import React from "react"
import BookingTicket from '../components/BookingTicket';
import { useNavigate } from "react-router";

export default function BookingComplete() {
    const router = useNavigate();
    const [bookingData, setBookingData] = React.useState<BookingType | null>(null);

    React.useEffect(() => {
        const bookingStorage: BookingType | null = JSON.parse(sessionStorage.getItem('current-booking-data') as string);
        if (!bookingStorage)
            router('/');
        else
            setBookingData(bookingStorage)
    }, [router])
    return (
        <div className='text-[#102751] relative'>
            {/* Booking Complete Header */}
            <div className='t40-container py-10 flex gap-3'>
                <h1 className='text-2xl font-semibold'>Booking Details </h1>
            </div>
            <div className='pt-4 pr-10 t40-container pb-20'>
                {
                    bookingData?.booking_details.map(
                        (booking, _) => <BookingTicket
                            data={booking} key={'ticket' + _}
                            index={_}
                            departure={bookingData.departure_details}
                            destination={bookingData.destination_details} />
                    )
                }
            </div>
        </div>
    )
}