import { motion } from "framer-motion";
import { brandName } from "../api/ApiConfig";
import T40Container from "../layout/Container";
import { Headings } from "../utils/constants/Headings";
import { slideIn, staggerContainer } from "../utils/motion";
import {
	SafetyBox,
	SafetyBox2,
	SafetyBox3,
	SafetyBox4,
} from "./services/SafetyBox";



export interface IServicesProps { }

export function Services(props: IServicesProps) {

	return (
		<div 
		// className="pt-[89px] pb-[40px] md:py-[89px]"
		>
			<T40Container>
				<div className="w-full space-y-5 lg:space-y-16">
					<motion.div

						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 0.5 }}
						viewport={{ once: false }}	
					// initial={{ x: -100 }}
					// whileInView={{ x: 0 }}
					// viewport={{ once: false }}						
					// transition={{ duration: 5, delay: 0.5 }}
					>
						<div className="">
							<Headings
								level="h2"
								title="Our Offers"
								className="text-t40Black md:text-left text-center text-2xl lg:text-5xl font-semibold md:font-bold"
							/>
							<p className="text-base md:text-[24px] text-center md:text-left leading-[24px] md:leading-[36px] text-[#050D1B] md:text-wrap">
								{brandName} offers variety of logistics and
								transportation services
							</p>
						</div>
					</motion.div>


					<div className="flex flex-col lg:flex-row gap-3 lg:gap-5">
						<div className="space-y-5 flex-1">
							<SafetyBox />
							<SafetyBox2 />
						</div>

						<div className="space-y-5 flex-1">
							<SafetyBox3 />
							<SafetyBox4 />
						</div>
					</div>
				</div>
			</T40Container>
		</div>
	);
}
