import React from 'react';

interface CurrencyFormatProps {
  amount: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

const CurrencyFormat: React.FC<CurrencyFormatProps> = ({
  amount,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
}) => {
  const formattedAmount = amount.toLocaleString(undefined, {
    minimumFractionDigits,
    maximumFractionDigits,
  });

  return <span>{formattedAmount}</span>;
};

export default CurrencyFormat;
