import * as React from "react";
import { FaDropbox, FaRocket, FaTicketSimple } from "react-icons/fa6";
import BookTrip from "../bookingBox/BookTrip";
import CharterRequest from "../bookingBox/CharterRequest";
import SendParcels from "../bookingBox/SendParcel";

export interface IBookingBoxProps {}

export function BookingBox(props: IBookingBoxProps) {
	const [activeTab, setActiveTab] = React.useState(0);

	const handleTabClick = (tab: number) => {
		setActiveTab(tab);
	};
	
	return (
		<div className="relative">
			<div className="absolute lg:relative w-full p-5 lg:p-6 bg-blue-950 rounded-[20px] lg:rounded-[32px] flex-col justify-center items-center inline-flex">
				<div className="w-full h-full relative bg-white rounded-2xl flex-col justify-start items-start flex">
					<div className="relative w-full bg-gray-50 border border-gray-200 rounded-t-2xl space-x-4">
						<div className="relative grid grid-cols-3 gap-1 place-items-center">
							<Tab
								title="Buy Tickets"
								active={activeTab === 0}
								icon={<FaTicketSimple />}
								onClick={() => handleTabClick(0)}
							/>
							<Tab
								title="Charter Request"
								active={activeTab === 1}
								icon={<FaRocket />}
								onClick={() => handleTabClick(1)}
							/>
							<Tab
								title="Send Parcels"
								active={activeTab === 2}
								icon={<FaDropbox />}
								onClick={() => handleTabClick(2)}
							/>
						</div>
					</div>
					<div className="p-4 w-full">
						{activeTab === 0 && <BookTrip />}
						{activeTab === 1 && <CharterRequest />}
						{activeTab === 2 && <SendParcels />}
					</div>
				</div>
			</div>
		</div>
	);
}

interface ITabProps {
	title: string;
	active: boolean;
	icon: any;
	onClick: () => void;
}

const Tab: React.FC<ITabProps> = ({ title, active, onClick, icon }) => (
	<div className="relative w-full">
		<div
			className={`h-20 flex-1 flex items-center justify-center text-center cursor-pointer ${
				active ? "border-b-4 border-gray-900" : "border-b-4 border-transparent"
			}`}
			onClick={onClick}>
			<div
				className={`flex flex-col items-center justify-center gap-2.5 ${
					active ? "text-slate-950" : "text-gray-400"
				}`}>
				{icon}
				<p
					className={`text-sm lg:text-base font-semibold lg:font-bold leading-[15px] lg:leading-tight ${
						active ? "text-slate-950" : "text-gray-400"
					}`}>
					{title}
				</p>
			</div>
		</div>
	</div>
);
