import { usePaystackPayment } from "react-paystack";
import type { PaystackProps } from "react-paystack/dist/types";
import  useMonnifyPayment  from "./monnify/use-monnify";
import type { MonnifyProps } from "./monnify/types";

export function usePayStack (config : PaystackProps) {
    try {
        return usePaystackPayment(config)
    } catch (e : any) {
        console.error("Paystack is currently down => " + e.message);
        return null;
    }
}

export function useMonnify(config : MonnifyProps) {
    try {
        return useMonnifyPayment(config)
    } catch (e : any) {
        console.error("Monnify is currently down => " + e.message);
        return null;
    }
}

export interface PaymentKeys {
    paystack : string,
    monnify : string
}