import React from "react";
// import { manPicture } from "../assets";
import { getDynamicColors } from "../utils/dynamic/colorUtils";
import T40Container from "../layout/Container";
import { getDynamicTexts } from "../utils/dynamic/textUtils";
import { BookingBox } from "./hero/BookingBox";
import HeroArrow from "../assets/hero-arrow.svg";
import HeroCircle from "../assets/hero-circle-sm.png";
import { Headings } from "../utils/constants/Headings";
import { brandName } from "../api/ApiConfig";
import { motion } from 'framer-motion';

function HeroSection() {
	const dynamicColors = getDynamicColors();
	const dynamicText = getDynamicTexts();

	return (
		<div>
			<div className="hidden bg-[#FDF2F8] px-5 lg:px-0">
				<T40Container>
					<motion.div
						initial={{
							opacity: 0,
							y: 100,
						}}
						transition={{
							type: "spring",
							stiffness: 300,
							damping: 140,
						}}
						whileInView={{
							opacity: 1,
							y: 0
						}}
						className="w-full py-4 flex flex-row items-center justify-center gap-3"
					>
						<div className="bg-t40Pink-600 px-2 rounded">
							<span className="text-white font-black text-xs lg:text-sm">
								NEW
							</span>
						</div>

						<span className="text-xs lg:text-sm font-medium text-slate-950">
							{dynamicText.promoText}
						</span>
					</motion.div>
				</T40Container>
			</div>
			<div className="relative md:mb-[45vh] mb-[45vh] lg:mb-[12vh]">
				<div
					className="w-full min-h-[627px] py-10 flex bg-no-repeat bg-bottom bg-contain lg:bg-contain lg:bg-right heroBgImage2"
					style={{
						backgroundColor: dynamicColors.primary,
					}}>
					<img
						src={HeroCircle}
						alt=""
						className="absolute z-0 lg:hidden object-cover w-full top-[37%]"
					/>
					<T40Container>
						<div className="relative flex flex-col lg:flex-row gap-10">
							<div className="w-full lg:w-[50%] space-y-5 lg:pt-10 xl:pt-20">
								<div className="mx-auto lg:mx-0 max-w-[275px] lg:max-w-[445px] bg-blue-900 rounded-full px-2 py-2">
									<p className="lg:text-left text-center text-white text-[10px] lg:text-sm font-medium leading-[15px]">
										Purchase tickets to enjoy premium and affordable travel service
									</p>
								</div>

								<Headings level="h1" title={dynamicText.heroTitle} />
								<p className="text-white text-center lg:text-left text-xs lg:text-lg leading-[18px] lg:leading-[27px] font-normal lg:font-medium ">
									Experience the best inter city travels in Nigeria with {" "}
									{brandName}. <br /> Buy your bus tickets now for seamless and
									reliable journeys nationwide!
								</p>

								<img
									src={HeroArrow}
									alt=""
									className="animate-bounce absolute left-[40%] hidden lg:block"
								/>


							</div>

							<div
								// initial={{
								// 	scale: 0
								// }}
								// whileInView={{
								// 	scale: 1
								// }}
								// transition={{
								// 	type: "spring",									
								// 	duration: 4,
								// 	x: {duration: 3},
								// 	stiffness: 260,
								// 	damping: 20
								// }}
								className="relative w-full lg:w-[50%] lg:flex lg:h-full lg:items-center lg:justify-end"
							>
								<BookingBox />
							</div>
						</div>
					</T40Container>
				</div>
			</div>
		</div>
	);
}

export default HeroSection;

// export default React.memo(function HeroSection() {
// 	return (
// 		<>

// 			{/* Old  */}
// 			<div className="grid justify-items-center gap-8 lg:gap-0 lg:justify-items-start lg:grid-cols-2 py-10">
// 				<div className="lg:pr-10">
// 					<div className="bg-[#F0F0F0] rounded-[40px] p-3 lg:h-[400px] h-[300px]">
// 						<img
// 							src={manPicture}
// 							alt="hero"
// 							className="h-full w-full object-cover rounded-[40px]"
// 						/>
// 					</div>
// 				</div>

// 				<header className="flex flex-col justify-center gap-6 items-center lg:items-start text-center lg:text-start">
// 					<p className="bg-[#F5FED4] w-fit p-1">
// 						Come enjoy Premium and Affordable Travel Service
// 					</p>
// 					<h1 className="text-3xl lg:text-5xl font-semibold">
// 						Easily Search and book ticket for your trips.
// 					</h1>
// 					<h5>
// 						{process.env.REACT_APP_BRAND_NAME} is committed to ensuring your
// 						safety to your destination and we help you say goodbye to long waits
// 						and Unreliable Transport service.
// 					</h5>

// 					<div className="flex gap-5 lg:justify-start justify-center w-full">
// 						<button className="btn h-[48px] w-full max-w-[200px] bg-t40Blue-500 text-white">
// 							Book Ticket
// 						</button>
// 						<button className="btn h-[48px] w-full max-w-[200px] border border-t40Blue-500">
// 							Learn More
// 						</button>
// 					</div>
// 				</header>
// 			</div>
// 		</>
// 	);
// });
