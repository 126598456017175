import React from 'react';
import ApiFetcher from '../utils/api';

const Context = React.createContext<any>({});

export default function ContextProvider({ children }: React.PropsWithChildren) {
    const [rideLocation, setRideLocation] = React.useState<rideLocationType>({
        from_cities: [],
        to_cities: []
    });
    const [passengerType, setPassengerTypes] = React.useState<any[]>([]);

    async function getLocations() {
        try {
            setRideLocation(JSON.parse(localStorage.getItem('locations') as string) || { from_cities: [], to_cities: [] })
            const response = await ApiFetcher.get('/v1/intercity/connections/routes');                        
            // const response = await ApiFetcher.get('connections/routes');
            
            setRideLocation(response.data.data);
            localStorage.setItem('locations', JSON.stringify(response.data.data))
        } catch (e) {
            setRideLocation({ from_cities: [], to_cities: [] })
        }
    }

    async function getPassengerTypes() {
        try {
          setPassengerTypes(JSON.parse(localStorage.getItem('passenger-types') as string) || [])
          const response = await ApiFetcher.get('/v1/intercity/generals/passenger-types');
        //   const response = await ApiFetcher.get('generals/passenger-types');
          if (response.status === 200) {
            setPassengerTypes(response.data.data);
            localStorage.setItem('passenger-types', JSON.stringify(response.data.data))
          }
        } catch (e) {
            console.log("Error fetching passenger types: ", e)
        }
      }
    

    React.useEffect(() => {
        getLocations();
        getPassengerTypes();
        
    }, [])
    return <Context.Provider value={{
        rideLocation, passengerType
    }}>
        {children}
    </Context.Provider>
}


export function usePassengerType(): { id: string, name: string, percentage_off: string }[] {
    const { passengerType } = React.useContext(Context);
    return passengerType;
  }

export function useRideLocation(): rideLocationType {
    const { rideLocation } = React.useContext(Context);
    return rideLocation;
}