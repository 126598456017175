import React from 'react'
import { FaFilter } from "react-icons/fa6";
import { motion } from 'framer-motion';

const FilterLocation = ({ onClick }: { onClick?: React.MouseEventHandler<HTMLElement> }) => {

    return (
        <section className='mt-10 flex items-center justify-between'>
            <motion.div
                initial={{
                    x: -50,
                    opacity: 0,
                }}
                transition={{
                    type: "spring",
                    stiffness: 100,
                    // damping: 70,
                    duration: 3,
                }}
                whileInView={{
                    x: 0,  
                    opacity: 1                  
                }}
            >
                <h2 className='text-[#050D1B] leading-tight text-[20px] md:text-3xl font-semibold'>
                    Available Locations
                </h2>
                <p className='text-[#6B7280] font-semibold leading-[21px] md:leading-[28.75px] text-sm md:text-[23px] mt-[18px]'>
                    We look forward to having you at our <br />
                    locations to serve you!
                </p>
            </motion.div>

            <button onClick={onClick} className='flex space-x-2 items-center border border-gray-300 bg-white text-gray-500 rounded-[8px] py-[14px] px-6'>
                <span>Filter</span>

                <FaFilter />
            </button>
        </section>
    )
}

export default FilterLocation