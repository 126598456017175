import React, { useEffect } from "react";
// import { useNavigate } from "react-router";
// import SearchBox from "../components/SearchBox";
import HeroSection from "../components/HeroSection";
// import ContactUs from "../components/ContactUs";
// import { formatDate } from "../utils/date";
import { Services } from "../components/Services";
import Branches from "../components/landingPage/Branches";
import WhyChooseUs from "../components/landingPage/WhyChooseUs";
import HowtoBuyTickets from "../components/landingPage/HowtoBuyTickets";
import Testimonials from "../components/landingPage/Testimonials";
import Faq from "../components/landingPage/Faq";
import { Helmet } from 'react-helmet-async';
import { brandName } from "../api/ApiConfig";

export default function Home() {
	// const navigate = useNavigate();

	React.useEffect(() => {
		const hash = window.location.hash;
		try {
			hash.length > 1 &&
				document.querySelector(hash)?.scrollIntoView({
					behavior: "smooth",
				});
		} catch { }
	}, []);

	// const handleSearchBoxChange = async (e: rideFilterType) => {
	// 	navigate(
	// 		`rides?from_ride=${e.from_ride?.label}&to_ride=${e.to_ride
	// 			?.label}&departure_date=${formatDate(e.departure_date)}&pax=${e.pax}${
	// 			e.return_date ? `&return_date=${formatDate(e.return_date)}` : ""
	// 		}`
	// 	);
	// };

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="hideNavigation">
			<Helmet>
				<title>
					{brandName}: Buy Bus Tickets Online For Intercity Travel in Nigeria
				</title>
				<meta name={`Book your rides with ${brandName}`} />
				<meta name='description'
					content={`Buy your bus ticket online today with ${brandName}, and enjoy the most affordable Nigeria inter-city travel experience. Book now!`} />
			</Helmet>

			<HeroSection />
			<div className="space-y-5 lg:space-y-24">
				<Services />				
				<WhyChooseUs />
				<HowtoBuyTickets />
				<Branches />
				<Testimonials />
				<Faq />
				{/* <ContactUs /> */}
			</div>
			{/* <section className="t40-container lg:!px-12 pt-10 pb-10">
				<SearchBox onClick={handleSearchBoxChange} rideCount />
			</section>
			 */}
		</div>
	);
}
