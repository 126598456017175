import React from "react";

type ButtonProps = {
    className?: string,
    title: string,
    onClick?: React.MouseEventHandler ,
    icon?: React.ReactNode | undefined,
    type?: "button" | "submit" | "reset" | undefined,
    style?: any,
    disabled?: boolean
}

const BaseButton = ({
    className,
    title,
    onClick,
    icon,
    type,
    style,
    disabled=false,
    ...props
}: ButtonProps) => {


    return (
        <button
            type={type}
            className={`${className} hover:bg-opacity-85 cursor-pointer transition-all ease-linear px-3 py-2 lg:px-6 lg:py-3.5 rounded-lg text-xs lg:text-base font-medium lg:font-['Inter']
            ${icon ? "flex items-center gap-1" : "text-center"} 
            ${disabled? "bg-gray-300 text-white" : "bg-[#1a56db] text-white"}
            `}
            onClick={onClick}
            style={style}
            {...props}            
        >
            <div>
                {title}
            </div>

            <div className="">
                {icon}
            </div>
        </button>
    )
}

export default BaseButton