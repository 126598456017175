import speedIcon from '../assets/speed-icon.svg';
import emailImage from '../assets/emaiImage.png';
import { NavLink } from 'react-router-dom';
import { brandName } from '../api/ApiConfig';

const EmailPrompt = () => {

    const userName: string = "David";
    return (
        <section className='mx-auto mt-6 md:w-[600px]'>
            <div className="p-6">
                <h2 className="flex justify-center items-center space-x-3 text-[24px] font-bold text-[#050D1B]">
                    <img src={speedIcon} alt="speed icon" />
                    <span>
                        Thanks for booking with us, David!
                    </span>
                </h2>
            </div>

            <div>
                <h4 className='py-6 px-8 text-gray-900 text-base leading-[24px] '>
                    <span>Hi {userName}</span>
                    <span className='block mt-4'>
                        Thank you for buying your ticket with {brandName} 
                        We hope you had a pleasant one travelling from Benin to Uyo. We look forward to your next travel with us.
                    </span>
                </h4>

                <div className="relative">
                    <div className='flex justify-center items-center'>
                        <img className="" src={emailImage} alt="create review links" />
                    </div>
                    {/* <img className="md:block hidden" src={emailImage} alt="create review links" /> */}
                    <div className="absolute p-4 md:p-6 w-full h-fit top-[50%] left-[50%]" style={{ transform: "translate(-50%, -50%)" }}>
                        <h1 className="text-center text-white text-[20px] sm:text-[30px] md:text-[36px] font-bold font-['Inter']">
                            Share your travel experience
                        </h1>
                        <p className='text-sm sm:text-base text-white text-center'>
                            Leave a review on our website to help us
                            improve our user experience and product quality.
                        </p>

                        <div className='flex justify-center items-center mt-4'>
                            <NavLink to="/email-review" className='py-3 px-[30px] text-xs sm:text-lg rounded-[60px] hover:bg-opacity-85 text-black bg-white'>
                                Write a review 
                            </NavLink>
                        </div>
                    </div>
                </div>

                <p className='p-8 text-gray-900 text-base leading-[24px]'>
                    Help other travelers make an informed decision when
                    buying ticket from us. Writing a review is quick, easy
                    and super helpful, this will only take few minutes.
                    <br />
                    <br />
                    We're here to assist you throughout the process,
                    so if you have any questions, kindly send an email to
                    <span className="text-blue-600">info@riverslink.ng</span> or
                    call <span className="text-orange-400">07010069307, 08145607821.</span>
                    <br />
                    <br />
                    Thank you for choosing us for your travel needs.
                    We wish you a safe and enjoyable trip!
                    <br />
                    <br />
                    Best regards.
                </p>
            </div>
        </section>
    )
}

export default EmailPrompt