import React from "react";
import Select from "react-select";

interface City {
	id: string;
	name: string;
	code: string | null;
}

interface CityOption {
	value: string;
	label: string;
}

interface CitySelectProps {
	cities: City[];
	setSelectedCity: (selectedCity: CityOption | null) => void;
	onSelectCity: (selectedCity: CityOption | null) => void;
	label: string;
	prefixIcon: React.ReactNode;
	selectedCity: CityOption | null;
	// setSelectedCity: React.Dispatch<React.SetStateAction<CityOption | null>>;
	onBlur?: () => void;
}

const CitySelect: React.FC<CitySelectProps> = ({
	cities,
	onSelectCity,
	label,
	prefixIcon,
	selectedCity,
	setSelectedCity,
	onBlur,
}) => {
	const cityOptions: CityOption[] = cities.map((city) => ({
		value: city.id,
		label: city.name,
	}));

	const handleChange = async (selectedOption: CityOption | null) => {
		setSelectedCity(selectedOption);
		onSelectCity(selectedOption);

		// onBlur();
	};

	return (
		<div className="w-full relative ">
			<label
				htmlFor="email"
				className="block text-sm font-medium leading-6 text-gray-900">
				{label}
			</label>
			<div className="relative mt-2 z-50 border-0 border-transparent bg-gray-100 py-1.5 rounded-lg">
				<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
					<span className="text-gray-500 text-xl">{prefixIcon}</span>
				</div>

				<Select
					value={selectedCity}
					onChange={handleChange}
					options={cityOptions}
					placeholder="Select a city"
					className="pl-8 bg-transparent ring-0 focus:bg-transparent focus:outline-none outline-none focus-within:outline-none drop-shadow-none shadow-none border-none"
					onBlur={onBlur}
					escapeClearsValue={true}
					isClearable={true}
					classNames={{
						control: (state) =>
						  state.isFocused ? 'border-red-600' : 'border-grey-300',
					  }}
					styles={{
						control: (baseStyles, state) => ({
							...baseStyles,
							borderColor: state.isFocused ? "transparent" : "transparent",
							backgroundColor: "#F3F4F6",
							border: "none",
							boxShadow: "none",
							caretColor: "#000",
							outline: "none",
							"&:hover": {
								borderColor: "transparent",
								border: "none",
							},
							"&:focus": {
								borderColor: "transparent",
								border: "none",
								outline: "none",
								boxShadow: 'none',
							},
							"&::before, &::after": {
								borderColor: "transparent",
								border: "none",
								outline: "none",
							},
						}),
					}}
				/>
			</div>
		</div>
	);
};

export default CitySelect;
