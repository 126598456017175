import ReactSelect, { StylesConfig } from "react-select";
import { ArrowDown2 } from "iconsax-react";
 
export interface SelectProps {
    value?: OptionProps | null,
    placeholder?: string,
    onChange?: (e : OptionProps) => any,
    options?: OptionProps[]
}
const customStyles: StylesConfig = {
    control: (provided, state) => ({
        ...provided,
        border: "none",
        background: 'white',
        height: '48px',
        color: "#040913",
        boxShadow: "4px 4px 8px 0px rgba(182, 190, 196, 0.15), -2px -2px 8px 0px rgba(182, 190, 196, 0.15)",
        borderRadius: "8px",
        paddingRight: "10px",
        paddingLeft: "5px"
    }),
    menu: (provided, state) => ({
        ...provided,
        top: '-20',
        overflow: 'hidden',
        zIndex: 20
    })
}

export default function Select({
    value, placeholder, onChange, options
}: SelectProps) {
    return <div className="w-full">
        <div className={` ${(value && placeholder) ? "max-h-[100px]" : "max-h-0"} transition-all overflow-hidden pb-1`}>
            <label className="mb-1">{placeholder}*</label>
        </div>
        <ReactSelect
            onChange={(e: any) => onChange && onChange(e)}
            styles={customStyles}
            components={{
                DropdownIndicator: () => <ArrowDown2 size={19} color="#485D6B" variant="Bold" />,
                IndicatorSeparator: () => null
            }}
            placeholder={value ? value.label : placeholder}
            options={options}
        />
    </div>
}