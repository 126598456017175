function validateWords(value: string): boolean {
    const regex = /^[a-zA-Z-]+$/
    return regex.test(value)
}

function valiadateDigits(value: string): boolean {
    const regex = /^\d+$/
    return regex.test(value)
}

function validatePasswordPattern(value: string) {
    let regex = /^(?=.*[A-Za-z])(?=.*\d).+$/;
    return regex.test(value);
}

function valiadateEmail(value: string) {
    let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return regex.test(value);
}


function validateNames(_value: string): string {
    const value = _value.trim();
    if (value.length < 3)
        return "Minimum of 3 chracters";
    if (value.length > 15)
        return "Maximum of 15 characters";

    if (!validateWords(value))
        return "Name can only have alphabets and hyphen";

    return "";
}


function validatePhoneNumber(_value: string): string {
    const value = _value.trim();
    if (value.length !== 11)
        return "Must be 11 digits";

    if (!valiadateDigits(value))
        return "Can only contain numbers";

    return "";
}

function checkPasswordStremgth(_value: string): string {
    const value = _value.trim();
    if (value.length < 8)
        return "Minimum of 8 characters"

    if (value.length > 20)
        return "Maximum of 20 characters"

    if (!validatePasswordPattern(value))
        return "Password must contain at least a letter and a number"

    return ""
}

function validateEmail(_value: string) {
    const value = _value.trim();
    if (valiadateEmail(value))
        return ""

    return "The email is not valid"
}

function validateAddress(_value: string) {
    const value = _value.trim();
    if (value.length < 8)
        return "Minimum of 8 characters"

    if (/.*[a-zA-Z].*/.test(value))
        return ""

    return "Address must contain letters"
}
export default {
    first_name: validateNames,
    last_name: validateNames,
    phone_number: validatePhoneNumber,
    password: checkPasswordStremgth,
    email: validateEmail,
    address : validateAddress
} as any