import T40Container from '../../layout/Container'
import { Headings } from '../../utils/constants/Headings'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { useCallback, useEffect, useRef, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import baseURL, { brandID } from '../../api/ApiConfig';
import { Rating } from '@mui/material';

const Testimonials = () => {
    const navigate = useNavigate();

    const [allReviews, setAllReviews] = useState([]);
    const [loading, setLoading] = useState(false);

    const onClickReviews = () => {
        navigate("/write-reviews");
    }

    const reviewSwiperRef: any = useRef(null);

    const slidePrev = useCallback(() => {
        if (!reviewSwiperRef.current) return;
        reviewSwiperRef.current.swiper.slidePrev();
    }, []);

    const slideNext = useCallback(() => {
        if (!reviewSwiperRef.current) return;
        reviewSwiperRef.current.swiper.slideNext();
    }, []);

    const getAllReviews = async () => {
        try {
            setLoading(true);

            // await axios.get(`${baseURL}/v1/intercity/reviews?service_type=${'ticket'}`)
            await axios.get(`${baseURL}/v1/intercity/reviews?service_type=${'ticket'}&per_page=10&brand_id=${brandID}`)
                .then((res: any) => {
                    // console.log("rev response", res.data.data);
                    setAllReviews(res.data.data);
                })

        } catch (error) {
            console.log("Error", error)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getAllReviews();
    }, [])

    return (
        <section className='md:border-0 border md:border-none md:py-0 py-6 border-[#e5e7eb] rounded-2xl'>
            <T40Container>
                <Headings
                    level="h2"
                    title="What our customers say..."
                    className="text-left leading-[45px] text-blue-900 text-2xl lg:text-4xl font-semibold lg:font-bold"
                />

                <div className='mt-6 md:mt-10 relative mx-2 bg-[#f9fafb] h-full'>
                    <Swiper
                        ref={reviewSwiperRef}
                        // loop={true}
                        // grabCursor={true}
                        // autoplay={{
                        //     delay: 4000,
                        //     disableOnInteraction: false,
                        // }}
                        modules={[Navigation, Autoplay]}
                        breakpoints={{
                            500: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 0,
                            },
                            1024: {
                                slidesPerView: 2,
                                spaceBetween: 0,
                            },
                        }}
                    >
                        {
                            loading ? <p className='text-center'> Loading reviews . . .</p> :
                                allReviews.length > 0 ?
                                    allReviews.slice(1, 10).map((review: any) =>
                                        <SwiperSlide key={review.id}>
                                            <div className='relative py-12 h-full flex flex-col justify-between text-center px-8  '>
                                                <div>
                                                    <div className='flex items-center justify-center'>
                                                        <Rating
                                                            name="ratings"
                                                            defaultValue={review.brand.rating}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <p className='my-4 text-gray-500 font-bold text-wrap'>
                                                        {review.from_city.name} to {review.to_city.name}
                                                    </p>
                                                </div>

                                                <div>
                                                    <div className=' text-gray-500 text-sm leading-[21px] text-wrap'>
                                                        {review.description}
                                                    </div>

                                                    <p className='text-gray-900 font-semibold text-lg mt-6'>
                                                        {review.first_name} {review?.last_name}
                                                    </p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ) : <p className='text-center py-3'>No reviews available</p>
                        }
                    </Swiper>

                    <div className='absolute top-[50%] left-[50%]' style={{ transform: "translateX(-50%)" }}>
                        <div className='h-[10px] w-1 bg-gray-200'>
                        </div>
                    </div>

                    <div className='z-50 absolute top-[50%] left-[50%] w-full' style={{ transform: "translate(-50%, -50%)" }}>
                        <div className="flex justify-between items-center -mx-4">
                            <button onClick={slidePrev} className='bg-gray-400 p-2.5 rounded-full'>
                                <FaArrowLeft className='text-gray-200' />
                            </button>

                            <button onClick={slideNext} className='bg-gray-800 p-2.5 rounded-full'>
                                <FaArrowRight className='text-gray-100' />
                            </button>
                        </div>
                    </div>
                </div>

                <div className='flex justify-left items-center mt-[40px]'>
                    <div className='md:grid grid-cols-2 items-center md:space-y-0 space-y-6 md:space-x-[25px]'>
                        <NavLink to="/reviews" className='text-gray-800 bg-gray-200 font-medium px-[20px] flex w-full py-[10px] rounded-lg text-sm'>
                            Show more reviews...
                        </NavLink>

                        <button onClick={onClickReviews} className='text-sm rounded-lg px-[20px] w-full py-[10px] bg-[#1A56DB] text-white font-medium'>
                            Write a review
                        </button>
                    </div>
                </div>
            </T40Container>
        </section>
    )
}

export default Testimonials