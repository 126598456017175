import * as React from "react";
import { IoInformationCircle } from "react-icons/io5";
import CustomButton from "../../../../utils/constants/Button";
import CurrencyFormat from "../../../../utils/constants/CurrencyFormat";
import SeatSelect from "../SeatSelect";
import { useBookingContext } from "../../../../context/BookingContext";
import { toast } from "react-toastify";
import { FaAngleDown } from "react-icons/fa";

interface ISelectSeatsProps {
	setStepper: React.Dispatch<React.SetStateAction<number>>;
	data: {
		ride_departure_id: string;
		ride_meta: {
			ride_meta_id: string;
			ride_destination: {
				ride_destination_id: string;
				amount: number;
				arrival_time: string;
				discount: {
					ride_discount_amount: number;
					date_discount_amount: number;
				};
				id: string;
				amount_with_discount: number;
				location: {
					name: string;
					description: string | null;
					address: string | null;
					latitude: number | null;
					longitude: number | null;
					what3word: string | null;
					city: {
						id: string;
						name: string;
						code: string | null;
					};
				};
			};
			departure_time: string;
			comment: string | null;
			business: {
				id: string;
				business_name: string;
				business_email: string;
				phone_number: string;
				address: string;
				postal_code: string | null;
				api_key: string | null;
				abbr_code: string | null;
				logo_url: string;
				is_verified: boolean;
				status: string;
				longitude: number | null;
				latitude: number | null;
				what3word: string | null;
			};
			brand: {
				id: string;
				brand_name: string;
				rating: number;
				status: string;
				logo_url: string;
				url: string;
				customers: {
					bookings: number;
					parcels: number;
				};
			};
			currency: {
				id: string;
				name: string;
				symbol: string;
				code: string;
			};
			vehicle_type: {
				id: string;
				name: string;
				category: string;
				row_col: [number, number];
				cordinates: [number, number][];
				status: string;
				capacity: number;
			};
			ride_departure_location: {
				name: string;
				description: string | null;
				address: string;
				latitude: number;
				longitude: number;
				what3word: string;
				city: {
					id: string;
					name: string;
					code: string | null;
				};
			};
			departure_location: {
				name: string;
				description: string | null;
				address: string;
				latitude: number;
				longitude: number;
				what3word: string;
				city: {
					id: string;
					name: string;
					code: string | null;
				};
			};
			ride_destinations: {
				ride_destination_id: string;
				amount: number;
				arrival_time: string;
				discount: {
					ride_discount_amount: number;
					date_discount_amount: number;
				};
				id: string;
				amount_with_discount: number;
				location: {
					name: string;
					description: string | null;
					address: string | null;
					latitude: number | null;
					longitude: number | null;
					what3word: string | null;
					city: {
						id: string;
						name: string;
						code: string | null;
					};
				};
			}[];
		};
		departure_date: string;
		no_of_seats: number;
		seats: {
			id: string;
			ride_seat_id: string;
			x: number;
			y: number;
			seat_no: number;
			is_available: boolean;
			is_reserved: boolean;
		}[];
		dispatch_status: string;
		id: string;
		ride_code: string | null;
	};
	selectedSeatIds: { id: string; seatNumber: number }[];
	setSelectedSeatIds: React.Dispatch<
		React.SetStateAction<{ id: string; seatNumber: number }[]>
	>;
}

const SelectSeats: React.FunctionComponent<ISelectSeatsProps> = (props) => {
	const { data, setStepper, selectedSeatIds, setSelectedSeatIds } = props;
	const { numberOfTravelers } = useBookingContext();
	console.log(selectedSeatIds);

	const handleButton = () => {
		if (selectedSeatIds.length === numberOfTravelers) {
			setStepper(2);
		} else {
			if (numberOfTravelers > 1) {
				toast.error("Please select seat for all travelers");
			} else {
				toast.error("Please select seats for traveler");
			}
		}
	};
	return (
		<>
			<div className="relative p-4">
				<div className="flex items-center justify-between">
					<h2 className="text-t40Black text-2xl font-semibold">Select Seat</h2>

					<div>
						<p className="text-gray-400 text-xs font-medium">Vehicle Type</p>
						<p className="text-t40Black flex items-center space-x-1 text-sm font-semibold">
							<span className="block">{data.ride_meta.vehicle_type.name}</span> 
							 {/* <FaAngleDown className="text-sm text-gray-800" /> */}
						</p>
					</div>
				</div>

				{/* Seats  */}
				<div className="py-8 pb-10">
					<SeatSelect
						data={data}
						selectedSeatIds={selectedSeatIds}
						setSelectedSeatIds={setSelectedSeatIds}
					/>
				</div>
				{/* Seats  */}

				<div className="w-full flex items-center justify-center gap-3">
					{seatIndicator.map((item, index) => (
						<div className="flex items-center gap-2">
							<div className={`${item.color} w-4 h-4 rounded-sm`} />
							<p>{item.type}</p>
						</div>
					))}
				</div>

				<div className="w-full md:mb-28 flex items-center justify-center mt-5 mb-6">
					<div className="px-2.5 py-0.5 text-yellow-600 text-xs font-medium flex items-center gap-1 bg-yellow-50 rounded-md">
						<IoInformationCircle />
						<p>Make sure to select a seat to continue</p>
					</div>
				</div>
			</div>

			<div className="fixed w-full lg:max-w-lg bottom-0 lg:bottom-12 ">
				<div className="border-t shadow bg-white py-5 px-4 lg:rounded-bl-3xl lg:rounded-br-3xl">
					<div className="flex items-center justify-between gap-10">
						<div className="w-[80%] flex flex-col ">
							<div className="flex items-center gap-2">
								<p className="text-gray-500 text-xs font-normal line-through">
									{data.ride_meta.currency.symbol}
									<CurrencyFormat
										amount={data.ride_meta.ride_destination.amount}
									/>
								</p>

								{/* Discount Percentage  */}
								{/* <div className="px-1 bg-green-100 rounded text-xs text-emerald-900">
									<span>-10%</span>
								</div> */}
							</div>
							<p className="text-slate-950 text-lg font-semibold">
								{data.ride_meta.currency.symbol}
								<CurrencyFormat
									amount={data.ride_meta.ride_destination.amount_with_discount}
								/>
							</p>
						</div>
						<CustomButton
							onClick={handleButton}
							type="button"
							text="Continue"
							small={true}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default SelectSeats;

const seatIndicator = [
	{
		color: "bg-blue-700",
		type: "Selected",
	},
	{
		color: "bg-gray-800",
		type: "Available",
	},
	{
		color: "bg-gray-200",
		type: "Booked",
	},
];
