import React from "react";
import DatePicker from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";

interface DateInputProps {
	label: string;
	selectedDate: Date | null;
	handleDateChange: (date: Date | null) => void;
	disabled: boolean;
	onBlur?: () => void;
}

const DateInput: React.FC<DateInputProps> = ({
	label,
	selectedDate,
	handleDateChange,
	disabled,
	onBlur,
}) => {
	const today = new Date();
	today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0

	const tomorrow = new Date(today);
	tomorrow.setDate(tomorrow.getDate() + 1); // Set the date to the next day

	return (
		<div className="w-full relative ">
			<label
				htmlFor="datepicker"
				className="block text-sm font-medium leading-6 text-gray-900">
				{label}
			</label>

			<div className="relative z-40 mt-2 border-0 border-transparent w-full bg-gray-100 rounded-lg">
				<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
					<span className="text-gray-500 text-xl">
						<FaCalendarAlt />
					</span>
				</div>

				<DatePicker
					selected={selectedDate}
					onChange={handleDateChange}
					dateFormat="yyyy/MM/dd"
					placeholderText="Select a date"
					id="datepicker"
					className="pl-10 block w-full appearance-none bg-transparent py-3 border-0 border-transparent rounded-lg text-gray-900 ring-0 ring-inset ring-transparent placeholder:text-gray-500 placeholder:font-['Inter'] focus:ring-0 focus:ring-inset focus:ring-transparent sm:text-sm sm:leading-6 outline-none caret-gray-300"
					minDate={tomorrow}
					disabled={disabled}
					onBlur={onBlur}
					onSelect={onBlur}
				/>
			</div>
		</div>
	);
};

export default DateInput;
