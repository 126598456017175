import React from 'react'
import T40Container from '../../layout/Container'
import { Headings } from '../../utils/constants/Headings'
import badge from '../../assets/badge.svg';
import cash from '../../assets/cash.svg';
import expand from '../../assets/expand.svg';
import seamless from '../../assets/seamless.svg';
import sun from '../../assets/sun.svg';
import thumbsup from '../../assets/thumbsup.svg';
import { brandName } from '../../api/ApiConfig';


type OurServicesType = {
    image: any,
    services: string,
    details: string,
}

const whyChooseOurServices = [
    {
        image: badge,
        services: "Reliable Services",
        details: `At ${brandName}, reliability is our 
        cornerstone. We pride ourselves on delivering 
        consistent, dependable services to all our customers. 
        Whether it's booking tickets, managing logistics, 
        or providing transportation solutions, you can 
        trust us to get the job done efficiently 
        and reliably.
        `
    },
    {
        image: expand,
        services: "Extensive Network",
        details: `With an extensive network of routes and 
        services, ${brandName} ensures that you can reach 
        your destination conveniently and on time. Our wide 
        coverage allows us to cater to diverse 
        transportation needs, whether it's interstate 
        travel or local commutes.
        `
    },
    {
        image: seamless,
        services: "Seamless Booking Experience",
        details: `Experience hassle-free booking with ${brandName}. Our user-friendly online platform makes it easy 
        to book tickets, compare schedules, and select the best 
        options for your journey. Say goodbye to long queues 
        and last-minute hassles with our seamless booking 
        process.
        `
    },
    {
        image: sun,
        services: "Innovation",
        details: `At ${brandName}, we continually explore new 
        technologies and ideas to enhance our services and 
        improve your experience. From digital solutions to 
        cutting-edge logistics, we're always pushing the 
        boundaries to provide you with the best possible service.
        `
    },
    {
        image: thumbsup,
        services: "Customer Satisfaction",
        details: `Customer satisfaction is at the heart of 
        everything we do. Our dedicated team is committed to 
        providing exceptional service and support to ensure 
        your journey with ${brandName} is smooth and enjoyable. 
        From inquiry to arrival, we strive to exceed your 
        expectations at every step.
        `
    },
    {
        image: cash,
        services: "Competitive Pricing",
        details: `We understand the value of affordability. 
        That's why ${brandName} offers competitive pricing 
        without compromising on quality. Enjoy cost-effective 
        solutions for your transportation needs, ensuring you 
        get the best value for your money.
        `
    }
]


const WhyChooseUs = () => {
    return (
        <section className='bg-gray-50 pt-16'>
            <T40Container>
                <Headings
                    level="h2"
                    title={<span> Why choose {brandName} </span>}
                    className=" lg:text-left text-center text-2xl lg:text-5xl font-bold lg:font-bold"
                />

                <div className='py-8 lg:py-16'>
                    <div className='lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-x-[45px] gap-y-10 lg:space-y-0 space-y-6'>
                        {
                            whyChooseOurServices?.map((data: OurServicesType) => (
                                <div key={data.services} className='lg:flex space-x-[8px] text-center lg:text-left'>
                                    <div className='lg:flex-none lg:w-[80px] flex justify-center items-center'>
                                        <img className='block w-[48px] h-[48px] lg:w-[80px] lg:h-[80px]' src={data.image} alt={data.details} />
                                    </div>
                                    <div className='flex-1'>
                                        <h3 className='text-xl text-t40Black font-semibold text-wrap'>{data.services}</h3>
                                        <p className='text-wrap text-gray-500 text-sm lg:text-base leading-[24px]'>
                                            {data.details}
                                        </p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </T40Container>
        </section>
    )
}

export default WhyChooseUs