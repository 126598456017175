import React from 'react';

export const Headings = ({level, title, className}:{title:string | React.ReactElement, level:string, className?: string}) => {

	switch (level) {
		case "h1":
			return (
				<h1 className="text-white text-3xl lg:text-6xl font-bold text-center lg:text-left leading-[37.50px] lg:leading-[60px] ">
					{title}
				</h1>
			);
		case "h2":
			return (
				<h2 className={`${className} font-bold text-t40Black text-[20px] md:text-3xl lg:text-4xl leading-normal lg:leading-[48px]`}>
					{title}
				</h2>
			);
		case "h3":
			return <h3 className={`font-bold text-t40Black text-[20px] lg:text-4xl ${className}`}>{title}</h3>;
		case "h4":
			return (
				<h4 className={`font-bold text-t40Black text-[20px] lg:text-4xl ${className}`}>
					{title}
				</h4>
			);
		case "h5":
			return <h5 className={`${className}`}>{title}</h5>;
		default:
			return <div>{title}</div>;		
	}
};
