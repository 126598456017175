import React from "react";
import { Headings } from "../../utils/constants/Headings";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import offer1 from '../../assets/offer1.png';
import ring1 from '../../assets/ring1.svg';
import offer2 from '../../assets/offer2.png';
import ring2 from '../../assets/ring2.svg';
import offer3 from '../../assets/offer3.png';
import ring3 from '../../assets/ring3.svg';
import offer4 from '../../assets/offer4.png';
import ring4 from '../../assets/ring4.svg';
import offer1md from '../../assets/offer1md.png';
import ring1md from '../../assets/ring1md.svg';
import offer2md from '../../assets/offer2md.png';
import ring2md from '../../assets/ring2md.svg';
import offer3md from '../../assets/offer3md.png';
import ring3md from '../../assets/ring3md.svg';
import offer4md from '../../assets/offer4md.png';
import ring4md from '../../assets/ring4md.svg';
import BaseButton from "../widgets/ui/button/BaseButton";
import { brandName } from "../../api/ApiConfig";


export function SafetyBox() {
	const navigate = useNavigate();

	const handleNavigate = () => {
		navigate("/select-station");
	};
	return (
		<div>
			<div className="relative h-[327px] lg:h-[560px] w-full transition-all ease-linear hover:bg-orange-100 bg-orange-50 rounded-lg lg:rounded-3xl p-5 lg:p-10">
				<div className="space-y-3 lg:space-y-5">
					<Headings level="h3" title="Buy bus tickets easily" />
					<p className="text-t40Black text-xs lg:text-lg font-normal">
						Easily buy your bus tickets online for a
						hassle-free travel experience. Choose your
						destination, select your preferred time, and
						get ready for a smooth journey with {brandName}
					</p>
					<button
						onClick={handleNavigate}
						className="px-3 py-2 lg:px-6 lg:py-3.5 transition-all ease-linear hover:bg-blue-200 bg-blue-100 rounded-lg text-t40Black text-xs lg:text-base font-medium lg:font-['Inter'] flex items-center gap-1"
					>
						Book Tickets
						<MdOutlineKeyboardArrowRight className="text-[#F2761B]" size={18} />
					</button>
				</div>

				<img
					src={offer1}
					alt="offer"
					className="lg:block hidden absolute rounded-br-2xl z-20 bottom-0 right-0"
				/>
				<img
					src={ring1}
					alt="ring"
					className="lg:block hidden absolute z-10 bottom-0 right-0"
				/>

				<img
					src={offer1md}
					alt="offer"
					className="lg:hidden block absolute rounded-br-2xl z-20 bottom-0 right-0"
				/>
				<img
					src={ring1md}
					alt="ring"
					className="lg:hidden block absolute z-10 bottom-0 right-0"
				/>

			</div>
		</div>
	);
}

export function SafetyBox2() {
	const navigate = useNavigate();

	const handleNavigate = () => {
		navigate("/select-station");
	};
	return (
		<div>
			<div className="relative h-[388.76px] lg:h-[752px] w-full transition-all ease-linear hover:bg-gray-100 bg-gray-50 rounded-lg lg:rounded-3xl p-5 lg:p-10">
				<div className="space-y-3 lg:space-y-5">
					<Headings level="h3" title="Send a parcel" />
					<p className="text-t40Black text-xs lg:text-lg font-normal">
						Need to send a parcel? {brandName} has you covered!
						Our parcel delivery service ensures your package reaches
						its destination safely and on time, giving you peace of mind
						every step of the way.
					</p>

					<button						
						className="px-3 py-2 lg:px-6 lg:py-3.5 rounded-lg group hover:text-white transition-all ease-linear hover:bg-gray-400 bg-gray-300 text-t40Black text-xs lg:text-base font-medium lg:font-['Inter'] flex items-center gap-1"
					>
						Send Parcel
						<MdOutlineKeyboardArrowRight className="text-[#F2761B] group-hover:text-white transition-all ease-linear" size={18} />
					</button>					
				</div>

				<img
					src={offer3}
					alt="offer"
					className="lg:block hidden absolute rounded-br-2xl z-20 bottom-0 right-0"
				/>
				<img
					src={ring3}
					alt="ring"
					className="lg:block hidden absolute z-10 bottom-0 right-0"
				/>

				<img
					src={offer3md}
					alt="offer"
					className="block lg:hidden absolute rounded-br-2xl z-20 bottom-0 right-0"
				/>
				<img
					src={ring3md}
					alt="ring"
					className="block lg:hidden absolute z-10 bottom-0 right-0"
				/>
			</div>
		</div>
	);
}

export function SafetyBox3() {
	const navigate = useNavigate();

	const handleNavigate = () => {
		navigate("/select-station");
	};
	return (
		<div>
			<div className="relative h-[388.76px] lg:h-[752px] w-full transition-all ease-linear hover:bg-gray-100 bg-gray-50 rounded-lg lg:rounded-3xl p-5 lg:p-10">
				<div className="space-y-3 lg:space-y-5">
					<Headings level="h3" title="Charter a ride" />
					<p className="text-t40Black text-xs lg:text-lg font-normal">
						Whether it's for a special event, corporate travel,
						or group outings, chartering a vehicle with {brandName}
						is simple and convenient. Enjoy personalized transportation
						solutions tailored to your needs.
					</p>
					<button
						onClick={handleNavigate}
						className="px-3 py-2 lg:px-6 lg:py-3.5 transition-all ease-linear group bg-gray-300 hover:bg-gray-400 hover:text-white cursor-pointer rounded-lg text-t40Black text-xs lg:text-base font-medium lg:font-['Inter'] flex items-center gap-1"
					>
						Hire a car
						<MdOutlineKeyboardArrowRight className="text-[#F2761B] group-hover:text-white transition-all ease-linear" size={18} />
					</button>
				</div>

				<img
					src={offer2}
					alt="offer"
					className="lg:block hidden absolute z-20 rounded-br-2xl bottom-0 right-0"
				/>
				<img
					src={ring2}
					alt="ring"
					className="lg:block hidden absolute z-10 bottom-0 right-0"
				/>

				<img
					src={offer2md}
					alt="offer"
					className="lg:hidden block absolute z-20 rounded-br-2xl bottom-0 right-0"
				/>
				<img
					src={ring2md}
					alt="ring"
					className="lg:hidden block absolute z-10 bottom-0 right-0"
				/>
			</div>
		</div>
	);
}
export function SafetyBox4() {

	return (
		<div>
			<div className="relative h-[290px] lg:h-[560px] w-full transition-all ease-linear bg-teal-50 hover:bg-teal-100 rounded-lg lg:rounded-3xl p-5 lg:p-10">
				<div className="space-y-3 lg:space-y-5">
					<Headings level="h3" title="Your safety is always priority" />
					<p className="text-t40Black text-xs lg:text-lg font-normal">
						Your safety is our top priority at {brandName}. We adhere
						to the highest safety standards to ensure that every
						journey with us is secure and worry-free
					</p>
				</div>

				<img
					src={offer4}
					alt="offer"
					className="hidden lg:block absolute z-20 rounded-br-2xl bottom-0 right-0"
				/>
				<img
					src={ring4}
					alt="ring"
					className="hidden lg:block absolute z-10 bottom-0 right-0"
				/>

				<img
					src={offer4md}
					alt="offer"
					className="lg:hidden block absolute z-20 rounded-br-2xl bottom-0 right-0"
				/>
				<img
					src={ring4md}
					alt="ring"
					className="lg:hidden block absolute z-10 bottom-0 right-0"
				/>
			</div>
		</div>
	);
}
