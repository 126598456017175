import mixpanel from 'mixpanel-browser';

const MIXPANEL_TOKEN: any = process.env.REACT_APP_MIXPANEL_TOKEN;

const isProduction = process.env.REACT_APP_NODE_ENV === 'production';
console.log(MIXPANEL_TOKEN, isProduction);

mixpanel.init(MIXPANEL_TOKEN, { track_pageview: true });

const actions = {
  identify: (id:any) => {
    if (isProduction) mixpanel.identify(id);
  },
  alias: (id:any) => {
    if (isProduction) mixpanel.alias(id);
  },
  track: (name:any, props:any) => {
    if (isProduction) mixpanel.track(name, props);
  },
  people: {
    set: (props:any) => {
      if (isProduction) mixpanel.people.set(props);
    },
  },
};

export const Mixpanel = actions;
