import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
// import Logo from "../assets/logo.svg";
import { getDynamicColors } from "../utils/dynamic/colorUtils";
import { HiMenu } from "react-icons/hi";
import T40Container from "../layout/Container";
import { IoMdClose } from "react-icons/io";
import { NavHashLink } from "react-router-hash-link";
// import logo from '../assets/logo2.svg';

export default function Navbar() {
	const dynamicColors = getDynamicColors();
	const [showMenu, setShowMenu] = useState(false);

	const logo = process.env.REACT_APP_BRAND_LOGO_HEADER;

	const handleClose = () => {
		setShowMenu(false);
	};
	return (
		<>
			{/* <nav className="bg-t40Blue-500">
				<div className="t40-container text-white py-4 flex justify-between items-center shadow-lg">
					<Link to="/">
						<h1 className="text-lg font-semibold">
							{process.env.REACT_APP_BRAND_NAME}
						</h1>
					</Link>

					<ul className="flex gap-12 font-[400]">
						<li className="hidden lg:block">
							<Link to="/rides">Rides</Link>
						</li>
						<li className="hidden lg:block">
							<Link to="/#contact">Contact Us</Link>
						</li>
						<li>
							<Link to="/" className="btn h-fit bg-t40orange">
								Book Ticket
							</Link>
						</li>
					</ul>
				</div>
			</nav> */}

			<nav className="w-full py-6 bg-white flex items-center justify-center border-b border-b-[#d1d5db]  ">
				<T40Container>
					<div className="w-full flex flex-row justify-between items-center">
						<Link to="/" onClick={handleClose}>
							<img
								// src={process.env.REACT_APP_BRAND_LOGO_HEADER}
								src={logo}
								// src="/unique.png"
								alt="Logo"
								className="w-[64px] object-cover"
							/>
						</Link>

						<div className="hidden lg:block">
							<ul className="flex gap-8 ">
								<li>
									<NavLink
										to="/"
										className={({ isActive }) => isActive ? "border-b-[3px] underline-offset-[10px] border-orange-400"
											: "text-gray-900 font-medium hover:cursor-pointer hover:opacity-70 opacity-100 transition ease-linear"}
									>
										Home
									</NavLink>
								</li>
								<li>
									<NavLink
										to="/select-station"
										className={({ isActive }) => isActive ? "border-b-[3px] underline-offset-[10px] border-orange-400"
											: "text-gray-900 font-medium hover:cursor-pointer hover:opacity-70 opacity-100 transition ease-linear"}
									>
										Tickets
									</NavLink>
								</li>
								<li>
									<NavLink
										to="/locations"
										className={({ isActive }) => isActive ? "border-b-[3px] underline-offset-[10px] border-orange-400"
											: "text-gray-900 font-medium hover:cursor-pointer hover:opacity-70 opacity-100 transition ease-linear"}
									>
										Locations
									</NavLink>
								</li>
								<li>
									<NavLink
										to="/reviews"
										className={({ isActive }) => isActive ? "border-b-[3px] underline-offset-[10px] border-orange-400"
											: "text-gray-900 font-medium hover:cursor-pointer hover:opacity-70 opacity-100 transition ease-linear"}
									>
										Reviews
									</NavLink>
								</li>
								{/* <li>
									<NavHashLink smooth
										to="/#contact"
										className={`text-gray-900 font-medium hover:cursor-pointer 
										hover:opacity-70 opacity-100 transition ease-linear`
										}
									>
										Contact
									</NavHashLink >
								</li> */}
							</ul>
						</div>

						<NavLink to="/select-station">
							<button
								className="hidden bg-[#1A56DB] transition-all ease-linear lg:block hover:bg-opacity-85 cursor-pointer px-6 py-3 rounded-lg text-white"
							// style={{ backgroundColor: dynamicColors.primary }}
							>
								Buy Tickets
							</button>
						</NavLink>
						{!showMenu ? (
							<button
								className="lg:hidden text-gray-500"
								onClick={() => setShowMenu(true)}>
								<HiMenu size={30} />
							</button>
						) : (
							<button
								className="lg:hidden text-gray-500"
								onClick={() => setShowMenu(false)}>
								<IoMdClose size={30} />
							</button>
						)}
					</div>
				</T40Container>
			</nav >

			{showMenu && (
				<div className="lg:hidden w-full py-5 bg-white shadow border-b">
					<T40Container>
						<div>
							<ul className="flex flex-col items-center justify-center gap-8 ">
								<li>
									<NavLink
										to="/select-station"
										className={({ isActive }) => isActive ? "border-b-[3px] underline-offset-[10px] border-orange-400"
											: "text-gray-900 font-medium hover:cursor-pointer hover:opacity-70 opacity-100 transition ease-linear"}
										onClick={handleClose}>
										Tickets
									</NavLink>
								</li>
								<li>
									<NavLink
										to="/locations"
										onClick={handleClose}
										className={({ isActive }) => isActive ? "border-b-[3px] underline-offset-[10px] border-orange-400"
											: "text-gray-900 font-medium hover:cursor-pointer hover:opacity-70 opacity-100 transition ease-linear"}
									>
										Locations
									</NavLink>
								</li>

								<li>
									<NavLink to="/reviews"
										className={({ isActive }) => isActive ? "border-b-[3px] underline-offset-[10px] border-orange-400"
											: "text-gray-900 font-medium hover:cursor-pointer hover:opacity-70 opacity-100 transition ease-linear"}
										onClick={handleClose}
									>
										Reviews
									</NavLink>
								</li>

								{/* <li>
									<NavHashLink smooth
										to="/#contact"
										className={`text-gray-900 font-medium hover:cursor-pointer 
										hover:opacity-70 opacity-100 transition ease-linear`
										}
										onClick={handleClose}
									>
										Contact
									</NavHashLink >
								</li> */}
								<li>
									<NavLink to="/select-station" onClick={handleClose}>
										<button
											className="px-6 py-3 rounded-lg text-white"
											style={{ backgroundColor: dynamicColors.primary }}>
											Buy Tickets
										</button>
									</NavLink>
								</li>
							</ul>
						</div>
					</T40Container>
				</div>
			)
			}
		</>
	);
}
