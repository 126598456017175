import * as React from "react";
import CustomButton from "../../../utils/constants/Button";
import { useNavigate, useParams } from "react-router-dom";
import Lottie from "lottie-react";
import Check from "../../../lottie/check.json";


type PassengerType = {
	firstName: string,
	lastName: string,
	phoneNumber: string,
	gender: string,
	emailAddress: string,
	nokFirstName: string,
	nokLastName: string,
	nokPhoneNumber: string
}

type SuccessfullyBookedProps = {
	passengerDetails: PassengerType[]
}



export function SuccessfullyBooked({ passengerDetails }: SuccessfullyBookedProps) {
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();

	const name = passengerDetails[0].firstName;

	return (
		<div>
			<div
				className={`flex z-50 fixed inset-0 justify-center items-center bg-black bg-opacity-55`}>
				<div className=" bg-white p-4 w-full mx-4 max-w-lg shadow-lg rounded-3xl">
					<div className="space-y-3">
						<div className="w-full flex items-center justify-center">
							<Lottie animationData={Check} loop={true} className="w-52 h-52" />
						</div>
						<div className="space-y-3 ">
							<h2 className="text-center text-black text-lg font-bold">
								Payment Successful
							</h2>
							<p className="text-center text-sm ">
								You can go ahead to view the details in your receipt, Don&apos;t
								forget to download a copy
							</p>
						</div>
						<div className="space-y-3 pt-4">
							<CustomButton
								text="View Receipt"
								type="button"
								small={false}
								onClick={() => navigate(`/select-station/${id}/receipt`)}
							/>
							<button
								type="button"
								onClick={() => navigate("/")}
								className="bg-gray-800 text-white w-full border border-gray-200 text-base font-medium rounded-lg px-6 py-3.5">
								Go Home
							</button>

							<button
								type="button"
								onClick={() => navigate("/email-review", { state: { key: name } })}
								className="bg-gray-50 text-orange-400 w-full border border-gray-200 text-base font-medium rounded-lg px-6 py-3.5">
								Write a review
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
