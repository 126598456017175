import React from 'react'
import T40Container from '../../layout/Container'
import { Headings } from '../../utils/constants/Headings'
import { brandName } from '../../api/ApiConfig'


type FaqTypes = {
    id:number,
    title: string,
    details: string
}

const Faq = () => {

    const faqs: FaqTypes[] = [
        {
            id: 1,
            title: `How do I buy tickets with ${brandName}?`,
            details: `
                Start by searching for rides; Select your 
                location and destination; Add departure time 
                and other necessary passenger information; Make 
                payment. And then your bus ticket will be sent 
                to you via email or SMS.
            `
        },
        {
            id: 2,
            title: `How many available routes does ${brandName} have?`,
            details: `
            Currently ${brandName} has 5 available routes and 
            we are actively working on increasing our routes 
            to enable us serve you better.
            `
        },
        {
            id: 3,
            title: "Will I be able to use the ticket I bought online at the motor park?",
            details: `
            Yes, every ticket bought online is recognized and 
            accepted at the motor park.
            `
        },
        {
            id: 4,
            title: `Does ${brandName} offer inter state charter services`,
            details: `
            Yes we offer safe and secure ride charter services for 
            different types of vehicle.
            `
        }
    ]
    return (
        <div className="py-[40px] bg-teal-50">
            <T40Container>
                <Headings
                    level="h2"
                    title="Frequently asked questions"
                    className="text-left leading-[45px] text-t40Black text-xl lg:text-4xl font-bold lg:font-bold"
                />

                <div className="md:my-[43px] md:grid grid-cols-2 gap-16 md:space-y-0 space-y-2.5 p-5 md:p-0">
                    {
                        faqs.map((item: FaqTypes) => (
                            <div key={item.id} className='h-full md:border-0 md:border-none border-b border-[#e5e7eb] flex flex-col justify-between'>
                                <div>
                                    <h3 className='text-sm md:text-2xl text-t40Black font-semibold'>{item.title}</h3>
                                </div>

                                <div className='pt-6'>
                                    <p className='text-gray-500 md:text-base text-xs leading-[24px]'>{item.details}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </T40Container>
        </div>
    )
}

export default Faq