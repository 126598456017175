import React, { ReactNode } from "react";

interface BottomDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	drawerClasses: string; // Change type to string
	setDrawerClasses: (classes: string) => void; // Add parameter type
	children: ReactNode;
}

const BottomDrawer: React.FC<BottomDrawerProps> = ({
	isOpen,
	onClose,
	children,
	drawerClasses,
	setDrawerClasses,
}) => {
	// const [drawerClasses, setDrawerClasses] = useState<string>(
	// 	isOpen ? "translate-y-0" : "translate-y-full"
	// );

	const closeDrawer = () => {
		setDrawerClasses("translate-y-full");
		onClose();
	};

	// const openDrawer = () => {
	// 	setDrawerClasses("translate-y-0");
	// };

	return (
		<>
			{/* Overlay */}
			{isOpen && (
				<div
					className="fixed inset-0 bg-black opacity-50"
					onClick={closeDrawer}
					aria-hidden="true"></div>
			)}

			{/* Bottom Drawer */}
			<div
				className={`lg:hidden z-50 fixed bottom-0 left-0 w-full h-[80vh] bg-white shadow-lg transform transition-transform duration-300 rounded-tl-3xl rounded-tr-3xl ${drawerClasses}`}>
				{/* Drawer Content */}
				<div className="h-full overflow-auto">{children}</div>
			</div>

			{/* modal  */}
			<div
				className={`hidden z-50 lg:flex fixed inset-0 justify-center items-center ${drawerClasses}`}>
				<div className="relative bg-white w-full max-w-lg max-h-[85vh] h-full overflow-auto rounded-3xl">
					{/* Modal Content */}
					<div className="relative">{children}</div>
				</div>
			</div>
		</>
	);
};

export default BottomDrawer;
