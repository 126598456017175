/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { TripType, useBookingContext } from "../../context/BookingContext";
import { IoPerson } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { HiLocationMarker } from "react-icons/hi";
import { TbTransfer, TbTransferVertical } from "react-icons/tb";
import DateInput from "../../utils/constants/CustomDate";
import CitySelect from "../../utils/constants/CitySelect";
import baseURL, { brandURL } from "../../api/ApiConfig";
import axios from "axios";
import { formatDate } from "../../utils/date";

interface IBookTripProps {
    selectedFromCity: CityOption | null;
    setSelectedFromCity: (selectedCity: CityOption | null) => void;
    selectedToCity: CityOption | null;
    setSelectedToCity: (selectedCity: CityOption | null) => void;
    departureDateInput: Date | null;
    setDepartureDateInput: (date: Date | null) => void;
    returnDateInput: Date | null;
    setReturnDateInput: (date: Date | null) => void;
    setAvailableRides: (availableRides: []) => void;
    setLoadingResult: (loading: boolean) => void;
    setLastPage: (pageName: number) => void;
}

interface CityOption {
    value: string;
    label: string;
}

interface City {
    id: string;
    name: string;
    code: string | null;
}

const BookTrip: React.FC<IBookTripProps> = ({
    selectedFromCity,
    setSelectedFromCity,
    selectedToCity,
    setSelectedToCity,
    departureDateInput,
    setDepartureDateInput,
    returnDateInput,
    setReturnDateInput,
    setAvailableRides,
    setLoadingResult,
    setLastPage,
}) => {
    const navigate = useNavigate();
    const [fromCities, setFromCities] = React.useState<City[]>([]);
    const [toCities, setToCities] = React.useState<City[]>([]);
    const { tripType, setTripType, numberOfTravelers, setNumberOfTravelers } = useBookingContext();
    const [availableRidesLength, setAvailableRidesLength] = React.useState<any | null>([]);
    const [numberOfRides, setNumberOfRides] = React.useState<number>(0);

    const handleTripTypeChange = (selectedType: TripType) => {
        setTripType(selectedType);
    };

    const handleDepatureDateChange = (date: Date | null) => {
        setDepartureDateInput(date);
    };

    const handleReturnDateChange = (date: Date | null) => {
        setReturnDateInput(date);
    };

    const handleFromCitySelect = (selectedCity: CityOption | null) => {
        setSelectedFromCity(selectedCity);
    };

    const handleToCitySelect = (selectedCity: CityOption | null) => {
        setSelectedToCity(selectedCity);
    };

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseURL}/v1/intercity/connections/routes`);
                // console.log("Response", response.data.data);
                const routesResult = response.data.data;
                // setFromCities(routesResult.from_cities);
                // setToCities(routesResult.to_cities);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    const handleBookForm = (e: React.FormEvent) => {
        e.preventDefault(); // Prevent default form submission behavior
        const fromCityParam = selectedFromCity ? `&from_city=${encodeURIComponent(selectedFromCity?.label)}` : "";
        const toCityParam = selectedToCity ? `&to_city=${encodeURIComponent(selectedToCity?.label)}` : "";
        const departureDateParam = departureDateInput ? `&departure_date=${formatDate(departureDateInput)}` : "";
        const returnDateParam = returnDateInput ? `&return_date=${formatDate(returnDateInput)}` : "";
        const passengers = numberOfTravelers ? `&pax=${numberOfTravelers}` : "";
        const brand = brandURL ? `&brand_url=${brandURL}` : "";

        navigate(
            `/select-station?${fromCityParam || ""}${toCityParam || ""}${departureDateParam ?? ""}${returnDateParam ?? ""}${brand}${passengers ?? ""}`
        );
    };

    const handleTripResults = async () => {
        setLoadingResult(true);

        try {
            const fromCityParam = selectedFromCity ? `&from_city=${encodeURIComponent(selectedFromCity.label)}` : "";
            const toCityParam = selectedToCity ? `&to_city=${encodeURIComponent(selectedToCity.label)}` : "";
            const departureDateParam = departureDateInput ? `&departure_date=${formatDate(departureDateInput)}` : "";
            const passenger = numberOfTravelers ? `&pax=${numberOfTravelers}` : "";
            const brand = brandURL ? `&brand_url=${brandURL}` : "";

            const searchURL = `${baseURL}/v1/intercity/connections/find?per_page=10&page=${1}${fromCityParam}${toCityParam}${departureDateParam}${brand}${passenger}`;

            const response = await axios.get(searchURL);
            console.log("here: ", response.data.data);

            const routesResult = response.data.data;
            const fromCities: any[] = Array.from(
                new Map(
                    routesResult.map((ride: any) => [
                        ride.ride_meta.ride_departure_location.city.name,
                        ride.ride_meta.ride_departure_location.city,
                    ])
                ).values()
            );

            const toCities: any[] = Array.from(
                new Map(
                    routesResult.map((ride: any) => [
                        ride.ride_meta.ride_destination.location.city.name,
                        ride.ride_meta.ride_destination.location.city,
                    ])
                ).values()
            );

            setFromCities(fromCities);
            setToCities(toCities);


            setLastPage(response.data.meta.last_page);
            const available = response.data.data;

            setNumberOfRides(response?.data?.data?.length);
            setAvailableRidesLength(available);
            setAvailableRides(available);
            setLoadingResult(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoadingResult(false);
        }
    };

    const handleTripResults2 = async (pax: number) => {
        setLoadingResult(true);

        try {
            const fromCityParam = selectedFromCity ? `&from_city=${encodeURIComponent(selectedFromCity.label)}` : "";
            const toCityParam = selectedToCity ? `&to_city=${encodeURIComponent(selectedToCity.label)}` : "";
            const departureDateParam = departureDateInput ? `&departure_date=${formatDate(departureDateInput)}` : "";
            const passenger = pax ? `&pax=${pax}` : "";
            const brand = brandURL ? `&brand_url=${brandURL}` : "";

            const searchURL = `${baseURL}/v1/intercity/connections/find?per_page=10&page=${1}${fromCityParam}${toCityParam}${departureDateParam}${brand}${passenger}`;

            const response = await axios.get(searchURL);
            console.log("hmmm", response.data.data);

            const routesResult = response.data.data;
            const fromCities: any[] = Array.from(
                new Map(
                    routesResult.map((ride: any) => [
                        ride.ride_meta.ride_departure_location.city.name,
                        ride.ride_meta.ride_departure_location.city,
                    ])
                ).values()
            );

            const toCities: any[] = Array.from(
                new Map(
                    routesResult.map((ride: any) => [
                        ride.ride_meta.ride_destination.location.city.name,
                        ride.ride_meta.ride_destination.location.city,
                    ])
                ).values()
            );

            setFromCities(fromCities);
            setToCities(toCities);

            setLastPage(response.data.meta.last_page);
            const available = response.data.data;
            setAvailableRides(available);
            setLoadingResult(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoadingResult(false);
        }
    };

    const handleNumberOfTravelersChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newNumberOfTravelers = Number(event.target.value);

        setNumberOfTravelers((prevNumberOfTravelers) => {
            return newNumberOfTravelers;
        });

        handleTripResults2(newNumberOfTravelers);
    };

    React.useEffect(() => {
        handleTripResults();
    }, [selectedFromCity, selectedToCity, departureDateInput, numberOfTravelers]); // Run effect on changes to relevant state variables

    return (
        <div>
            <div className="lg:py-5">
                <div className="flex gap-3">
                    <div className="hidden lg:flex gap-2">
                        <button
                            className={`px-3 py-2 rounded border-0 text-sm ${tripType === TripType.OneWay ? "bg-blue-700 text-white" : "bg-gray-50 text-gray-500"}`}
                            onClick={() => handleTripTypeChange(TripType.OneWay)}>
                            One Way
                        </button>
                    </div>

                    <div className="relative lg:hidden">
                        <select
                            className="w-full pl-2 pr-8 bg-transparent border border-transparent p-2 rounded appearance-none ring-0 ring-transparent focus:outline-none focus:border-transparent"
                            value={tripType}
                            onChange={(e) => handleTripTypeChange(e.target.value as TripType)}>
                            {Object.values(TripType).map((type) => (
                                <option key={type} value={type} className="text-gray-900">
                                    {type}
                                </option>
                            ))}
                        </select>
                        <div className="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none"></div>
                    </div>

                    <div className="relative flex items-center">
                        <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
                            <IoPerson className="text-gray-500" />
                        </div>
                        <select
                            className="w-full pl-8 pr-8 bg-transparent border border-transparent p-2 rounded appearance-none focus:outline-none focus:border-transparent"
                            value={numberOfTravelers}
                            onChange={handleNumberOfTravelersChange}>
                            {[1, 2, 3, 4, 5].map((number) => (
                                <option key={number} value={number} className="text-gray-900">
                                    {number} {number === 1 ? "Traveller" : "Travellers"}
                                </option>
                            ))}
                        </select>
                        <div className="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none"></div>
                    </div>
                </div>

                <div>
                    <form
                        onSubmit={handleBookForm}
                        autoComplete="off"
                        className="w-full space-y-5 mt-5 xl:mt-10 xl:flex xl:items-center">
                        <div className="relative space-y-5 xl:space-y-0 xl:flex xl:gap-4 xl:w-full">
                            <CitySelect
                                selectedCity={selectedFromCity}
                                setSelectedCity={handleFromCitySelect}
                                cities={fromCities}
                                onSelectCity={handleFromCitySelect}
                                label="Travel from"
                                prefixIcon={<HiLocationMarker />}
                            // onBlur={handleTripResults}
                            />
                            <div className="z-10 mx-auto flex justify-center items-center">
                                <div>
                                    <TbTransfer className="bg-orange-400 p-1 rounded-full w-6 h-6 xl:w-5 xl:h-5 hidden xl:block" />
                                    <TbTransferVertical className="bg-orange-400 p-1 rounded-full w-6 h-6 xl:w-5 xl:h-5 xl:hidden" />
                                </div>
                            </div>
                            <CitySelect
                                selectedCity={selectedToCity}
                                setSelectedCity={handleToCitySelect}
                                cities={toCities}
                                onSelectCity={handleToCitySelect}
                                label="Travel to"
                                prefixIcon={<HiLocationMarker />}
                            // onBlur={handleTripResults}
                            />
                            <DateInput
                                label="Departure date"
                                selectedDate={departureDateInput}
                                handleDateChange={handleDepatureDateChange}
                                // onBlur={handleTripResults}
                                disabled={false}
                            />
                            <DateInput
                                label="Return date"
                                selectedDate={returnDateInput}
                                handleDateChange={handleReturnDateChange}
                                // onBlur={handleTripResults}
                                disabled={true}
                            />

                            <div className="flex items-end justify-center w-full">
                                <button className="text-white w-full h-fit  bg-[#1A56DB] transition-all ease-linear hover:bg-opacity-85 cursor-pointer text-base font-medium rounded-lg px-2 py-3">
                                    {numberOfRides} rides available
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default BookTrip;
