import T40Container from "../layout/Container";
import { Link } from "react-router-dom";
import { getDynamicColors } from "../utils/dynamic/colorUtils";
import { IntercityIcon } from "../assets/ExportSvg";
import {
	FaFacebookF,
	FaInstagram,
	FaLinkedin,
	FaXTwitter,
} from "react-icons/fa6";
import { IoChevronDown } from "react-icons/io5";
// import logo from '../assets/logo-white.svg';

export default function Footer() {
	const dynamicColor = getDynamicColors();
	const logo = process.env.REACT_APP_BRAND_LOGO_FOOTER;

	return (
		<>
			<footer className="bg-slate-950 py-20">
				<T40Container>
					<div className="w-full">
						<div className="w-full md:flex items-center justify-center">
							<div className="w-full flex flex-col items-center gap-2 lg:items-start">
								<Link to="/">
									<img
										src={logo}
										// src="/unique.png"
										alt="Logo"
										className="w-[104px] object-cover"
									/>
								</Link>
								<p className="text-white text-xs font-normal">
									Copyright © 2023. All rights reserved
								</p>
							</div>

							<div className="flex items-center justify-center">
								<div className="flex justify-center items-center">
									<ul className="md:mt-0 my-6 footer-link text-sm flex flex-col items-center md:flex-row md:space-y-0 space-y-3 space-x-0 md:space-x-5">
										{links.map((link, index) => (
											<li key={index}>
												<Link to={link?.href}>{link?.label}</Link>
											</li>
										))}
									</ul>
								</div>
							</div>

							<div className="w-full flex items-center justify-center md:justify-end gap-3">
								<div
									className="rounded-full w-11 h-11 flex items-center justify-center"
									style={{ backgroundColor: dynamicColor.primary }}>
									<FaFacebookF className="text-white" />
								</div>
								<div
									className="rounded-full w-11 h-11 flex items-center justify-center"
									style={{ backgroundColor: dynamicColor.primary }}>
									<FaXTwitter className="text-white" />
								</div>
								<div
									className="rounded-full w-11 h-11 flex items-center justify-center"
									style={{ backgroundColor: dynamicColor.primary }}>
									<FaInstagram className="text-white" />
								</div>
								<div
									className="rounded-full w-11 h-11 flex items-center justify-center"
									style={{ backgroundColor: dynamicColor.primary }}>
									<FaLinkedin className="text-white" />
								</div>
							</div>
						</div>

						<div className="hidden w-full lg:flex items-center justify-center gap-2 mt-10">
							<span className="text-white text-xs font-normal capitalize">
								Powered by
							</span>
							<IntercityIcon />
							<span className="text-white text-lg font-bold leading-tight">
								intercity
							</span>
						</div>

						<div className="w-full lg:hidden flex items-center justify-center gap-2 mt-10">
							<span className="text-white text-xs font-normal capitalize">
								Powered by
							</span>
							<IntercityIcon />
							<span className="text-white text-lg font-bold leading-tight">
								intercity
							</span>
						</div>

						{/* <div className="flex items-center justify-center gap-2 mt-0">
							<span className="text-white text-xs font-normal capitalize">
								Powered by
							</span>
							<IntercityIcon />
							<span className="text-white text-lg font-bold capitalize leading-tight">
								Intercity
							</span>
						</div> */}
					</div>
				</T40Container>
			</footer>
		</>
	);
}

const links = [
	{ href: "/select-station", label: <div className="flex items-center space-x-1"><span>Tickets</span></div> },
	{ href: "/", label: "Parcels" },
	{ href: "/", label: "Locations" },
	{ href: "/", label: "Contact" },
];
