import { brandName } from "../../api/ApiConfig";

const defaultPromoText = "Get a 5% discount on all bookings you make from now to December";
const defaultHeroTitle = "Your home for interstate travels across Nigeria";
const buyTicketPromoText = "All trips has to be booked earlier, at least a day before travelling date to secure your seat and confirm bookings, for more enquiry or more information. Talk to us ";
const buyTicketHeroTitle = `Buy Affordable Bus Tickets Online in Nigeria with ${brandName}`; 

export const getDynamicTexts = () => {
  const fetchedPromoText = defaultPromoText; 
  const fetchedHeroTitle = defaultHeroTitle; 


  const dynamicPromoText = fetchedPromoText || defaultPromoText;
  const dynamicHeroTitle = fetchedHeroTitle || defaultHeroTitle;
  const buyTicketPromoTitle = buyTicketPromoText || defaultPromoText;
  const ticketHeroTitle = buyTicketHeroTitle;

  return {
    promoText: dynamicPromoText,
    heroTitle: dynamicHeroTitle,
    ticketTitle: buyTicketPromoTitle,
    ticketHero: ticketHeroTitle,   
  };
};
