import React from 'react';
import KeyMap from '../utils/keyMap';
import { useNavigate } from 'react-router';
import { formatTime } from '../utils/date';
import { Location, Call, Message } from 'iconsax-react';
import SeatSelect from '../components/widgets/SeatSelect';
import RideCard from '../components/RideCard';
import GoBack from '../components/widgets/GoBack';
import GoogleMapsIframe from '../components/widgets/GoogleMapsIframe';

export default function RideDetails() {
    const navigate = useNavigate();
    const [rideData, setRideData] = React.useState<RideStorage | null>(null);
    const [selectedSeats, setSelectedSeats] = React.useState<string[]>([]);

    React.useEffect(
        () => {
            const storageData = JSON.parse(sessionStorage.getItem(KeyMap.CURRENT_RIDE_DATA) as string) as RideStorage || null;
            if (!storageData) {
                navigate("/");
            } else {
                setRideData(storageData)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    );

    const handleSubmit = React.useCallback(() => {
        sessionStorage.setItem(KeyMap.CURRENT_RIDE_DATA, JSON.stringify({
            ...rideData,
            seat: selectedSeats
        }))
        navigate('/ride/book')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSeats, rideData]);


    return <>
        <section className='t40-container lg:!px-12 pt-10'>
            <GoBack />
            <h1 className='text-2xl font-semibold mb-3'>Book Ticket</h1>
            {rideData && <RideCard ride={rideData?.ride} pax={rideData.pax} departure_date={new Date(rideData.departure_date)} hideButton widthFull/>}
        </section>

        <div className='t40-container lg:!px-12 pb-20 lg:py-20 text-[#102751]'>
            <div className='grid lg:grid-cols-2 gap-10 -mt-5'>
                {/* First Column */}
                <div className='grid mt-12 lg:mt-0 gap-10 h-fit'>
                    <div>
                        <h1 className='text-xl font-semibold'>{rideData?.ride.ride_meta.departure_location.city.name} - {rideData?.ride.ride_meta.ride_destination.location.city.name}</h1>
                        <h1 className='text-sm mt-2'>Departure {new Date(rideData?.ride.departure_date || '').toDateString()}</h1>
                        <div className='flex mt-2'>
                            <h2 className='bg-[#ECE7F9] mr-3 text-sm md:text-md rounded-md text-[#6C5B97] px-2 py-1'>{rideData?.ride.ride_meta.vehicle_type.name}</h2>
                            <h2 className='bg-[#F5FED4] mr-3 text-sm md:text-md rounded-md text-[#667D13] px-2 py-1'>{formatTime(rideData?.ride.ride_meta.departure_time || "")}</h2>
                        </div>
                        <h1 className='text-orange-600 bg-orange-1 text-lg px-2 py-1 font-semibold rounded-lg block lg:hidden w-fit mt-3'>&#8358;{rideData?.ride.ride_meta.ride_destinations[0].amount_with_discount.toLocaleString()}</h1>
                    </div>
                    <div className='block lg:hidden'>
                        <h1 className='text-lg font-semibold'>Departure Station</h1>
                        <h1 className='flex items-center mt-4'><Location className='mr-2' size={20} variant='Bold' color='#627496' /> {rideData?.ride.ride_meta.departure_location.name}, {rideData?.ride.ride_meta.departure_location.address}</h1>
                    </div>
                    <div className='block lg:hidden'>
                        <h1 className='text-lg font-semibold'>Destination Station</h1>
                        <h1 className='flex items-center mt-4'><Location className='mr-2' size={20} variant='Bold' color='#627496' /> {rideData?.ride.ride_meta.ride_destination.location.name}, {rideData?.ride.ride_meta.ride_destination.location.address}</h1>
                    </div>
                    <div className='h-fit block lg:hidden'>
                        <h1 className='text-lg font-semibold '>Contact</h1>
                        <h1 className='flex items-center mt-4'><Call className='mr-2' size={20} variant='Bold' color='#627496' /> {rideData?.ride.ride_meta.business.phone_number}</h1>
                        <h1 className='flex items-center mt-2'><Message className='mr-2' size={20} variant='Bold' color='#627496' /> {rideData?.ride.ride_meta.business.business_email}</h1>
                    </div>

                    <div className='rounded-lg overflow-hidden h-56 block lg:hidden'>
                        <GoogleMapsIframe url={process.env.REACT_APP_GOOGLE_MAPS_EMBED_URL as string} />
                    </div>

                    {/* Ammenites is comming soon */}

                    <div>
                        <h2 className='text-lg font-semibold mb-5'>Select Seat</h2>
                        <SeatSelect row={rideData?.ride.ride_meta.vehicle_type.row_col[0]}
                            column={rideData?.ride.ride_meta.vehicle_type.row_col[1]}
                            seatMap={rideData?.ride.seats || []}
                            pax={rideData?.pax || 1}
                            onChange={setSelectedSeats}
                        />
                    </div>

                </div>

                
                <div className='grid gap-7 h-fit'>
                    <div className='hidden lg:block'>
                        <h1 className='text-lg font-semibold'>Departure Station</h1>
                        <h1 className='flex items-center mt-1'><Location className='mr-2' size={20} variant='Bold' color='#627496' /> {rideData?.ride.ride_meta.departure_location.name}, {rideData?.ride.ride_meta.departure_location.address}</h1>
                    </div>
                    <div className='hidden lg:block'>
                        <h1 className='text-lg font-semibold'>Destination Station</h1>
                        <h1 className='flex items-center mt-1'><Location className='mr-2' size={20} variant='Bold' color='#627496' /> {rideData?.ride.ride_meta.ride_destination.location.name}, {rideData?.ride.ride_meta.ride_destination.location.address}</h1>
                    </div>

                    <div className='h-fit hidden lg:block'>
                        <h1 className='text-lg font-semibold '>Contact</h1>
                        <h1 className='flex items-center mt-4'><Call className='mr-2' size={20} variant='Bold' color='#627496' /> {rideData?.ride.ride_meta.business.phone_number}</h1>
                        <h1 className='flex items-center mt-2'><Message className='mr-2' size={20} variant='Bold' color='#627496' /> {rideData?.ride.ride_meta.business.business_email}</h1>
                    </div>

                    <div className='rounded-lg overflow-hidden h-56 hidden lg:block'>
                        <GoogleMapsIframe url={process.env.REACT_APP_GOOGLE_MAPS_EMBED_URL as string} />
                    </div>
                    {/* PickupLocation Selector */}
                    {/* <div className='bg-[#E4E7ED] p-5 md:px-8 lg:px-8 md:py-3 lg:py-5  rounded-lg grid gap-3 relative overflow-hidden'>
                    <h1 className='text-lg font-semibold'>Pickup Location <span className='text-sm'>{'(Optional)'}</span></h1>
                    <Select hideIcon label='Pick Location' className='pr-3' options={[]} />
                    <div className='rounded-lg bg-white p-3 flex justify-between items-center'>
                        <h1 className='text-sm'>Pickup Time:</h1>
                        <h1 className='text-lg font-semibold'>--:--am</h1>
                    </div>
                    <div className='rounded-lg bg-white p-3 flex justify-between items-center'>
                        <h1 className='text-sm'>Pickup amount:</h1>
                        <h1 className='text-lg font-semibold text-orange-600'>&#8358;0</h1>
                    </div>

                    <div className='w-full h-full bg-blue-8/20 absolute hover:backdrop-blur-sm flex' onMouseEnter={() => setCommingSoon(true)}
                        onMouseLeave={() => setCommingSoon(false)}>
                        {showCommingSoon && <h1 className='text-xl m-auto font-semibold'>Coming Soon 🤞</h1>}
                    </div>
                </div> */}

                    <button className='btn bg-t40Blue-500 rounded-lg w-full py-4 transition-all disabled:opacity-70 text-white'
                        disabled={selectedSeats.length !== rideData?.pax} onClick={handleSubmit}>Book Ticket</button>
                </div>
            </div>
        </div>
    </>
}