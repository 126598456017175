import React from 'react';
import Select from './widgets/ui/forms/Select';
import DateSelect from './widgets/ui/DateSelect';
import { useRideLocation } from '../context/Context';
import { formatDate, getFutureDate } from '../utils/date';
import ApiFetcher from '../utils/api';

interface SearchBoxProps {
    onClick?: (e: rideFilterType) => void,
    initialRideFilter?: rideFilterType | null,
    rideCount?: boolean
}

export const NULL_ROUTE_OPTION: OptionProps = { label: "Anywhere", value: null }

export default function SearchBox({
    onClick, initialRideFilter, rideCount = false
}: SearchBoxProps) {
    const [isOneWay, setIsOneWay] = React.useState(true);
    const rideLocations = useRideLocation();
    const [rideCounter, setRideCount] = React.useState(0);

    const [fromCitiesOptions, toCitiesOptions] = React.useMemo(() => {
        const returnArray: OptionProps[][] = [[], []];
        Object.entries(rideLocations).forEach(([field, value]) => {
            const valueMap = value.map((value: City) => ({
                label: value.name,
                value: value.name,
            }));
            returnArray[field === 'from_cities' ? 0 : 1] = valueMap;
        });
        return returnArray;
    }, [rideLocations]);

    React.useEffect(
        () => {
            initialRideFilter && setRideFilter(initialRideFilter)
        }, [initialRideFilter]
    )



    const [rideFilter, setRideFilter] = React.useState<rideFilterType>({
        from_ride: NULL_ROUTE_OPTION,
        return_date: null,
        departure_date: getFutureDate(1),
        to_ride: NULL_ROUTE_OPTION,
        pax: 1,
    });

    const handleFrom = (e: OptionProps) => {
        if (!e.value || e.label !== rideFilter.to_ride?.label)
            setRideFilter({
                ...rideFilter,
                from_ride: e,
            });
    };
    const handleTo = (e: OptionProps) => {
        if (!e.value || e.label !== rideFilter.from_ride?.label)
            setRideFilter({
                ...rideFilter,
                to_ride: e,
            });
    };

    const handleDateChange = (e: Date | null) => {
        if (e == null) {
            e = getFutureDate(1);
        }
        setRideFilter({
            ...rideFilter,
            departure_date: e,
        });
    };
    const handleReturnDateChange = (e: Date | null) => {
        setIsOneWay(e === null); // toggle back on when the return date is selected
        setRideFilter({
            ...rideFilter,
            return_date: e,
        });
    };

    const minReturnDate = React.useMemo(() => {
        const { departure_date, return_date } = rideFilter;
        const returnFuture = getFutureDate(
            1,
            departure_date ? departure_date : getFutureDate(1),
        );
        if (return_date && return_date < returnFuture) {
            setRideFilter({
                ...rideFilter,
                return_date: returnFuture,
            });
        }
        return returnFuture;
    }, [rideFilter]);

    async function getRideCount() {
        let additionalString = '';

        if (rideFilter?.from_ride?.value)
            additionalString += `from_city=${rideFilter.from_ride.label}&`;

        if (rideFilter?.to_ride?.value)
            additionalString += `to_city=${rideFilter.to_ride.label}&`;

        const res = await ApiFetcher.get(
            'connections/find?per_page=1&page=1&' +
            additionalString +
            'departure_date=' +
            formatDate(rideFilter.departure_date) +
            '&pax=1&brand_url=' +
            process.env.REACT_APP_BRAND_URL,
        );
        setRideCount(res.data.meta.total);
    }

    React.useEffect(() => {
        rideCount && getRideCount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rideFilter])

    return <div className='px-6 py-5 rounded-lg border border-[#E4E7ED]' style={{
        background: "linear-gradient(180deg, #FFF 0%, #E4E7ED 100%)"
    }}>
        <div className='flex items-center gap-5 mb-5'>
            <button onClick={() => setIsOneWay(true)} className={`btn ${!isOneWay ? "bg-white" : "bg-[#E4E7ED]"} border-[#E4E7ED] border`}>One Way</button>
            {/* <button onClick={() => setIsOneWay(false)} className={`btn ${isOneWay ? "bg-white" : "bg-[#E4E7ED]"} border-[#E4E7ED] border`}>Round Trip</button> */}
        </div>

        <div className={`grid justify-between grid-cols-1 items-end ${isOneWay ? "md:grid-cols-3" : "md:grid-cols-2"} lg:grid-cols-[repeat(15,1fr)] gap-5 md:gap-3 lg:gap-2 xl:gap-5`}>
            <div className={`col-span-1 ${isOneWay ? "lg:col-span-4" : "lg:col-span-3"} `}>
                <Select value={rideFilter.from_ride} placeholder='From' options={fromCitiesOptions} onChange={(e: any) => handleFrom(e)} />
            </div>

            <div className={`col-span-1 ${isOneWay ? "lg:col-span-4" : "lg:col-span-3"} `}>
                <Select value={rideFilter.to_ride} placeholder='To' options={toCitiesOptions} onChange={(e: any) => handleTo(e)} />
            </div>

            <div className={`col-span-1 ${isOneWay ? "lg:col-span-4" : "lg:col-span-3"} `}>
                <DateSelect
                    minDate={getFutureDate(1)}
                    onChange={e => handleDateChange(e)}
                    selected={rideFilter.departure_date} placeholderText='Departure' className='bg-white w-full h-[48px] rounded-lg px-2' style={{
                        boxShadow: "4px 4px 8px 0px rgba(182, 190, 196, 0.15), -2px -2px 8px 0px rgba(182, 190, 196, 0.15)"
                    }} />
            </div>
            {
                !isOneWay && <div className='lg:col-span-3'>
                    <DateSelect
                        minDate={minReturnDate}
                        onChange={e => handleReturnDateChange(e)}
                        selected={rideFilter.return_date} placeholderText='Return' className='bg-white w-full h-[48px] rounded-lg px-2' style={{
                            boxShadow: "4px 4px 8px 0px rgba(182, 190, 196, 0.15), -2px -2px 8px 0px rgba(182, 190, 196, 0.15)"
                        }} />
                </div>
            }
            <button onClick={() => onClick && onClick(rideFilter)} disabled={rideCount && (rideCounter === 0)} className='disabled:bg-[#102751]/60 btn h-[48px] col-span-full lg:col-span-3  bg-[#102751] text-white text-sm truncate'>{
                rideCount ? `${rideCounter} ${rideCounter === 1 ? "Ride" : "Rides"} Available` : "Search"
            }</button>
        </div> 
    </div>
}