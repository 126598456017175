import * as React from "react";
import BookTrip from "./BookTrip";

interface ICharterRequestProps {}

const CharterRequest: React.FunctionComponent<ICharterRequestProps> = (
	props
) => {
	return (
		<div className="w-full">
			<p className="text-gray-500 font-bold text-center">Coming Soon...</p>
			<div className="opacity-0">
				<BookTrip />
			</div>
		</div>
	);
};

export default CharterRequest;
