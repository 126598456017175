import React from 'react';
import SearchBox, { NULL_ROUTE_OPTION } from '../components/SearchBox';
import RideCard from '../components/RideCard';
import { useSearchParams } from 'react-router-dom';
import Loading from 'react-loading';
import ApiFetcher from '../utils/api';
import { formatDate, getFutureDate } from '../utils/date';
import Pagination from '../components/widgets/Pagination';
import GoBack from '../components/widgets/GoBack';

const PER_PAGE = 5;

export default function Rides() {
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [numOfPages, setNumOfPages] = React.useState(1);
    const [rides, setRides] = React.useState<RideType[]>([]);
    const [rideCount, setRideCount] = React.useState(0);

    const urlFilter = React.useMemo<rideFilterType>(() => {
        const fromRide = searchParams.get('from_ride') as string;
        const toRide = searchParams.get('to_ride') as string;
        const departure_date = searchParams.get('departure_date');
        const returnDate = searchParams.get('return_date');
        return ({
            from_ride: fromRide && (fromRide !== "Anywhere") ? { label: fromRide, value: fromRide } : NULL_ROUTE_OPTION,
            to_ride: toRide && (toRide !== "Anywhere") ? { label: toRide, value: toRide } : NULL_ROUTE_OPTION,
            departure_date: departure_date ? new Date(departure_date) : getFutureDate(1),
            return_date: returnDate ? new Date(returnDate) : null,
            pax: 1
        })
    }, [searchParams])

    async function handleRideFetch(rideFilter: rideFilterType) {
        try {
            setLoading(true);
            const res = await ApiFetcher.get(
                `connections/find?from_city=${rideFilter.from_ride?.value || ''
                }&to_city=${rideFilter.to_ride?.value || ''}&pax=${rideFilter.pax
                }&page=${currentPage}&per_page=${PER_PAGE}&departure_date=${formatDate(
                    rideFilter.departure_date,
                )}&brand_url=${process.env.REACT_APP_BRAND_URL}`,
            );
            setRides(res.data.data);
            setNumOfPages(res.data.meta.last_page);
            setRideCount(res.data.meta.total);
        } catch { }
        finally {
            setLoading(false);
        }
    }





    React.useEffect(
        () => {
            urlFilter && handleRideFetch(urlFilter);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [urlFilter, currentPage]
    )
    return <>
        <div className='t40-container lg:!px-12 pt-10 pb-10'>
            <GoBack />
            <SearchBox initialRideFilter={urlFilter} onClick={handleRideFetch} />

            <h1 className='text-3xl font-semibold mt-10'>Available Rides</h1>
            {
                loading ?
                    <div className='flex flex-col gap-2 justify-center items-center h-48'>
                        <Loading type='spin' color='#102751' />
                        <p>Updating Ride data</p>
                    </div> : rides.length !== 0 ?
                        <div className='grid mt-5 gap-5'>
                            {
                                rides.map((ride, index) => <RideCard key={"ride" + index}
                                    ride={ride}
                                    pax={urlFilter.pax}
                                    departure_date={urlFilter.departure_date as Date}
                                />)
                            }
                        </div> : <div className='flex flex-col gap-2 justify-center items-center h-48'>
                            <p className='text-center'>
                                Ooops!!! 😢 No results found <br />We could not find available rides for your search</p>
                        </div>
            }
            <div className="flex justify-center mt-5">
                {rideCount > PER_PAGE ? (
                    <Pagination
                        page={currentPage - 1}
                        numOfPages={numOfPages}
                        onChange={e => setCurrentPage(e.selected + 1)}
                    />
                ) : (
                    <></>
                )}
            </div>
        </div>
    </>
}