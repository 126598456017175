/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState, useEffect } from 'react';
import T40Container from '../layout/Container';
import reviewImage from '../assets/review-img.png'
import reviewBlock from '../assets/review-block.svg';
import arrowRight from '../assets/review-arrow-right.svg';
import ReviewInput from '../components/widgets/ui/forms/ReviewInput';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import greenPlus from '../assets/circle-plus.svg';
import { Rating } from '@mui/material';
import BaseButton from '../components/widgets/ui/button/BaseButton';
import { NavLink, useNavigate } from 'react-router-dom';
import { FileInput, Label } from "flowbite-react";
import { FaSearch } from "react-icons/fa";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import axios from 'axios';
import baseURL, { brandID } from '../api/ApiConfig';
import { AnimatePresence, motion } from 'framer-motion';
import { Modal } from "flowbite-react";
import { Dropzone, FileMosaic, ExtFile } from "@files-ui/react";


const CreateReview = () => {

    const [open, setOpen] = useState(false);
    const [fromCity, setFromCity] = useState([]);
    const [toCity, setToCity] = useState([]);
    const [selectedFile, setSelectedFile] = useState<any>([]);
    const [previewUrl, setPreviewUrl] = useState<any>([]);
    const [selectedId, setSelectedId] = useState<any>(null);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [imageLink, setImageLink] = useState<string>("");
    const [files, setFiles] = useState<ExtFile[]>([]);
    const updateFiles = (incommingFiles: ExtFile[]) => {
        //do something with the files
        setFiles(incommingFiles);
        //even your own upload implementation
    };
    const removeFile = (id: string | number | undefined) => {
        setFiles(files.filter((x: ExtFile) => x.id !== id));
    };

    const handleFileChange = (e: any) => {
        const files = Array.from(e.target.files);
        setSelectedFile(files);

        if (files.length > 5) {
            return toast.error("Max of 5 images");
        }

        const previewUrls = files.map((item: any) => URL.createObjectURL(item));
        setPreviewUrl(previewUrls);
    }

    // console.log("selected file:", selectedFile[0]);
    // console.log("preview file:", previewUrl);

    // const uploadFile = async (file: File) => {
    //     const formData = new FormData();
    //     formData.append('image', file);

    //     try {
    //         const response = await axios.post(`${baseURL}/v1/intercity/images`, formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data'
    //             }
    //         });
    //         console.log("upload res:", response)
    //         return response.data.imageUrl;
    //     } catch (error) {
    //         console.error("upload error: ", error);
    //         toast.error("unable to upload image");
    //         throw error;
    //     }
    // }


    const navigate = useNavigate();

    const initialValues = {
        first_name: "",
        last_name: "",
        email_address: "",
        travel_date: new Date(),
        from_city_id: "",
        to_city_id: "",
        rating: "",
        description: ""
    }

    const validationSchema = Yup.object({
        first_name: Yup.string()
            .required('First name is required')
            .min(3, 'Must be 3 characters or more'),
        last_name: Yup.string()
            .required('Last name is required')
            .min(3, 'Must be 3 characters or more'),
        email_address: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        travel_date: Yup.string()
            .required('Business name is required'),
        from_city_id: Yup.string()
            .required('Origin is required')
            .min(3, 'Must be 3 characters or more'),
        to_city_id: Yup.string()
            .required('Destination is required')
            .min(3, 'Must be 3 characters or more'),
        rating: Yup.string()
            .required('rating is required'),
        description: Yup.string()
            .required('Review is required')
            .min(10, 'Must be 10 characters or more'),
    });

    const onReviewSubmission = async (values: any) => {
        try {

            // const firstImageFile = selectedFile[0];
            // const imageUrl = await uploadFile(firstImageFile);

            // console.log("img url: ", imageUrl);


            const formattedValues = {
                ...values,
                travel_date: values.travel_date.toISOString().split('T')[0]
            };
            console.table("Formatted review values: ", formattedValues);

            await axios.post(`${baseURL}/v1/intercity/reviews/ticket/unverified`, {
                first_name: formattedValues.first_name,
                last_name: formattedValues.last_name,
                email_address: formattedValues.email_address,
                rating: formattedValues.rating,
                description: formattedValues.description,
                brand_id: brandID,
                travel_date: formattedValues.travel_date,
                from_city_id: formattedValues.from_city_id,
                to_city_id: formattedValues.to_city_id,
                image_urls: [
                    "https://res.cloudinary.com/t40-nigeria/image/upload/v1712913422/v2/image/b2jfe37agnpzzsf59xpa.jpg",
                    "https://res.cloudinary.com/t40-nigeria/image/upload/v1712913422/v2/image/b2jfe37agnpzzsf59xpa.jpg",
                    "https://res.cloudinary.com/t40-nigeria/image/upload/v1712913422/v2/image/b2jfe37agnpzzsf59xpa.jpg",
                    "https://res.cloudinary.com/t40-nigeria/image/upload/v1712913422/v2/image/b2jfe37agnpzzsf59xpa.jpg",
                    "https://res.cloudinary.com/t40-nigeria/image/upload/v1712913422/v2/image/b2jfe37agnpzzsf59xpa.jpg"
                    // imageUrl
                ]
            }).then((res) => {
                console.log(res);
                console.log("successful")
                navigate("/reviews", { state: { key: true } })
            }).catch((error) => {
                console.log(error);
                toast.error("unable to create review")
            })

        } catch (error) {
            console.log(error);
            toast.error("unable to create review")
        }
    }

    const openDropzone = () => {
        setOpen(prev => !prev);
    }

    const getCities = async () => {
        try {
            axios.get(`${baseURL}/v1/intercity/generals/cities`)
                .then((res) => {
                    console.log("cities: ", res.data.data);
                    setFromCity(res.data.data);
                    setToCity(res.data.data);
                }).catch((err) => {
                    console.log(err);
                })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getCities();
    }, [])

    useEffect(() => {

        return () => {
            previewUrl?.map((item: any) => URL.revokeObjectURL(item));
        };
    }, [previewUrl]);

    return (
        <main className='py-16'>
            <T40Container>
                <section className='lg:grid grid-cols-12 md:gap-4'>
                    <motion.div
                        initial={{
                            x: -50,
                            opacity: 0,
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 100,
                            // damping: 70,
                            duration: 3,
                        }}
                        whileInView={{
                            x: 0,
                            opacity: 1
                        }}
                        className='col-span-4'
                    >
                        <h2 className='text-[#050D1B] break-words md:w-full w-[217px] text-[20px] md:text-3xl font-bold text-left leading-[30px] lg:leading-[60px]'>
                            Help us pave the way to better journeys!
                            Share your <span className='text-indigo-600'>experience</span> in a review.
                        </h2>

                        <div className='flex md:mt-0 mt-[6px] items-center'>
                            <img src={reviewBlock} alt="review blocks" />
                            <img src={reviewImage} alt="review image" />
                        </div>


                        <NavLink to="/reviews" className="mt-8 text-[#F2761B] text-sm md:text-lg font-semibold flex items-center space-x-2" >
                            <span>Click here to see previous reviews</span>
                            <motion.div
                                animate={{
                                    x: [0, 10, 0]
                                }}
                                transition={{
                                    repeat: Infinity,
                                    duration: 2,
                                }}
                            >
                                <img src={arrowRight} alt="right arrow" />
                            </motion.div>
                        </NavLink>
                    </motion.div>

                    <div className='col-span-8 md:mt-0 mt-[28px]'>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values: any, { resetForm }: { resetForm: any }) => {
                                onReviewSubmission(values);
                                resetForm({ values: initialValues });
                            }}
                        >
                            {
                                (
                                    { values, errors, touched, handleChange, setFieldValue, isValid, dirty }:
                                        {
                                            values: any, errors: any,
                                            touched: any, handleChange: any,
                                            setFieldValue: any, isValid: any, dirty: any
                                        }
                                ) => (
                                    <Form>
                                        <h4 className='text-gray-800 text-[20px] md:text-[30px] font-bold leading-[30px] md:leading-[45px] break-words'>
                                            Input your details below to enable you submit your review
                                        </h4>

                                        <div
                                            style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.05)" }}
                                            className='mt-4 md:mt-0 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-x-4 md:gap-y-4 md:space-y-0 space-y-4 p-4 md:p-5 bg-white rounded-[8px] border border-[#e5e7eb] '>
                                            <div>
                                                <ReviewInput
                                                    label="First name"
                                                    type="text"
                                                    name="first_name"
                                                    value={values.first_name}
                                                    onChange={handleChange}
                                                    placeholder="First name"
                                                />

                                                <p className='text-xs text-red-700'>
                                                    {errors.first_name && touched.first_name && errors.first_name}
                                                </p>
                                            </div>

                                            <div>
                                                <ReviewInput
                                                    label="Last name"
                                                    type="text"
                                                    name="last_name"
                                                    value={values.last_name}
                                                    onChange={handleChange}
                                                    placeholder="Last name"
                                                />

                                                <p className='text-xs text-red-700'>
                                                    {errors.last_name && touched.last_name && errors.last_name}
                                                </p>
                                            </div>

                                            <div>
                                                <ReviewInput
                                                    label="email"
                                                    type="email"
                                                    name="email_address"
                                                    value={values.email_address}
                                                    onChange={handleChange}
                                                    placeholder="name@company.com"
                                                />

                                                <p className='text-xs text-red-700'>
                                                    {errors.email_address && touched.email_address && errors.email_address}
                                                </p>
                                            </div>

                                            <div>
                                                <div className="w-full">
                                                    <label
                                                        // htmlFor="datepicker"
                                                        className="block text-sm font-medium leading-6 text-gray-900">
                                                        Travel date
                                                    </label>

                                                    <div className="relative mt-2 border w-full rounded-lg text-[#d1d5db] bg-[#F9FAFB]">
                                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                            <span className="text-gray-500 text-xl">
                                                                <FaCalendarAlt />
                                                            </span>
                                                        </div>

                                                        <DatePicker
                                                            selected={values.travel_date}
                                                            dateFormat="yyyy/MM/dd"
                                                            maxDate={new Date()}
                                                            className={`block w-full py-3 px-4 appearance-none bg-transparent border-0 border-transparent rounded-lg text-gray-900 ring-0 ring-inset ring-transparent placeholder:text-gray-500 placeholder:font-['Inter'] focus:ring-0 focus:ring-inset focus:ring-transparent sm:text-sm sm:leading-6 outline-none caret-gray-300`}
                                                            value={values.travel_date}
                                                            onChange={(date) => setFieldValue('travel_date', date)}
                                                        />
                                                    </div>

                                                    <p className='text-xs text-red-700'>
                                                        {errors.travel_date && touched.travel_date && errors.travel_date}
                                                    </p>
                                                </div>
                                            </div>

                                            <div>
                                                <label
                                                    htmlFor="from_city_id"
                                                    className='block text-sm font-medium leading-6 text-gray-900'
                                                >
                                                    Travel from
                                                </label>

                                                <select
                                                    className={`block w-full mt-2 py-3 px-4 border-0 rounded-lg text-gray-500 bg-[#F9FAFB] ring-1 
                                                    ring-inset ring-gray-300 placeholder:text-gray-500 placeholder:font-['Inter'] focus:ring-1 focus:ring-inset
                                                     focus:ring-gray-300 sm:text-sm sm:leading-6 outline-none caret-gray-300`}
                                                    name="from_city_id"
                                                    onChange={handleChange}
                                                >
                                                    <option value="">
                                                        Enter departure
                                                    </option>
                                                    {
                                                        fromCity.map((city: any) =>
                                                            <option
                                                                key={city.id}
                                                                value={city.id}
                                                            >
                                                                {city.name}
                                                            </option>
                                                        )
                                                    }
                                                </select>

                                                <p className='text-xs text-red-700'>
                                                    {errors.from_city_id && touched.from_city_id && errors.from_city_id}
                                                </p>
                                            </div>

                                            <div>
                                                <label
                                                    htmlFor="to_city_id"
                                                    className='block text-sm font-medium leading-6 text-gray-900'
                                                >
                                                    Travel to
                                                </label>

                                                <select
                                                    className={`block w-full mt-2 py-3 px-4 border-0 rounded-lg text-gray-500 bg-[#F9FAFB] ring-1 
                                                    ring-inset ring-gray-300 placeholder:text-gray-500 placeholder:font-['Inter'] focus:ring-1 focus:ring-inset
                                                     focus:ring-gray-300 sm:text-sm sm:leading-6 outline-none caret-gray-300`}
                                                    name="to_city_id"
                                                    onChange={handleChange}
                                                >
                                                    <option value="">
                                                        Enter destination name
                                                    </option>
                                                    {
                                                        toCity.map((city: any) =>
                                                            <option
                                                                key={city.id}
                                                                value={city.id}
                                                            >
                                                                {city.name}
                                                            </option>
                                                        )
                                                    }
                                                </select>

                                                <p className='text-xs text-red-700'>
                                                    {errors.to_city_id && touched.to_city_id && errors.to_city_id}
                                                </p>
                                            </div>


                                            <div className="col-span-3">
                                                <label
                                                    // htmlFor="rating"
                                                    className='block text-sm font-medium leading-6 text-gray-900'
                                                >
                                                    How would you rate your experience<span className='text-orange-400'>*</span>
                                                </label>
                                                <Rating
                                                    name="rating"
                                                    value={values.rating}
                                                    onChange={handleChange}
                                                />

                                                <p className='text-xs text-red-700'>
                                                    {errors.rating && touched.rating && errors.rating}
                                                </p>
                                            </div>

                                            <div className='w-full col-span-3'>
                                                <label
                                                    htmlFor="experience"
                                                    className='block text-sm font-medium leading-6 text-gray-900'
                                                >
                                                    Tell us more about your experience
                                                </label>

                                                <textarea
                                                    className={`block w-full py-3 px-4 border-0 rounded-lg text-gray-500 bg-[#F9FAFB] ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 placeholder:text-sm placeholder:font-['Inter'] focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6 outline-none caret-gray-300 `}
                                                    cols={4}
                                                    rows={4}
                                                    name="description"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                    placeholder="Leave detailed feedback. Remember to be honest as it will help other users make informed decisions."
                                                >
                                                </textarea>

                                                <p className='text-xs text-red-700'>
                                                    {errors.description && touched.description && errors.description}
                                                </p>
                                            </div>

                                            <div className='relative col-span-3 w-full'>
                                                <div onClick={openDropzone} className="w-fit cursor-pointer flex items-center space-x-1.5 font-medium">
                                                    <img src={greenPlus} alt="green plus icon" />

                                                    <div className='text-black'>
                                                        <p >Attach photo <span className='text-gray-500'>{"(optional)"}</span></p>
                                                    </div>
                                                </div>

                                                <p className='text-gray-900 col-span-3 text-sm font-medium'>
                                                    Max of 5 images
                                                </p>

                                                {previewUrl.length > 0 && (
                                                    <div className='mt-2 mb-2 flex items-center space-x-4'>
                                                        {
                                                            previewUrl.map((item: any) =>
                                                                <motion.div
                                                                    // layoutId={item}
                                                                    key={item.name}
                                                                    onClick={() => {
                                                                        setSelectedId(item);
                                                                        setOpenModal(true);
                                                                    }}
                                                                >
                                                                    <img src={item} className='object-fit border border-gray-400  w-[50px] h-[50px] md:w-[100px] md:h-[100px]' alt={item.name} />
                                                                </motion.div>
                                                            )
                                                        }
                                                    </div>
                                                )}

                                                <AnimatePresence>
                                                    <Modal className='bg-opacity-70' show={openModal} onClose={() => setOpenModal(false)}>
                                                        <Modal.Header></Modal.Header>
                                                        <Modal.Body>
                                                            <img
                                                                src={selectedId}
                                                                className='object-fit border'
                                                                alt="selected image"
                                                            />
                                                        </Modal.Body>
                                                    </Modal>
                                                </AnimatePresence>
                                                <div />
                                                {
                                                    open ?

                                                        <div>
                                                            {/* <div className="col-span-3 w-full">
                                                                <div className="flex w-full items-center justify-center">
                                                                    <Label
                                                                        htmlFor="dropzone-file"
                                                                        // htmlFor="multiple-file-upload" 
                                                                        // value="Upload multiple files"
                                                                        className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                                                    >
                                                                        <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                                                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M18 12.2952H13V12.808C12.9973 13.3496 12.8536 13.8807 12.584 14.3464H18V18.4488H2V14.3464H7.416C7.14635 13.8807 7.00275 13.3496 7 12.808V12.2952H2C1.46957 12.2952 0.960859 12.5113 0.585786 12.896C0.210714 13.2807 0 13.8024 0 14.3464V18.4488C0 18.9928 0.210714 19.5145 0.585786 19.8992C0.960859 20.2839 1.46957 20.5 2 20.5H18C18.5304 20.5 19.0391 20.2839 19.4142 19.8992C19.7893 19.5145 20 18.9928 20 18.4488V14.3464C20 13.8024 19.7893 13.2807 19.4142 12.896C19.0391 12.5113 18.5304 12.2952 18 12.2952Z" fill="#9CA3AF" />
                                                                                <path d="M6.707 6.3539L9 4.0022V12.808C9 13.08 9.10536 13.3409 9.29289 13.5332C9.48043 13.7255 9.73478 13.8336 10 13.8336C10.2652 13.8336 10.5196 13.7255 10.7071 13.5332C10.8946 13.3409 11 13.08 11 12.808V4.0022L13.293 6.3539C13.4816 6.54073 13.7342 6.6441 13.9964 6.64176C14.2586 6.63943 14.5094 6.53157 14.6948 6.34141C14.8802 6.15126 14.9854 5.89402 14.9877 5.62511C14.99 5.35621 14.8892 5.09714 14.707 4.90371L10.707 0.801308C10.6141 0.705798 10.5038 0.630021 10.3823 0.578317C10.2608 0.526614 10.1305 0.5 9.999 0.5C9.86747 0.5 9.73722 0.526614 9.61573 0.578317C9.49424 0.630021 9.38389 0.705798 9.291 0.801308L5.291 4.90371C5.10349 5.09629 4.99826 5.35737 4.99844 5.62953C4.99863 5.90169 5.10423 6.16262 5.292 6.35493C5.47977 6.54724 5.73434 6.65517 5.99971 6.65498C6.26507 6.65478 6.51949 6.54648 6.707 6.3539Z" fill="#9CA3AF" />
                                                                                <path d="M15 17.4232C15.5523 17.4232 16 16.964 16 16.3976C16 15.8312 15.5523 15.372 15 15.372C14.4477 15.372 14 15.8312 14 16.3976C14 16.964 14.4477 17.4232 15 17.4232Z" fill="#9CA3AF" />
                                                                            </svg>

                                                                            <p className="my-2 text-sm text-gray-500 dark:text-gray-400">
                                                                                Click to upload or drag and drop
                                                                            </p>
                                                                            <p className="text-xs text-gray-500 font-semibold">
                                                                                Max. File Size: 5MB
                                                                            </p>

                                                                            <p className="mt-4 py-2 px-3 text-xs bg-[#1A56DB] text-white rounded-lg font-medium flex items-center space-x-2">
                                                                                <FaSearch className='white' />

                                                                                <span className="block">
                                                                                    Browse File
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                        <FileInput
                                                                            id="dropzone-file" name="dropzone-file" onChange={handleFileChange} className="hidden"
                                                                            multiple
                                                                        />
                                                                    </Label>
                                                                </div>
                                                            </div> */}

                                                            <Dropzone
                                                                onChange={updateFiles}
                                                                value={files}
                                                                accept="image/*"
                                                                label={"Click to upload or drag and drop"}
                                                                // maxFileSize={5 * 1024}
                                                                maxFiles={5}
                                                                footer={false}
                                                            >
                                                                {files.map((file: ExtFile) => (
                                                                    <FileMosaic key={file.id} {...file} onDelete={removeFile} info={true} />
                                                                ))}
                                                            </Dropzone>

                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>


                                            <BaseButton
                                                type="submit"
                                                className='col-span-3 w-full'
                                                title="Submit your review"
                                                // disabled={true}
                                                disabled={!isValid || !dirty}
                                            />
                                        </div>
                                    </Form>
                                )
                            }
                        </Formik>

                    </div>
                </section>
            </T40Container>
        </main>
    )
}

export default CreateReview