import React from 'react';

import Input from './widgets/ui/forms/Input';
import Select from './widgets/ui/forms/Select';

interface PassengerFormProps {
    passenger: Passengers;
    passengerTypes: OptionProps[];
    genderOptions: OptionProps[];
    index: number;
    onChange: (ind: number, data: Passengers) => any;
    errors: any;
    clearApiIssues: (index: number, field: string) => void
}
export default function PassengerForm({
    passenger,
    passengerTypes,
    genderOptions,
    index,
    onChange,
    errors,
    clearApiIssues
}: PassengerFormProps) {
    const handleChange = React.useCallback(
        (field: string, value: string) => {
            clearApiIssues(index, field)
            onChange(index, {
                ...passenger,
                [field]: value,
            });
        },
        [passenger, index, onChange, clearApiIssues],
    );

    const requiredTag = <span className='inline-block text-red-500 px-1'>*</span>
    return (
        <div className='grid gap-5 mb-5'>
            <div className='bg-[#E4E7ED] rounded-lg px-3 md:px-8 py-7 pt-2 md:pb-10 border border-[#B0BACA]'>
                <h1 className='text-lg font-semibold'>Passenger {index ? index + 1 : ''} Details</h1>
                <div className='bg-white rounded-lg mt-2 p-5 grid grid-cols-2 gap-5  border border-[#B0BACA]'>
                    <div className='relative'>
                        <label className='relative block w-fit pb-1'>First name{requiredTag}</label>
                        <Input id={`passengers.${index}.first_name`} onChange={e => handleChange('first_name', e.target.value)} value={passenger.first_name} error={errors && [errors.first_name]} placeholder='First name' />
                    </div>

                    <div className="relative">
                        <label className="relative block w-fit pb-1">
                            Last name{requiredTag}
                        </label>
                        <Input
                            id={`passengers.${index}.last_name`}
                            onChange={e => handleChange('last_name', e.target.value)}
                            value={passenger.last_name}
                            error={errors && [errors.last_name]}
                            placeholder="Last name"
                        />
                    </div>

                    <div className="relative">
                        <label className="relative block w-fit pb-1">
                            Passenger type{requiredTag}
                        </label>
                        <Select
                            onChange={e => handleChange('passenger_type_id', e.value)}
                            value={
                                passengerTypes.filter(
                                    p => p.value === passenger.passenger_type_id,
                                )[0]
                            }
                            options={passengerTypes}
                        />
                    </div>
                    <div className="relative">
                        <label className="relative block w-fit pb-1">
                            Gender{requiredTag}
                        </label>
                        <Select
                            onChange={e => handleChange('gender', e.value)}
                            value={genderOptions.filter(g => g.value === passenger.gender)[0]}
                            options={genderOptions}
                        />
                    </div>
                    <div className="col-span-full">
                        <label className="relative block w-fit pb-1">
                            Email{requiredTag}
                        </label>
                        <Input
                            id={`passengers.${index}.email`}
                            onChange={e => handleChange('email', e.target.value)}
                            value={passenger.email}
                            placeholder="Email"
                            error={errors && [errors.email]}
                        />
                    </div>
                    <div className="col-span-full">
                        <label className="relative block w-fit pb-1">
                            Phone number{requiredTag}
                        </label>
                        <Input
                            id={`passengers.${index}.phone_number`}
                            onChange={e => handleChange('phone_number', e.target.value)}
                            value={passenger.phone_number}
                            placeholder="Phone number"
                            error={errors && [errors.phone_number]}
                        />
                    </div>
                </div>
            </div>
            <div className="bg-[#E4E7ED] rounded-lg px-3 md:px-8 pt-2 pb-10  border border-[#B0BACA]">
                <h1 className="text-lg font-semibold">Next of kin</h1>
                <div className="bg-white rounded-lg mt-2 p-5 grid grid-cols-2 gap-5  border border-[#B0BACA]">
                    <div className="relative">
                        <label className="relative block w-fit pb-1">
                            First name{requiredTag}
                        </label>
                        <Input
                            id={`passengers.${index}.next_of_kin_first_name`}
                            onChange={e =>
                                handleChange('next_of_kin_first_name', e.target.value)
                            }
                            value={passenger.next_of_kin_first_name}
                            placeholder="First name"
                            error={errors && [errors.next_of_kin_first_name]}
                        />
                    </div>
                    <div className="relative">
                        <label className="relative block w-fit pb-1">
                            Last name{requiredTag}
                        </label>
                        <Input
                            id={`passengers.${index}.next_of_kin_last_name`}
                            onChange={e =>
                                handleChange('next_of_kin_last_name', e.target.value)
                            }
                            value={passenger.next_of_kin_last_name}
                            placeholder="Last name"
                            error={errors && [errors.next_of_kin_last_name]}
                        />
                    </div>
                    <div className="col-span-full">
                        <label className="relative block w-fit pb-1">
                            Phone number{requiredTag}
                        </label>
                        <Input
                            id={`passengers.${index}.next_of_kin_phone_number`}
                            onChange={e =>
                                handleChange('next_of_kin_phone_number', e.target.value)
                            }
                            value={passenger.next_of_kin_phone_number}
                            placeholder="Phone number"
                            error={errors && [errors.next_of_kin_phone_number]}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
