import React from "react";
// import { generateBookingErrorObjList } from "../utils/errors";
import Validator from '../utils/validation'
import { usePassengerType } from "../context/Context";
import GoBack from "../components/widgets/GoBack";
import PassengerForm from "../components/PassengerForm";
import { Location } from "iconsax-react";
import { useNavigate } from "react-router";
import ApiFetcher from "../utils/api";
import Input from "../components/widgets/ui/forms/Input";
import KeyMap from "../utils/keyMap";
import PaymentModal from "../components/modals/PaymentModal";

export default function BookRide() {
    const navigate = useNavigate();
    const passengerTypes = usePassengerType();
    const [rideData, setRideData] = React.useState<RideStorage | null>(null);
    const [passengersDetails, setPassengerDetails] = React.useState<Passengers[]>([]);
    const [referalCode, setReferralCode] = React.useState({
        code: "",
        discount: 0
    })
    const [referalLoading, setReferalLoading] = React.useState(false)
    const [referalCodeError, setReferralCodeError] = React.useState("")

    const [showPaymentModal, setShowPaymentModal] = React.useState(false)

    const [apiIssues, setApiIssues] = React.useState<any[]>([])

    const clearApiIssues = React.useCallback((index: number, field: string) => {
        if (apiIssues[index]?.[field]) {
            // make a deepcopy to avoid referencing issues
            const cloneApiIssues = apiIssues.map(_ => ({ ..._ }));

            cloneApiIssues[index] = {
                ...cloneApiIssues[index],
                [field]: ""
            }

            setApiIssues(cloneApiIssues);
        }
    }, [apiIssues])

    React.useEffect(() => {
        const storageRide = JSON.parse(sessionStorage.getItem('current-ride-data') as string) as RideStorage || null;
        // there must be a ride in storage for this page to show at all
        if (!storageRide) {
            navigate('/')
        }
        else {
            // const issuesObj = issues && JSON.parse(window.atob(issues as string))
            // if (issuesObj) {
            //     try {
            //         // go to the first element with issue
            //         document.getElementById(Object.keys(issuesObj)[0])?.scrollIntoView({
            //             behavior: 'smooth'
            //         })
            //     } catch { }
            //     setApiIssues(generateBookingErrorObjList(issuesObj));
            // }

            setRideData(storageRide);
            if (storageRide.passengers) {
                // Always updating the send data incase of change
                setPassengerDetails(storageRide.passengers.map(
                    (passenger, index) => ({
                        ...passenger,
                        ride_seat_id : storageRide.seat ? storageRide.seat[index] : passenger.ride_seat_id
                    })
                ))
            } else {
                storageRide.seat && setPassengerDetails(storageRide.seat.map(seat => ({
                    ride_seat_id: seat,
                    first_name: "",
                    last_name: "",
                    email: "",
                    phone_number: "",
                    next_of_kin_first_name: "",
                    next_of_kin_last_name: "",
                    next_of_kin_phone_number: "",
                    passenger_type_id: "", // default to adults
                    gender: ""
                })) satisfies Passengers[])
            }
        }

    }, [navigate, passengerTypes])

    const formErrors = React.useMemo(() => {

        const errors: any[] = [];
        passengersDetails.forEach(
            (passenger, index) => {
                const passError = {} as any;
                Object.entries(passenger).forEach(
                    ([field, value]: [string, string]) => {
                        if (apiIssues[index]?.[field]) {
                            passError[field] = apiIssues[index][field];
                            setApiIssues([...apiIssues])
                            return;
                        }

                        if (!value || ['gender', 'passenger_type_id', 'ride_seat_id'].includes(field))
                            return;

                        if (['phone_number', 'next_of_kin_phone_number'].includes(field)) {
                            // ie. handle with phone number validation
                            passError[field] = Validator['phone_number'](value);
                            return
                        }

                        if (field === 'email') {
                            // handle for emails
                            passError[field] = Validator['email'](value)
                            return;
                        }

                        passError[field] = Validator['first_name'](value)

                    }
                )
                errors.push(passError);
            }
        )

        return errors;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [passengersDetails])

    const isFormValidated = React.useMemo<boolean>(() => {

        return ((function (passengers: any[]) {
            // ie. one of the important fields is empty
            // or one of the fields has an error
            let ind = 0;
            for (const pass of passengers) {
                for (const field in pass) {
                    if (!pass[field] || formErrors?.[ind]?.[field])
                        return false;
                }
                ind++;
            }
            return true
        })(passengersDetails))
    }, [formErrors, passengersDetails])



    const handlePassengerChange = React.useCallback((ind: number, data: Passengers) => {
        passengersDetails[ind] = data;
        setPassengerDetails([...passengersDetails])
    }, [passengersDetails])

    const passengerTypeOptions = React.useMemo(() => {
        return passengerTypes.map((passType) => ({
            label: passType.name,
            value: passType.id
        }))
    }, [passengerTypes])

    const genderOptions = React.useMemo(() => [
        {
            label: 'Male',
            value: 'male'
        },
        {
            label: 'Female',
            value: 'female'
        }
    ], [])

    const totalAmount = React.useMemo(() => {
        if (!rideData)
            return 0;
        return rideData.ride.ride_meta.ride_destination.amount_with_discount -
            (referalCode.discount * rideData.ride.ride_meta.ride_destination.amount)
    }, [rideData, referalCode])

    const handleSubmit = React.useCallback(() => {
        sessionStorage.setItem(KeyMap.CURRENT_RIDE_DATA, JSON.stringify({
            ...rideData,
            passengers: passengersDetails,
            referral_code: referalCode.discount ? referalCode.code : undefined
        } as RideStorage))
        setShowPaymentModal(true)
    }, [passengersDetails, rideData, referalCode, setShowPaymentModal])

    const handleVerifyReferralCode = React.useCallback(async () => {
        try {
            setReferralCodeError("");
            setReferalLoading(true);
            const response = await ApiFetcher.get(`referrals/${referalCode.code.toLowerCase()}`);
            setReferralCode({
                ...referalCode,
                discount: response.data.data.referral_discount_percentage
            })
        } catch (e: any) {
            if (e?.response?.status === 404) {
                setReferralCodeError(e?.response?.data?.message || "Code not found");
            }
            else {
                setReferralCodeError("Something went wrong");
            }
        } finally {
            setReferalLoading(false);
        }
    }, [referalCode])

    return (
        <>
            <div className='text-[#102751] t40-container py-10'>
                <GoBack />
                <h1 className='text-2xl font-semibold mt-5 mb-5'>Passenger Details</h1>

                <div className='grid lg:grid-cols-2 gap-4'>
                    <div className='grid gap-4 h-fit'>
                        <div className='gap-5 grid lg:hidden'>

                            <h1 className=' font-semibold'>{rideData?.ride.ride_meta.departure_location.city.name} - {rideData?.ride.ride_meta.ride_destination.location.city.name}</h1>
                            {/* <div className=''>
                                <h1 className='text-lg font-semibold'>Pick Up station </h1>
                                <h1 className='flex items-center mt-1'><Location className='mr-2' size={20} variant='Bold' color='#627496' /> {rideData?.ride.ride_meta.departure_location.name}, {rideData?.ride.ride_meta.departure_location.address}</h1>
                            </div>
                            <div className=''>
                                <h1 className='text-lg font-semibold'>Destination Station</h1>
                                <h1 className='flex items-center mt-1'><Location className='mr-2' size={20} variant='Bold' color='#627496' /> {rideData?.ride.ride_meta.ride_destinations[0].location.name}, {rideData?.ride.ride_meta.ride_destinations[0].location.address}</h1>
                            </div> */}
                        </div>

                        {
                            passengersDetails.map(
                                (passenger, ind) => <PassengerForm
                                    passengerTypes={passengerTypeOptions}
                                    genderOptions={genderOptions}
                                    passenger={passenger}
                                    key={'passenger' + ind}
                                    index={ind}
                                    onChange={handlePassengerChange}
                                    errors={formErrors[ind]}
                                    clearApiIssues={clearApiIssues}
                                />
                            )
                        }
                    </div>
                    <div className='grid gap-10 h-fit xl:pl-20'>
                        <div className='gap-5 hidden lg:grid'>

                            <h1 className='text-lg font-semibold'>{rideData?.ride.ride_meta.departure_location.city.name} - {rideData?.ride.ride_meta.ride_destination.location.city.name}</h1>
                            <div className=''>
                                <h1 className='text-lg font-semibold'>Pick Up Station</h1>
                                <h1 className='flex items-center mt-1'><Location className='mr-2' size={20} variant='Bold' color='#627496' /> {rideData?.ride.ride_meta.departure_location.name}, {rideData?.ride.ride_meta.departure_location.address}</h1>
                            </div>
                            <div className=''>
                                <h1 className='text-lg font-semibold'>Destination Station</h1>
                                <h1 className='flex items-center mt-1'><Location className='mr-2' size={20} variant='Bold' color='#627496' /> {rideData?.ride.ride_meta.ride_destinations[0].location.name}, {rideData?.ride.ride_meta.ride_destinations[0].location.address}</h1>
                            </div>
                        </div>

                        <div className='px-5 py-3 rounded-lg grid gap-4  border border-[#B0BACA]' style={{ background: 'linear-gradient(180deg, #FFFFFF 0%, #E4E7ED 100%)' }}>
                            <h1 className='text-lg font-semibold'>Payment Summary</h1>

                            <div className='rounded-lg bg-white px-3 py-2 flex justify-between items-center'>
                                <h1 className='text-sm'>Base Fare:</h1>
                                <h1 className='text-lg font-semibold'>&#8358;{rideData?.ride.ride_meta.ride_destination.amount.toLocaleString()}</h1>
                            </div>
                            <div className='rounded-lg bg-white px-3 py-2 flex justify-between items-center'>
                                <h1 className='text-sm'>Date Discount:</h1>
                                <h1 className='text-lg font-semibold text-orange-600'>-&#8358;{rideData?.ride.ride_meta.ride_destination.discount.date_discount_amount}</h1>
                            </div>
                            <div className='rounded-lg bg-white px-3 py-2 flex justify-between items-center'>
                                <h1 className='text-sm'>Ride Discount:</h1>
                                <h1 className='text-lg font-semibold text-orange-600'>-&#8358;{rideData?.ride.ride_meta.ride_destination.discount.ride_discount_amount}</h1>
                            </div>
                            {
                                referalCode.discount ? <div className='rounded-lg bg-white px-3 py-2 flex justify-between items-center'>
                                    <h1 className='text-sm'>Coupon Discount:</h1>
                                    <h1 className='text-lg font-semibold text-orange-600'>-&#8358;{(referalCode.discount * (rideData?.ride.ride_meta.ride_destination.amount as number)).toLocaleString()}</h1>
                                </div> : <></>
                            }
                            <div className='rounded-lg bg-white px-3 py-2 flex justify-between items-center'>
                                <h1 className='text-sm'>Total:</h1>
                                <h1 className='text-xl font-semibold'>&#8358;{totalAmount.toLocaleString()}</h1>
                            </div>
                        </div>
                        <div className='px-5 py-3 pt-2 rounded-lg grid gap-4  border border-[#B0BACA]' style={{ background: 'linear-gradient(180deg, #FFFFFF 0%, #E4E7ED 100%)' }}>
                            <h1 className='text-lg font-semibold'>Referral / coupon code</h1>

                            <Input error={referalCodeError ? [referalCodeError] : undefined} placeholder='Discount code' onChange={e => setReferralCode({
                                ...referalCode,
                                code: e.target.value.toUpperCase()
                            })} value={referalCode.code} haveWhiteBackground onBtnClick={handleVerifyReferralCode} isLoading={referalLoading} />
                        </div>

                        <button className='btn bg-t40Blue-500 rounded-lg w-full py-4 transition-all disabled:opacity-70 text-white ' onClick={handleSubmit}
                            disabled={!isFormValidated || referalLoading}
                        >Book Ticket</button>
                    </div>
                </div>

            </div>

            <PaymentModal show={showPaymentModal} toggle={() => setShowPaymentModal(false)}/>
        </>
    )
}