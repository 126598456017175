/* eslint-disable jsx-a11y/anchor-is-valid */


export default function WhatsApp() {

    return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
       <div className="w-fit group">
        
         <a 
            // href="#"             
            href="https://api.whatsapp.com/send?phone=2349074395656"
            className="group p-3 flex items-center space-x-4 cursor-pointer bg-white rounded-full text-3xl fixed bottom-5 z-50 right-5"
        >
            
            <img src="/whatsapp.png" alt="whatsapp" className="w-[45px]" />
            <p className="text-base group-hover:block hidden font-bold text-green-600">Chat with us</p>
        </a>
       </div>
    )
}
