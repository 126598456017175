
import T40Container from '../../layout/Container'
import makePayment from '../../assets/make-payment.png';
import busTicket from '../../assets/bus-ticket.png';
import searchLocation from '../../assets/search-location.png';
import searchForRides from '../../assets/search-for-rides.png';
import { brandName } from '../../api/ApiConfig';
import { useNavigate } from 'react-router-dom';

type HowtoBuyTicketsProps = {
    id: number,
    title: string,
    details: string,
    image: string,
    alt: string,
    idColor: string
}

const HowTo = () => {

    const navigate = useNavigate();

    const getBusTickets: HowtoBuyTicketsProps[] = [
        {
            id: 1,
            title: "Search for rides",
            details: `To purchase your bus tickets on ${brandName} 
            , start by searching for rides either by location 
            or destination.`,
            image: searchForRides,
            alt: "woman with her hat looking at her phone",
            idColor: "text-orange-500 bg-orange-50"
        },
        {
            id: 2,
            title: "Select location",
            details: `Select your location (e.g Lagos, Abuja), 
            destination (Aba), add departure time, and if you 
            would be returning by road, specify your return date.`,
            image: searchLocation,
            alt: "direction location map",
            idColor: "bg-indigo-50 text-indigo-600"
        },
        {
            id: 3,
            title: "Make payment",
            details: `Input passenger information including your 
            name, mobile number, and email address, then proceed to 
            make payment.`,
            image: makePayment,
            alt: "making payment",
            idColor: "bg-yellow-100 text-yellow-400"
        },
        {
            id: 4,
            title: "Get your bus ticket instantly",
            details: `After payment, your bus ticket will be 
            emailed or sent via SMS. Have it printed or show 
            the SMS to our Bus Operator when boarding.`,
            image: busTicket,
            alt: "get your bus ticket",
            idColor: "bg-indigo-50 text-blue-900"
        }
    ]


    return (
        <section className='mb-10'>
            <T40Container>
                <div className='text-center lg:text-left w-full lg:w-[405px]'>
                    <h2 className=' text-gray-900 text-[20px] lg:text-[36px] font-bold'>
                        Buying your {brandName} Bus Tickets Online
                    </h2>
                    <p className=' mt-[17px] leading-[24px] text-gray-500 text-xs lg:text-base font-medium'>
                        Purchasing bus tickets online with {brandName} is  super easy on our website.
                    </p>
                </div>

                <div className='md:grid grid-cols-4 space-y-6 md:space-y-0 gap-x-8 mt-8'>
                    {
                        getBusTickets?.map((items: HowtoBuyTicketsProps) => (
                            <div className='h-full text-center md:text-left flex flex-col justify-between' key={items.id}>
                                <div>
                                    <span className={`md:w-fit text-center md:text-left font-bold text-2xl p-[9px] rounded-[100px] ${items.idColor}`}>
                                        {items.id}
                                    </span>

                                    <h3 className='pt-4 text-blue-900 font-semibold text-xl'>
                                        {items.title}
                                    </h3>

                                    <p className='text-gray-500 leading-[24px]'>
                                        {items.details}
                                    </p>
                                </div>

                                <div className='pt-6 flex justify-center items-center md:block'>
                                    <img src={items.image} alt={items.alt} />
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className='mt-16 flex justify-center items-center'>
                    <button onClick={() => window.scrollTo(0, 0)} className='rounded-lg font-medium py-[10px] px-[20px] hover:bg-opacity-90 bg-primary-700 text-white'>
                        Buy Tickets now
                    </button>
                </div>
            </T40Container>
        </section>
    )
}

export default HowTo