import React from 'react'
import { Printer } from "iconsax-react"
import { useScreenshot, createFileName } from "use-react-screenshot";

interface BookingTicketProps {
    data: BookingDetailsType,
    index: number,
    departure: BookingType['departure_details'],
    destination: BookingType['destination_details']
}

export default function BookingTicket(
    { data, index, departure, destination }: BookingTicketProps

) {

    const downloadRef = React.createRef<HTMLDivElement>();

    const [,takeScreenShot] = useScreenshot({
      type: "image/jpeg",
      quality: 1.0
    });
  
    const download = (image : any, { name = "receipt", extension = "jpg" } = {}) => {
      const a = document.createElement("a");
      a.href = image;
      a.download = createFileName(extension, name);
      a.click();
    };

    const downloadScreenshot = () => takeScreenShot(downloadRef.current).then(download);
  
    return <div className='border mb-10 border-[#B0BACA] rounded-xl lg:pr-5 pr-5 p-5 md:p-10  lg:p-20 max-w-4xl' ref={downloadRef}>
        <h1 className='font-semibold mb-10 text-2xl text-t40orange'>{process.env.REACT_APP_BRAND_NAME}</h1>
        <h1 className='font-bold text-xl'>Booking Details</h1>

        <table className='table-fixed mt-5 w-full'>
            <tbody>
                <tr>
                    <td >Booking ID</td>
                    <td className='pl-10 lg:pl-20 pt-2'>{data.booking_code}</td>
                </tr>
                <tr>
                    <td >Take Off</td>
                    <td className='pl-10 lg:pl-20 pt-2'>{departure.ride_meta.departure_location.name}</td>
                </tr>
                <tr>
                    <td >Destination</td>
                    <td className='pl-10 lg:pl-20 pt-2'>{destination.location.name}</td>
                </tr>
                {/* <tr>
                    <td >Departure Time</td>
                    <td className='pl-10 lg:pl-20 pt-2'>{departure.}</td>
                </tr> */}
            </tbody>
        </table>

        <h1 className='font-bold text-xl mt-10'>Passenger Details</h1>

        <table className='table-fixed mt-5 w-full'>
            <tbody>
                <tr>
                    <td >First Name</td>
                    <td className='pl-10 lg:pl-20 pt-2'>{data.passenger_details.first_name}</td>
                </tr>
                <tr>
                    <td >Last Name</td>
                    <td className='pl-10 lg:pl-20 pt-2'>{data.passenger_details.last_name}</td>
                </tr>
                <tr>
                    <td >Phone Number</td>
                    <td className='pl-10 lg:pl-20 pt-2'>{data.passenger_details.phone_number}</td>
                </tr>
                <tr>
                    <td >Seat number</td>
                    <td className='pl-10 lg:pl-20 pt-2'>{data.passenger_details.seat.seat_no}</td>
                </tr>
            </tbody>
        </table>

        <h1 className='font-bold text-xl mt-10'>Payment Details</h1>

        <table className='table-fixed mt-5 w-full'>
            <tbody>
                <tr>
                    <td >Base fare</td>
                    <td className='pl-10 lg:pl-20 pt-2 font-semibold'>&#8358;{data.booking_payment_details.flat_amount.toLocaleString()}</td>
                </tr>
                <tr>
                    <td >Date Discount</td>
                    <td className='pl-10 lg:pl-20 pt-2'>&#8358;{data.booking_payment_details.date_discount_amount.toLocaleString()}</td>
                </tr>
                <tr>
                    <td >Fare Discount</td>
                    <td className='pl-10 lg:pl-20 pt-2'>&#8358;{data.booking_payment_details.business_discount_amount?.toLocaleString() || 0}</td>
                    {/* {rideData?.ride.ride_meta.ride_destination.discount.business_discount_amount} */}
                </tr>
                {
                    data.booking_payment_details.referral_discount_amount ? <tr>
                        <td >Referral Discount</td>
                        <td className='pl-10 lg:pl-20 pt-2'>&#8358;{data.booking_payment_details.referral_discount_amount.toLocaleString()}</td>
                        {/* {rideData?.ride.ride_meta.ride_destination.discount.business_discount_amount} */}
                    </tr> : <></>
                }
                <tr className='font-bold text-lg'>
                    <td >Total</td>
                    <td className='pl-10 lg:pl-20 pt-2'>&#8358;{data.booking_payment_details.paid_amount.toLocaleString()}</td>
                </tr>
            </tbody>
        </table>

        <div className="flex justify-end">
            <button className="flex flex-col items-center" onClick={downloadScreenshot}>
                <Printer className="text-t40orange w-16 h-16" variant="Bold" />
                <p className="italic">Download Receipt </p>
            </button>
        </div>
    </div>
}