import T40Container from "../../layout/Container";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import AvailableLocation from "../locations/AvailableLocation";
import locationsData from '../locations/locations.json';
import { useCallback, useRef, useState, useEffect } from "react";
import { Headings } from "../../utils/constants/Headings";
import baseURL, { brandID, brandName } from "../../api/ApiConfig";
import axios from 'axios';

const CustomBranches = () => {

    const [getLocations, setGetLocations] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(false)
	const swiperRef: any = useRef(null);
	

	const getLocation = async () => {
		try {
			setLoading(true);
			await axios.get(`${baseURL}/v1/intercity/generals/locations?company_id=${brandID}`)
			// await axios.get(`${baseURL}/v1/intercity/generals/locations`)
				.then((res: any) => {
					setGetLocations(res.data.data);
				})                
		} catch (error: any) {

		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		getLocation();
	}, [])
    return (
        <div className="mb-20">
            <>
                <div className="space-y-10 lg:space-y-16">
                    <div className="flex flex-col lg:flex-row lg:justify-between">
                        <div className="md:text-left text-center ">
                            <Headings
                                level="h3"
                                title={<span> {brandName} Bus Stations </span>}
                                className="block leading-[38px] text-t40Black text-2xl lg:text-[28px] font-semibold lg:font-bold"
                            />

                            <p className="text-gray-600 pt-1 md:pt-3">
                                Our branches are available for you to explore
                            </p>
                        </div>                    
                    </div>


                    <div className='mt-20 '>                        

                        <Swiper
							ref={swiperRef}
							loop={true}
							grabCursor={true}
							autoplay={{
								delay: 4000,
								disableOnInteraction: false,
							}}
							modules={[Navigation, Autoplay]}
							slidesPerView={1}
							breakpoints={{
								500: {
									slidesPerView: 1,
									spaceBetween: 10,
								},
								640: {
									slidesPerView: 1,
									spaceBetween: 10,
								},
								768: {
									slidesPerView: 2.2,
									spaceBetween: 10,
								},
								1024: {
									slidesPerView: 3.2,
									spaceBetween: 10,
								},
							}}
						>
							{
									loading ? (
										<p>Loading . . .</p>
									) : (
										getLocations.length > 0 ? (
											getLocations.map((location: any) => (
												<SwiperSlide key={location.id}>
													<AvailableLocation {...location} />
												</SwiperSlide>												
											))
										) : (
											<p>No available location</p>
										)
									)
								}							
						</Swiper>
                    </div>
                </div>
            </>
        </div>
    )
}

export default CustomBranches