import React from 'react';
import { useNavigate } from 'react-router';

import { usePayStack, useMonnify, PaymentKeys } from '../../utils/payment';
//assets import
import { ArrowRight2, CloseCircle } from 'iconsax-react';
import Loading from 'react-loading';
import { monnify, paystack as paystackImg } from '../../assets';
import { MonnifyProps } from '../../utils/monnify/types';
import Modal from '.';
import KeyMap from '../../utils/keyMap';
import ApiFetcher from '../../utils/api';

interface PaymentModalProps {
    toggle: () => void,
    show: boolean
}

export default function PaymentModal({
    toggle, show
}: PaymentModalProps) {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("")
    const [rideData, setRideData] = React.useState<RideStorage | null>(null);
    const [paystack, setPaystack] = React.useState({
        reference: "",
        email: "",
        amount: 0,
        currency: 'NGN',
        publicKey: '',
        metadata: {
            payment_type: "for.booking",
        },
    });

    const [monnifyData, setMonnifyData] = React.useState<MonnifyProps>({
        amount: 5000,
        currency: 'NGN',
        reference: '',
        customerFullName: '',
        customerEmail: '',
        customerMobileNumber: '',
        apiKey: '',
        contractCode: process.env.REACT_APP_MONNIFY_CONTACT_CODE as string,
        isTestMode: process.env.REACT_APP_ENVIRONMENT === "dev",
        paymentDescription: 'Booking payment',
        metadata: {
            paymentType: "for.booking"
        }
    })
    const [loadingMessage, setLoadingMessage] = React.useState("Creating your Booking")


    function showErrorMessage(message: string) {
        setError(message);
        setTimeout(() => {
            setError("")
        }, 2000)
    }

    const payWithPayStack = usePayStack(paystack as any);
    const payWithMonnify = useMonnify(monnifyData)

    const paymentMethods = [
        {
            name: 'Paystack',
            icon: paystackImg
        },
        {
            name: 'Monnify',
            icon: monnify
        },
    ] as const;

    React.useEffect(() => {
        const storageRide = JSON.parse(sessionStorage.getItem(KeyMap.CURRENT_RIDE_DATA) as string) as RideStorage || null;
        // there must be a ride in storage for this page to show at all
        if (!storageRide) {
            navigate('/')
        } else
            setRideData(storageRide);
    }, [navigate, show])

    // React.useEffect(() => {
    //     /***
    //      * Handles paystack payment
    //      */
    //     if (paystack.reference) {
    //         // i.e the payment reference has been recieved from the backend
    //         payWithPayStack && payWithPayStack(
    //             () => {
    //                 navigate('/ride/complete')
    //             }, () => {
    //                 setLoading(false);
    //                 setPaystack({
    //                     ...paystack,
    //                     reference: ""
    //                 })
    //                 showErrorMessage('Payment Failed. Try again')
    //             }
    //         )
    //     }
    // }, [paystack, payWithPayStack, navigate]);

    React.useEffect(() => {
        /***
         * Handles monnify payment
         */
        if (monnifyData.reference) {
            // i.e the payment reference has been recieved from the backend
            payWithMonnify && payWithMonnify(
                (response: any) => {
                    if (response.status === "FAILED") {
                        setLoading(false);
                        setMonnifyData({
                            ...monnifyData,
                            reference: ""
                        })
                        showErrorMessage('Payment Failed. Try again')
                    }

                    else navigate('/ride/complete')
                }, () => {
                    setLoading(false);
                    setMonnifyData({
                        ...monnifyData,
                        reference: ""
                    })
                    showErrorMessage('Payment Failed. Try again')
                }
            )
        }
    }, [monnifyData, payWithMonnify, navigate])





    const handlePayment = React.useCallback(async (name: typeof paymentMethods[number]['name']) => {
        if (!rideData) // if there is not date in storage
            return

        if (name === "Paystack" && !payWithPayStack)  // Can't use paystack when it is down
            return showErrorMessage("Oops, Paystack is currenty down use another payment method");


        if (name === "Monnify" && !payWithMonnify)  // Can't use monnify when it is down
            return showErrorMessage("Opps, Monnify is currently down use another payment method");

        const dataToSend = {
            ride: {
                departure_ride_id: rideData.ride.id,
                platform: 'Intercity Web',
                destination_ride_id: rideData.ride.ride_meta.ride_destinations[0].id
            },
            passengers: rideData.passengers,
            referral_code: rideData?.referral_code,
            ...(rideData?.referred_by && { referred_by: rideData.referred_by })
        }

        try {
            setLoading(true);
            const response = await ApiFetcher.post('bookings', dataToSend);
            // setLoading(false)
            if (response.status === 201) {
                setLoadingMessage("Processing payment")
                const bookingData: BookingType = response.data.data;
                const { paystack: paystackey, monnify: monnifyKey }: PaymentKeys = response.data.extra
                sessionStorage.setItem('current-booking-data', JSON.stringify(bookingData))
                switch (name) {
                    case 'Paystack':
                        setPaystack({
                            ...paystack,
                            email: bookingData.booking_details[0].passenger_details.email,
                            amount: bookingData.total_ride_fare * 100,
                            reference: bookingData.payment_reference,
                            publicKey: paystackey
                        })
                        break;
                    case "Monnify":
                        setMonnifyData({
                            ...monnifyData,
                            customerEmail: bookingData.booking_details[0].passenger_details.email,
                            customerFullName: `${bookingData.booking_details[0].passenger_details.first_name} ${bookingData.booking_details[0].passenger_details.last_name}`,
                            customerMobileNumber: bookingData.booking_details[0].passenger_details.phone_number,
                            amount: bookingData.total_ride_fare,
                            reference: bookingData.payment_reference,
                            apiKey: monnifyKey
                        })
                }
            }
        } catch (e: any) {
            setLoading(false);
            // if (e?.response?.status == 422)
            //     router.push('/bookride?issues=' +
            //         window.btoa(JSON.stringify(e.response.data.errors)));
            if (e?.response?.status === 400) {
                showErrorMessage(e?.response?.data?.message || "The Booking was not created successfully");
                if (e?.response?.data?.message === "Seat selected not available") {
                    if (rideData.ride.seats) {
                        rideData.ride.seats = rideData.ride.seats.map(
                            seat => ({
                                ...seat,
                                is_available: (seat.is_available) && !(rideData.seat?.includes(seat.id))
                            })
                        )
                        sessionStorage.setItem(KeyMap.CURRENT_RIDE_DATA, JSON.stringify(rideData));
                    }
                    setTimeout(
                        () => {
                            showErrorMessage("Redirecting you to select a new seat")
                            setTimeout(() => {
                                navigate('/ride/details')
                            }, 2000)
                        }, 2000
                    )
                } else {
                }
            } else {
                showErrorMessage("The Booking was not created successfully");
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rideData, paystack])

    return (
        <Modal show={show}>
            <div style={{ background: 'linear-gradient(180deg, #FFFFFF 0%, #E4E7ED 100%)' }}
                className="border border-[#B0BACA] max-w-xl w-full p-5 lg:p-14 rounded-xl relative overflow-hidden">

                {/* The loading overlay */}
                {
                    loading && <div className=' absolute flex justify-center top-0 left-0 z-10 items-center flex-col bg-white/90 w-full h-full'>
                        <Loading type='spin' color='#102751' />
                        <h2 className='text-center mt-4 font-semibold'>{loadingMessage}</h2>
                    </div>
                }


                <CloseCircle variant='Bold' color='#627496' className='absolute top-4 right-4 cursor-pointer' onClick={toggle} />
                {
                    <div className='bg-white p-3 lg:p-10 rounded-xl shadow-sm '>
                        <h1 className='text-center text-xl font-semibold mb-1'>Choose Payment Method</h1>
                        <h1 className='text-center text-lg font-light'>How would you like to pay ?</h1>
                        {error && <h1 className='text-red-700 font-semibold text-center mt-2'>{error}</h1>}
                        <div className='grid gap-7 mt-7'>
                            {
                                paymentMethods.map((pay, _) => {
                                    return <button onClick={() => handlePayment(pay.name)} className='rounded-lg flex transition-all hover:scale-105 hover:shadow-md items-center shadow-sm justify-between p-3' key={"paybutt" + _}>
                                        <h1 className='flex items-center text-md font-light'><img className='mr-3' src={pay.icon} alt="" /> {pay.name}</h1>
                                        <ArrowRight2 variant="Bold" className='text-blue-11' />
                                    </button>
                                })
                            }
                        </div>
                    </div>
                }

            </div>
        </Modal>
    )
}