import React from 'react';

interface ModalProps {
    show : boolean,
    children : React.ReactNode
}
export default function Modal({
    show,
    children
}: ModalProps) {
    return show ?  (
        <div className='w-full px-5 h-screen fixed top-0 left-0 bg-[#292B2E]/20 flex justify-center items-center z-[9999]'>
            {children}
        </div>
    ) : <></>
}