const defaultPrimaryColor = "#1A56DB";
const defaultSecondaryColor = "#0A1831";
const defaultAccentColor = "#050D1B";
const defaultTextColor = "#333333";

export const getDynamicColors = () => {
  const fetchedPrimaryColor = defaultPrimaryColor; 
  const fetchedSecondaryColor = defaultSecondaryColor; 
  const fetchedAccentColor = defaultAccentColor; 
  const fetchedTextColor = defaultTextColor; 

  const dynamicPrimaryColor = fetchedPrimaryColor || defaultPrimaryColor;
  const dynamicSecondaryColor = fetchedSecondaryColor || defaultSecondaryColor;
  const dynamicAccentColor = fetchedAccentColor || defaultAccentColor;
  const dynamicTextColor = fetchedTextColor || defaultTextColor;

  return {
    primary: dynamicPrimaryColor,
    secondary: dynamicSecondaryColor,
    accent: dynamicAccentColor,
    text: dynamicTextColor,
  };
};
