import React from "react";

interface SeatSelectProps {
	row?: number;
	column?: number;
	pax: number;
	seatMap: SeatType[];
	onChange: (e: string[]) => any;
}

export default function SeatSelect({
	row,
	column,
	seatMap,
	pax,
	onChange,
}: SeatSelectProps) {
	const [selectedSeats, setSelectedSeats] = React.useState<string[]>([]);
	const [error, setError] = React.useState<string>("");

	React.useEffect(() => {
		onChange && onChange(selectedSeats);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSeats]);

	const handleClick = React.useCallback(
		(id: string) => {
			if (selectedSeats.length >= pax && !selectedSeats.includes(id)) {
				setError(
					`You can't select more than ${pax} ${pax === 1 ? "seat" : "seats"}`
				);
				return;
			}
			setError("");

			if (selectedSeats.includes(id))
				setSelectedSeats(selectedSeats.filter((seat) => seat !== id));
			else setSelectedSeats([...selectedSeats, id]);
		},
		[selectedSeats, pax]
	);

	const steeringWheel = (
		<div className="w-full aspect-square rounded-full border-4 flex justify-center items-center border-[#CA6217] bg-orange-600">
			<div className="w-1/2 rounded-full h-1/2 bg-white"></div>
		</div>
	);
	return (
		<div className="flex md:flex-row flex-col gap-5 md:gap-16 w-full">
			{/* Main seat select */}
			<div
				className={`border-2 py-8 grid ${
					column && column <= 3 ? "gap-10" : "gap-7"
				} p-5 border-[#627496] border-4 rounded-xl w-full max-w-[250px]`}
				style={{
					gridTemplateColumns: `repeat(${column}, 1fr)`,
					gridTemplateRows: `repeat(${row}, 1fr)`,
				}}>
				{steeringWheel}

				{seatMap.map((seat, _) => (
					<button
						disabled={!seat.is_available}
						className={`hover:scale-105 transition-all ${
							selectedSeats.includes(seat.id)
								? "bg-[#F6A467] border-[#CA6217]"
								: seat.is_available
								? "hover:bg-orange-100"
								: "bg-[#8997B0]"
						}  w-full aspect-square rounded-t-full border-2 border-[#102751]`}
						key={"seat" + _}
						style={{
							gridRow: seat.x,
							gridColumn: seat.y,
						}}
						onClick={() => handleClick(seat.id)}>
						{_ + 1}
					</button>
				))}
			</div>

			<div className="flex flex-col w-full justify-end gap-4">
				{error && (
					<h1 className="text-red-700 text-sm font-semibold">{error}</h1>
				)}
				<h1 className="w-full flex items-center">
					<div className="w-6 mr-3  aspect-square rounded-t-full border-2 border-[#102751]"></div>{" "}
					Available
				</h1>
				<h1 className="w-full flex items-center">
					<div className="w-6 mr-3  aspect-square rounded-t-full border-2 border-[#CA6217] bg-[#F6A467]"></div>{" "}
					Selected
				</h1>
				<h1 className="w-full flex items-center">
					<div className="w-6 mr-3  aspect-square rounded-t-full border-2 border-[#102751] bg-[#8997B0]"></div>{" "}
					Booked
				</h1>
			</div>
		</div>
	);
}
