import React, { createContext, useContext, ReactNode, useState } from "react";
import { BookingDataResult } from "../components/selectStation/bookTicket/BookingProps";

export enum TripType {
	OneWay = "One Way",
	RoundTrip = "Round Trip",
}

interface BookingContextProps {
	tripType: TripType;
	setTripType: React.Dispatch<React.SetStateAction<TripType>>;
	numberOfTravelers: number;
	setNumberOfTravelers: React.Dispatch<React.SetStateAction<number>>;
	bookAccommodation: boolean;
	setBookAccommodation: React.Dispatch<React.SetStateAction<boolean>>;
  bookingData: BookingDataResult | null;
  setBookingData: React.Dispatch<React.SetStateAction<BookingDataResult | null>>;
}

const BookingContext = createContext<BookingContextProps | undefined>(
	undefined
);

export const BookingProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [tripType, setTripType] = useState<TripType>(TripType.OneWay);
	const [numberOfTravelers, setNumberOfTravelers] = useState<number>(1);
	const [bookAccommodation, setBookAccommodation] = useState<boolean>(false);
	const [bookingData, setBookingData] =
		React.useState<BookingDataResult | null>(null);

	return (
		<BookingContext.Provider
			value={{
				tripType,
				setTripType,
				numberOfTravelers,
				setNumberOfTravelers,
				bookAccommodation,
				setBookAccommodation,
				bookingData,
				setBookingData,
			}}>
			{children}
		</BookingContext.Provider>
	);
};

export const useBookingContext = () => {
	const context = useContext(BookingContext);
	if (!context) {
		throw new Error("useBookingContext must be used within a BookingProvider");
	}
	return context;
};
