import React from "react";
import { road } from "../assets";
import Naira from "./widgets/Naira";
import { useNavigate } from "react-router";
import KeyMap from "../utils/keyMap";
import { formatDate, formatTime } from "../utils/date";

interface RideCardProps {
    ride: RideType,
    pax: number,
    departure_date: Date,
    hideButton?: boolean,
    widthFull?: boolean
}
export default function RideCard(
    { ride, pax, departure_date, hideButton = false, widthFull = false }: RideCardProps
) {
    const navigate = useNavigate();

    function handleClick() {
        sessionStorage.setItem(KeyMap.CURRENT_RIDE_DATA, JSON.stringify({
            ride,
            pax,
            departure_date: formatDate(departure_date)
        } satisfies RideStorage));
        navigate('/ride/details')
    }
    return <div className="flex lg:flex-row flex-col gap-5">
        <div className={`border border-[#E4E7ED] p-3 grid grid-cols-4 gap-1 gap-x-3 sm:gap-x-1 lg:gap-x-14 lg:gap-3 rounded-lg pb-8 pr-5 w-full ${!widthFull && "max-w-2xl"}`}>

            <div className="col-span-4 sm:col-span-3 flex gap-5">
                <div className="bg-[#E4E7ED] w-24 h-24 md:block hidden" />
                <div className="grid grid-cols-3 gap.1 md:gap-4 ">
                    <div className="flex flex-col justify-center">
                        <h5>{ride.ride_meta.departure_location.name}</h5>
                        <p>{formatTime(ride.ride_meta.departure_time)}</p>
                    </div>
                    <div className="flex flex-col justify-center">
                        <img src={road} alt="road" />
                    </div>
                    <div className="flex flex-col justify-center items-end">
                        <h5 className="text-end">{ride.ride_meta.ride_destination.location.name}</h5>
                        <p>{formatTime(ride.ride_meta.ride_destination.arrival_time)}</p>
                    </div>
                </div>
            </div>

            <div className="flex flex-col items-end justify-center col-span-4 sm:col-span-1">
                <p className="text-t40orange font-semibold text-xl">
                    <span className="text-[#627496] text-sm font-light sm:mr-3">Price: </span>
                    <Naira />{ride.ride_meta.ride_destination.amount_with_discount.toLocaleString()}</p>
                {!hideButton && <button onClick={handleClick} className="btn w-full max-w-[150px] bg-[#102751] mt-3 text-white sm:block hidden">Book Ride</button>}
            </div>
            {!hideButton && <button onClick={handleClick} className="btn w-full  bg-[#102751] mt-3 text-white sm:hidden block col-span-full">Book Ride</button>}
        </div>
        <div className="border border-[#E4E7ED] p-3 rounded-lg w-full mb-10 lg:mb-0 lg:w-60">
            <p className="font-semibold">Price Summary</p>

            <hr className="my-3" />

            <ul className="grid gap-2 text-sm">
                <li className="grid grid-cols-2 gao-2 ">
                    <p>Bus Fare</p>
                    <p className="font-semibold text-t40orange"><Naira />{ride.ride_meta.ride_destination.amount.toLocaleString()}</p>
                </li>
                <li className="grid grid-cols-2 gao-2">
                    <p>Discount</p>
                    <p className="font-semibold"><Naira />{ride.ride_meta.ride_destination.discount.date_discount_amount.toLocaleString()}</p>
                </li>
                <li className="grid grid-cols-2 gao-2">
                    <p>Total Price</p>
                    <p className="font-semibold"><Naira />{ride.ride_meta.ride_destination.amount_with_discount.toLocaleString()}</p>
                </li>
            </ul>
        </div>
    </div>
}