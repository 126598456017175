import { useEffect, useState } from 'react'
import { Label, Radio } from "flowbite-react";
import T40Container from '../layout/Container'
import FilterLocation from '../components/locations/FilterLocation'
import AvailableLocation from '../components/locations/AvailableLocation';
import locationsData from '../components/locations/locations.json';
import { FaChevronDown } from "react-icons/fa6";
import xIcon from '../assets/x.svg';
import { motion } from 'framer-motion';
import { getDynamicTexts } from '../utils/dynamic/textUtils';
import baseURL, { brandID } from '../api/ApiConfig';
import axios from 'axios';

const Locations = () => {

    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [getLocations, setGetLocations] = useState<any>([]);
    const [filteredLocations, setFilteredLocations] = useState<any>([]);
    const [selectedState, setSelectedState] = useState<string>('');
    const [states, setStates] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false)

    const dynamicText = getDynamicTexts();
    const handleClick: any = () => {
        setOpenFilter(prev => !prev);
    }

    const getLocation = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${baseURL}/v1/intercity/generals/locations?company_id=${brandID}`)
            // const response = await axios.get(`${baseURL}/v1/intercity/generals/locations`)
            const locations = response.data.data;

            setGetLocations(locations);

            const uniqueStates: any = Array.from(new Set(locations.map((location: any) => location.address.split(',')[0])));
            setStates(uniqueStates);
            setFilteredLocations(locations);

            // .then((res: any) => {
            //     setGetLocations(res.data.data);
            // })
        } catch (error: any) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }


    const handleFilterChange = (state: string) => {
        setSelectedState(state);

        if (state) {
            const filtered = getLocations.filter((location: any) => location.address.includes(state));
            setFilteredLocations(filtered);
            setOpenFilter(false);
        } else {
            setFilteredLocations(getLocations);
        }
    };

    useEffect(() => {
        getLocation();
    }, [])

    return (
        <main className='pb-20'>
            <div className="hidden bg-gray-900 px-6 lg:px-0">
                <T40Container>
                    <motion.div
                        initial={{
                            y: 50,
                            opacity: 0,
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 100,
                            // damping: 70,
                            duration: 4,
                        }}
                        whileInView={{
                            y: 0,
                            opacity: 1
                        }}
                        className="w-full py-4 flex flex-row items-center justify-center gap-3"
                    >
                        <div className="bg-pink-500 px-2 rounded">
                            <span className="text-white font-black text-xs lg:text-sm">
                                NOTICE
                            </span>
                        </div>

                        <span className="text-xs lg:text-sm font-medium text-blue-50">
                            {dynamicText.ticketTitle}
                        </span>
                    </motion.div>
                </T40Container>
            </div>
            <T40Container>
                <div className={`${states.length === 0 ? "hidden": "block"} relative`} >
                    <FilterLocation onClick={handleClick} />

                    {
                        openFilter &&                          
                        <div className={`${states.length === 0 ? "hidden": "block"} bg-white border border-gray-300 rounded-[8px] z-40 absolute top-20 md:top-24 right-0 w-full sm:w-[335px] md:w-[480px] max-h-[50vh] overflow-y-auto`}>
                            <div className="hidden sm:flex justify-end items-center">
                                <button className='pr-[8px] pt-[8px]' onClick={() => setOpenFilter(false)}>
                                    <img src={xIcon} alt="cancel button" />
                                </button>
                            </div>

                            <div className='sm:px-6 sm:pb-6 sm:pt-0 p-4'>
                                <p className='text-gray-900 leading-tight font-semibold'>
                                    Please select a category to filter your search
                                </p>

                                <fieldset>
                                    <div className="flex items-center justify-between">
                                        <p className='text-gray-500 font-medium mt-2'>State</p>

                                        {selectedState && (
                                            <button
                                                onClick={() => { handleFilterChange(''); setOpenFilter(false); }}
                                                className="mt-4 bg-gray-200 text-gray-800 hover:bg-gray-300 rounded-md px-4 py-2 text-xs font-medium"
                                            >
                                                Clear Filter
                                            </button>
                                        )}
                                    </div>

                                    <div className="space-y-2 mt-1">
                                        {states.map((state) => (
                                            <div key={state} className="flex items-center gap-2">
                                                <Radio
                                                    id={state}
                                                    name="locations"
                                                    value={state}
                                                    checked={selectedState === state}
                                                    onChange={() => handleFilterChange(state)}                                                    
                                                />
                                                <Label className='cursor-pointer' htmlFor={state}>{state}</Label>
                                            </div>
                                        ))}
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    }
                </div>

                <section className='mt-20 '>
                    <div className='md:grid md:grid-cols-2 block lg:grid-cols-3 gap-x-8 lg:gap-y-16 md:gap-y-16 md:space-y-0 space-y-14 '>
                        {
                            loading ? (
                                <p>Loading . . .</p>
                            ) : (
                                filteredLocations.length > 0 ? (
                                    filteredLocations.map((location: any) => (
                                        <AvailableLocation key={location.id} {...location} />
                                    ))
                                ) : (
                                    <p>No available location</p>
                                )
                            )
                        }
                    </div>

                    {
                        filteredLocations.length >= 20 &&
                        <div className='mt-10 flex w-full justify-center items-center'>
                            <button className='bg-white text-[#1f2a37] text-base font-medium border border-[#E5E7EB] rounded-[8px] py-[14px] px-6 flex items-center space-x-2'>
                                <span>Load more </span>
                                <FaChevronDown />
                            </button>
                        </div>
                    }
                </section>
            </T40Container>
        </main>
    )
}

export default Locations