import * as React from "react";
import {
	SeatIcon,
	SeatIconBooked,
	SeatIconChecked,
} from "../../../assets/ExportSvg";
import { toast } from "react-toastify";
import { useBookingContext } from "../../../context/BookingContext";

interface ISeatSelectProps {
	data: {
		ride_departure_id: string;
		ride_meta: {
			ride_meta_id: string;
			ride_destination: {
				ride_destination_id: string;
				amount: number;
				arrival_time: string;
				discount: {
					ride_discount_amount: number;
					date_discount_amount: number;
				};
				id: string;
				amount_with_discount: number;
				location: {
					name: string;
					description: string | null;
					address: string | null;
					latitude: number | null;
					longitude: number | null;
					what3word: string | null;
					city: {
						id: string;
						name: string;
						code: string | null;
					};
				};
			};
			departure_time: string;
			comment: string | null;
			business: {
				id: string;
				business_name: string;
				business_email: string;
				phone_number: string;
				address: string;
				postal_code: string | null;
				api_key: string | null;
				abbr_code: string | null;
				logo_url: string;
				is_verified: boolean;
				status: string;
				longitude: number | null;
				latitude: number | null;
				what3word: string | null;
			};
			brand: {
				id: string;
				brand_name: string;
				rating: number;
				status: string;
				logo_url: string;
				url: string;
				customers: {
					bookings: number;
					parcels: number;
				};
			};
			currency: {
				id: string;
				name: string;
				symbol: string;
				code: string;
			};
			vehicle_type: {
				id: string;
				name: string;
				category: string;
				row_col: [number, number];
				cordinates: [number, number][];
				status: string;
				capacity: number;
			};
			ride_departure_location: {
				name: string;
				description: string | null;
				address: string;
				latitude: number;
				longitude: number;
				what3word: string;
				city: {
					id: string;
					name: string;
					code: string | null;
				};
			};
			departure_location: {
				name: string;
				description: string | null;
				address: string;
				latitude: number;
				longitude: number;
				what3word: string;
				city: {
					id: string;
					name: string;
					code: string | null;
				};
			};
			ride_destinations: {
				ride_destination_id: string;
				amount: number;
				arrival_time: string;
				discount: {
					ride_discount_amount: number;
					date_discount_amount: number;
				};
				id: string;
				amount_with_discount: number;
				location: {
					name: string;
					description: string | null;
					address: string | null;
					latitude: number | null;
					longitude: number | null;
					what3word: string | null;
					city: {
						id: string;
						name: string;
						code: string | null;
					};
				};
			}[];
		};
		departure_date: string;
		no_of_seats: number;
		seats: {
			id: string;
			ride_seat_id: string;
			x: number;
			y: number;
			seat_no: number;
			is_available: boolean;
			is_reserved: boolean;
		}[];
		dispatch_status: string;
		id: string;
		ride_code: string | null;
	};
	selectedSeatIds: { id: string; seatNumber: number }[];
	setSelectedSeatIds: React.Dispatch<
		React.SetStateAction<{ id: string; seatNumber: number }[]>
	>;
}

const SeatSelect: React.FunctionComponent<ISeatSelectProps> = (props) => {
	const { data, selectedSeatIds, setSelectedSeatIds } = props;
	const { numberOfTravelers } = useBookingContext();
	const pax = numberOfTravelers;

	const seatRow = data.ride_meta.vehicle_type.row_col[0];
	const seatColumn = data.ride_meta.vehicle_type.row_col[1];

	const handleSeatClick = (seatId: string, seatNumber: number) => {
		if (
			selectedSeatIds.length < pax ||
			selectedSeatIds.some((seat) => seat.id === seatId)
		) {
			setSelectedSeatIds((prev) =>
				prev.some((seat) => seat.id === seatId)
					? prev.filter((seat) => seat.id !== seatId)
					: [...prev, { id: seatId, seatNumber }]
			);
		} else {
			// Display an error message or handle the error appropriately
			toast.error("You can only select up to " + pax + " seats.");
		}
	};

	return (
		<div className="relative">
			<div
				className={`grid place-items-center ${
					seatColumn && seatColumn <= 3 ? "gap-10" : "gap-7"
				} `}
				style={{
					gridTemplateColumns: `repeat(${seatColumn}, 1fr)`,
					gridTemplateRows: `repeat(${seatRow}, 1fr)`,
				}}>
				<div></div> <div></div>
				{data.seats.length > 0 && typeof data.seats[0] === "object" ? (
					<SeatButton
						number={1}
						checked={selectedSeatIds.some(
							(selectedSeat) => selectedSeat.id === data.seats[0].ride_seat_id
						)}
						isAvailable={data.seats[0].is_available}
						onClick={() => handleSeatClick(data.seats[0].ride_seat_id, 1)}
					/>
				) : (
					<p>No seats available</p>
				)}
				{data.seats.slice(1).map((seat, index) => {
					const num = index + 2;
					return (
						<div className="relative" key={seat.id}>
							<SeatButton
								number={num}
								checked={selectedSeatIds.some(
									(selectedSeat) => selectedSeat.id === seat.ride_seat_id
								)}
								isAvailable={seat.is_available}
								onClick={() => handleSeatClick(seat.ride_seat_id, num)}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default SeatSelect;

interface SeatButtonProps {
	checked: boolean;
	number: number;
	onClick?: () => void;
	isAvailable?: boolean;
}

const SeatButton: React.FC<SeatButtonProps> = ({
	checked,
	number,
	onClick,
	isAvailable = false,
}) => {
	return (
		<>
			{!isAvailable ? (
				<button disabled={isAvailable} className="relative">
					<SeatIconBooked />
					<p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white">
						{number}
					</p>
				</button>
			) : (
				<button onClick={onClick} className="relative">
					{checked ? <SeatIconChecked /> : <SeatIcon />}
					<p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white">
						{number}
					</p>
				</button>
			)}
		</>
	);
};
