import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import Rides from "./pages/Rides";
import ContextProvider from "./context/Context";
import RideDetails from "./pages/RideDetails";
import BookRide from "./pages/BookRide";
import BookingComplete from "./pages/BookComplete";
import SelectStation from "./pages/SelectStation";
import BookTicketPage from "./components/selectStation/bookTicket/BookTicketPage";
import BookingReceipt from "./components/selectStation/bookTicket/BookingReceipt";
import Locations from "./pages/Locations";
import CreateReview from "./pages/CreateReview";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import ReviewsPage from "./pages/ReviewsPage";
import ScrollToTop from "./utils/helpers/ScrollToTop";
import EmailPrompt from "./pages/EmailPrompt";
import UserCreateReview from "./pages/UserCreateReview";
import { Mixpanel } from './utils/helpers/Mixpanel';
import { HelmetProvider } from 'react-helmet-async';
import WhatsApp from "./components/widgets/WhatsApp";
import { Helmet } from 'react-helmet-async';

function App() {

	const logo = process.env.REACT_APP_BRAND_LOGO_HEADER;
	useEffect(() => {
		Mixpanel.track('Home page viewed', { page: 'Home' })
	}, [])

	return (
		<BrowserRouter>
			<ContextProvider>
				<HelmetProvider>
					<Layout>
						<ScrollToTop />
						<Helmet>
							<link rel="icon" href={logo} />
							<link rel="apple-touch-icon" href={logo} />
						</Helmet>
						<Routes>
							<Route index path="/" element={<Home />} />
							<Route path="*" element={<Home />} />
							<Route path="/select-station" element={<SelectStation />} />
							<Route path="/select-station/:id" element={<BookTicketPage />} />
							<Route
								path="/select-station/:id/receipt"
								element={<BookingReceipt />}
							/>
							<Route path="/rides" element={<Rides />} />
							<Route path="/ride/details" element={<RideDetails />} />
							<Route path="/ride/book" element={<BookRide />} />
							<Route path="/ride/complete" element={<BookingComplete />} />
							<Route path="/locations" element={<Locations />} />
							<Route path="/write-reviews" element={<CreateReview />} />
							<Route path="/reviews" element={<ReviewsPage />} />
							<Route path="/email-review" element={<UserCreateReview />} />
							<Route path="/success-prompt" element={<EmailPrompt />} />
						</Routes>
						<WhatsApp />
					</Layout>
				</HelmetProvider>
			</ContextProvider>
			<ToastContainer />
		</BrowserRouter>
	);
}

export default App;
