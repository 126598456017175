import * as React from "react";
import { MonifyIcon, PaystackIcon } from "../../../../assets/ExportSvg";
import { getDynamicColors } from "../../../../utils/dynamic/colorUtils";
import CurrencyFormat from "../../../../utils/constants/CurrencyFormat";
import { PaystackButton } from "react-paystack";
import { toast } from "react-toastify";
import { BookingDataResult } from "../BookingProps";
import CustomButton from "../../../../utils/constants/Button";


interface Passenger {
	firstName: string;
	lastName: string;
	phoneNumber: string;
	gender: string;
	emailAddress: string;
	nokFirstName: string;
	nokLastName: string;
	nokPhoneNumber: string;
}

interface IPaymentMethodProps {
	setStepper: React.Dispatch<React.SetStateAction<number>>;
	data: {
		ride_departure_id: string;
		ride_meta: {
			ride_meta_id: string;
			ride_destination: {
				ride_destination_id: string;
				amount: number;
				arrival_time: string;
				discount: {
					ride_discount_amount: number;
					date_discount_amount: number;
				};
				id: string;
				amount_with_discount: number;
				location: {
					name: string;
					description: string | null;
					address: string | null;
					latitude: number | null;
					longitude: number | null;
					what3word: string | null;
					city: {
						id: string;
						name: string;
						code: string | null;
					};
				};
			};
			departure_time: string;
			comment: string | null;
			business: {
				id: string;
				business_name: string;
				business_email: string;
				phone_number: string;
				address: string;
				postal_code: string | null;
				api_key: string | null;
				abbr_code: string | null;
				logo_url: string;
				is_verified: boolean;
				status: string;
				longitude: number | null;
				latitude: number | null;
				what3word: string | null;
			};
			brand: {
				id: string;
				brand_name: string;
				rating: number;
				status: string;
				logo_url: string;
				url: string;
				customers: {
					bookings: number;
					parcels: number;
				};
			};
			currency: {
				id: string;
				name: string;
				symbol: string;
				code: string;
			};
			vehicle_type: {
				id: string;
				name: string;
				category: string;
				row_col: [number, number];
				cordinates: [number, number][];
				status: string;
				capacity: number;
			};
			ride_departure_location: {
				name: string;
				description: string | null;
				address: string;
				latitude: number;
				longitude: number;
				what3word: string;
				city: {
					id: string;
					name: string;
					code: string | null;
				};
			};
			departure_location: {
				name: string;
				description: string | null;
				address: string;
				latitude: number;
				longitude: number;
				what3word: string;
				city: {
					id: string;
					name: string;
					code: string | null;
				};
			};
			ride_destinations: {
				ride_destination_id: string;
				amount: number;
				arrival_time: string;
				discount: {
					ride_discount_amount: number;
					date_discount_amount: number;
				};
				id: string;
				amount_with_discount: number;
				location: {
					name: string;
					description: string | null;
					address: string | null;
					latitude: number | null;
					longitude: number | null;
					what3word: string | null;
					city: {
						id: string;
						name: string;
						code: string | null;
					};
				};
			}[];
		};
		departure_date: string;
		no_of_seats: number;
		seats: {
			id: string;
			ride_seat_id: string;
			x: number;
			y: number;
			seat_no: number;
			is_available: boolean;
			is_reserved: boolean;
		}[];
		dispatch_status: string;
		id: string;
		ride_code: string | null;
	};
	passengerDetails: Passenger[];
	extraData: BookingDataResult["extra"] | null;
	bookingData: BookingDataResult | null;
	setBookedSuccessfully: React.Dispatch<React.SetStateAction<boolean>>;
	closeDrawer: () => void;
}

const PaymentMethod: React.FunctionComponent<IPaymentMethodProps> = (props) => {
	const {
		data,
		passengerDetails,
		extraData,
		bookingData,
		setBookedSuccessfully,
		closeDrawer,
	} = props;
	const dynamicColors = getDynamicColors();
	const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState("");
	const primaryColor = dynamicColors.primary;

	const amountToCharge = Number(bookingData?.data.total_ride_fare) || 0;

	// Paystack
	const amountInKobo = Number(amountToCharge) * 100;
	console.log(amountInKobo);

	// const config = {
	// 	reference: new Date().getTime().toString(),
	// 	email: passengerDetails.emailAddress,
	// 	amount: amountInKobo,
	// 	publicKey: extraData?.paystack || "",
	// 	metadata: {
	// 		payment_type: "for.booking",
	// 	},
	// };
	const config = {
		reference: bookingData?.data.payment_reference || "",
		email: passengerDetails[0].emailAddress,
		amount: amountInKobo,
		publicKey: extraData?.paystack || "",
		metadata: {
			payment_type: "for.booking",
			custom_fields: [
				{
					display_name: "Payment Type",
					variable_name: "payment_type",
					value: "for.booking",
				},
			],
		},
	};

	const handlePaystackSuccessAction = (reference: any) => {
		const response = reference;
		console.log(response);
		if (response.status === "success") {
			toast.success("Payment Successful!");
			sessionStorage.setItem("paymentStatus", "successful");
			setBookedSuccessfully(true);
			closeDrawer();
		} else {
			toast.error(response.message);
		}
	};

	const handlePaystackCloseAction = () => {
		console.log("closed");
	};

	const componentProps = {
		...config,
		text: "Make Payment",
		onSuccess: (reference: any) => handlePaystackSuccessAction(reference),
		onClose: handlePaystackCloseAction,
	};

	// End of Paystack Payment Button

	// ------------------------------------

	// Monnify

	const payWithMonnify = () => {
		const existingMonnifyModal = document.getElementById("monnify-modal");

		if (existingMonnifyModal) {
			existingMonnifyModal.remove();
		}

		(window as any).MonnifySDK.initialize({
			amount: amountToCharge,
			currency: "NGN",
			reference: bookingData?.data.payment_reference || "",
			customerFullName:
				passengerDetails[0].firstName + " " + passengerDetails[0].lastName,
			customerEmail: passengerDetails[0].emailAddress,
			apiKey: extraData?.monnify || "",
			contractCode: extraData?.monnify_contract_code || "",
			paymentDescription: "Ticket Booking",
			metaData: {
				paymentType: "for.booking",
			},

			onLoadStart: () => {
				console.log("Loading has started");
			},
			onLoadComplete: () => {
				console.log("SDK is UP");
			},
			onComplete: (response: any) => {
				console.log(response);
				if (response.status === "SUCCESS") {
					toast.success("Payment Successful!");
					sessionStorage.setItem("paymentStatus", "successful");
					setBookedSuccessfully(true);
					closeDrawer();
				}
			},
			onClose: (data: any) => {
				console.log(data);
				if (existingMonnifyModal) {
					existingMonnifyModal.remove();
				}
			},
		});
	};

	React.useEffect(() => {
		// Initialize Monnify SDK on component mount
		(window as any).MonnifySDK.initialize();
	}, []);
	return (
		<>
			<div className="relative p-4">
				<div className="flex items-center justify-between">
					<h2 className="text-slate-950 text-2xl font-semibold">
						Choose Payment Method
					</h2>
				</div>
				<div className="space-y-3 mt-8">
					<p className="text-gray-400 text-xs font-medium uppercase">
						HOW WOULD YOU LIKE TO PAY?
					</p>
					<div className="space-y-4">
						{paymentMethods.map((provider, index) => (
							<button
								key={index}
								onClick={() => setSelectedPaymentMethod(provider.provider)}
								className={`w-full py-4 pl-4 border rounded-lg text-slate-950`}
								style={{
									borderColor:
										selectedPaymentMethod === provider.provider
											? dynamicColors.primary
											: "",
								}}>
								<div className="flex items-center gap-2">
									{provider.icon}
									<p>{provider.provider}</p>
								</div>
							</button>
						))}
					</div>
				</div>
			</div>

			<div className="fixed w-full lg:max-w-lg bottom-0 lg:bottom-12 ">
				<div className="border-t shadow bg-white py-5 px-4 lg:rounded-bl-3xl lg:rounded-br-3xl">
					<div className="flex items-center justify-between gap-10">
						<div className="w-[80%] flex flex-col ">
							<div className="flex items-center gap-2">
								<p className="text-gray-500 text-xs font-normal line-through">
									{data.ride_meta.currency.symbol}
									<CurrencyFormat
										amount={data.ride_meta.ride_destination.amount}
									/>
								</p>

								{/* Discount Percentage  */}
								{/* <div className="px-1 bg-green-100 rounded text-xs text-emerald-900">
									<span>-10%</span>
								</div> */}
							</div>
							<p className="text-slate-950 text-lg font-semibold">
								{data.ride_meta.currency.symbol}
								<CurrencyFormat
									amount={data.ride_meta.ride_destination.amount_with_discount}
								/>
							</p>
						</div>

						{selectedPaymentMethod === "Paystack" ? (
							<PaystackButton
								{...componentProps}
								className={`w-full max-w-sm text-white text-base font-medium rounded-lg px-6 py-3.5 bg-blue-700 ${
									primaryColor ? `bg-${primaryColor}` : "bg-blue-500"
								}`}
							/>
						) : selectedPaymentMethod === "Monnify" ? (
							<CustomButton
								onClick={payWithMonnify}
								type="button"
								text="Make Payment"
								small={false}
							/>
						) : (
							""
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default PaymentMethod;

const paymentMethods = [
	{
		icon: <PaystackIcon className="w-6 h-6" />,
		provider: "Paystack",
	},
	{
		icon: <MonifyIcon className="w-6 h-6" />,
		provider: "Monnify",
	},
];
