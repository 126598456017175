import * as React from "react";
import CustomButton from "../../../../utils/constants/Button";
import CurrencyFormat from "../../../../utils/constants/CurrencyFormat";
import baseURL from "../../../../api/ApiConfig";
import axios from "axios";
import LoadingSpinner from "../../../../utils/constants/Spinner";
import { getDynamicColors } from "../../../../utils/dynamic/colorUtils";
import { toast } from "react-toastify";
import { BookingDataResult } from "../BookingProps";
import { useBookingContext } from "../../../../context/BookingContext";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface Passenger {
	firstName: string;
	lastName: string;
	phoneNumber: string;
	gender: string;
	emailAddress: string;
	nokFirstName: string;
	nokLastName: string;
	nokPhoneNumber: string;
}
interface IPassengerDetailsProps {
	setStepper: React.Dispatch<React.SetStateAction<number>>;
	data: {
		ride_departure_id: string;
		ride_meta: {
			ride_meta_id: string;
			ride_destination: {
				ride_destination_id: string;
				amount: number;
				arrival_time: string;
				discount: {
					ride_discount_amount: number;
					date_discount_amount: number;
				};
				id: string;
				amount_with_discount: number;
				location: {
					name: string;
					description: string | null;
					address: string | null;
					latitude: number | null;
					longitude: number | null;
					what3word: string | null;
					city: {
						id: string;
						name: string;
						code: string | null;
					};
				};
			};
			departure_time: string;
			comment: string | null;
			business: {
				id: string;
				business_name: string;
				business_email: string;
				phone_number: string;
				address: string;
				postal_code: string | null;
				api_key: string | null;
				abbr_code: string | null;
				logo_url: string;
				is_verified: boolean;
				status: string;
				longitude: number | null;
				latitude: number | null;
				what3word: string | null;
			};
			brand: {
				id: string;
				brand_name: string;
				rating: number;
				status: string;
				logo_url: string;
				url: string;
				customers: {
					bookings: number;
					parcels: number;
				};
			};
			currency: {
				id: string;
				name: string;
				symbol: string;
				code: string;
			};
			vehicle_type: {
				id: string;
				name: string;
				category: string;
				row_col: [number, number];
				cordinates: [number, number][];
				status: string;
				capacity: number;
			};
			ride_departure_location: {
				name: string;
				description: string | null;
				address: string;
				latitude: number;
				longitude: number;
				what3word: string;
				city: {
					id: string;
					name: string;
					code: string | null;
				};
			};
			departure_location: {
				name: string;
				description: string | null;
				address: string;
				latitude: number;
				longitude: number;
				what3word: string;
				city: {
					id: string;
					name: string;
					code: string | null;
				};
			};
			ride_destinations: {
				ride_destination_id: string;
				amount: number;
				arrival_time: string;
				discount: {
					ride_discount_amount: number;
					date_discount_amount: number;
				};
				id: string;
				amount_with_discount: number;
				location: {
					name: string;
					description: string | null;
					address: string | null;
					latitude: number | null;
					longitude: number | null;
					what3word: string | null;
					city: {
						id: string;
						name: string;
						code: string | null;
					};
				};
			}[];
		};
		departure_date: string;
		no_of_seats: number;
		seats: {
			id: string;
			ride_seat_id: string;
			x: number;
			y: number;
			seat_no: number;
			is_available: boolean;
			is_reserved: boolean;
		}[];
		dispatch_status: string;
		id: string;
		ride_code: string | null;
	};
	passengerDetails: Passenger[];
	setPassengerDetails: React.Dispatch<React.SetStateAction<Passenger[]>>;
	selectedSeatIds: { id: string; seatNumber: number }[];
	setExtraData: React.Dispatch<
		React.SetStateAction<BookingDataResult["extra"] | null>
	>;
	setBookingData: React.Dispatch<
		React.SetStateAction<BookingDataResult | null>
	>;
}

const genderOptions = ["Male", "Female", "Other"];

const PassengerDetails: React.FunctionComponent<IPassengerDetailsProps> = (
	props
) => {
	const {
		setStepper,
		data,
		passengerDetails,
		setPassengerDetails,
		selectedSeatIds,
		setExtraData,
		setBookingData,
	} = props;

	// console.log("passenger:", passengerDetails)
	// console.log("passenger:", data)
	const [bookingTicket, setBookingTicket] = React.useState(false);
	const dynamicColors = getDynamicColors();
	const { numberOfTravelers } = useBookingContext();

	const handleChange = (
		index: number,
		field: keyof Passenger,
		value: string
	) => {
		if (
			![
				"firstName",
				"lastName",
				"phoneNumber",
				"gender",
				"emailAddress",
				"nokFirstName",
				"nokLastName",
				"nokPhoneNumber",
			].includes(field)
		) {
			console.error("Invalid field:", field);
			return;
		}

		setPassengerDetails((prevDetails) => {
			const newDetails = [...prevDetails];
			newDetails[index][field] = value;
			return newDetails;
		});
	};

	const handleButton = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setBookingTicket(true);

		let entry = JSON.stringify({
			referral_coupon_code: "",
			pickup_location_id: "",
			ticket_bid_code: null,
			referred_by: "",
			ride: {
				departure_ride_id: data.ride_departure_id,
				comment: "",
				destination_ride_id:
					data.ride_meta.ride_destination.ride_destination_id,
				platform: "Intercity Web",
			},
			passengers: passengerDetails.map((passenger, index) => ({
				ride_seat_id: selectedSeatIds[index] ? selectedSeatIds[index].id : "",
				passenger_type_id: "98e7cb0a-5ce5-48dc-b975-7e4333cb790d",
				first_name: passenger.firstName,
				last_name: passenger.lastName,
				gender: "male", // You might want to replace this with the actual gender from passenger
				phone_number: passenger.phoneNumber,
				email: passenger.emailAddress,
				next_of_kin_first_name: passenger.nokFirstName,
				next_of_kin_last_name: passenger.nokLastName,
				next_of_kin_phone_number: passenger.nokPhoneNumber,
			})),
			ride_addons: [],
		});

		console.log("ENTRIES", entry);

		let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${baseURL}/v1/intercity/bookings`,
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			data: entry,
		};

		axios
			.request(config)
			.then((response) => {
				const res = response.data;
				console.log(res);
				setBookingTicket(false);
				setExtraData(res.extra);
				setBookingData(res);
				setStepper(3);
			})
			.catch((error) => {
				const errorMessage = error.response.data.message;
				setBookingTicket(false);
				if (
					errorMessage ===
					"The selected seat is currently available. Please try again in 2 minutes."
				) {
					setStepper(1);
					console.log(error);
					toast.error("Seat not available! Select another seat.");
					setBookingTicket(false);
				} else {
					console.log(error);
					toast.error(errorMessage);
					setBookingTicket(false);
				}
			});
	};

	console.log("numberOfTravelers:", numberOfTravelers);
	console.log("passengerDetails length:", passengerDetails.length);

	return (
		<div className="pb-24">
			<form onSubmit={handleButton} className="w-full lg:max-w-lg">
				<div className="relative p-4">
					<div className="flex items-center justify-between">
						<h2 className="text-slate-950 text-2xl font-semibold">
							Passenger Details
						</h2>
					</div>

					<div className="space-y-3 mt-8">
						<p className="text-gray-400 text-xs font-medium uppercase">
							PASSENGER DETAILS
						</p>

						<div className="space-y-10">
							{Array.from({ length: numberOfTravelers }, (_, index) => (
								<div key={index}>
									{/* Form Fields  */}
									{numberOfTravelers > 1 && (
										<div className="bg-gray-200 py-1 px-2 rounded mb-3">
											<p className="text-sm text-gray-600">
												Passenger {index + 1}
											</p>
										</div>
									)}
									<div className="pt-2 space-y-5">
										<div className="grid grid-cols-2 gap-5">
											<div className="grid grid-cols-1 gap-2">
												<label
													className="text-gray-900 text-sm font-medium"
													htmlFor="firstName">
													First Name<span className="text-red-600">*</span>
												</label>
												<input
													type="text"
													name={`firstName${index}`}
													value={passengerDetails[index]?.firstName || ""}
													onChange={(e) =>
														handleChange(index, "firstName", e.target.value)
													}
													placeholder="Enter first name"
													className="bg-gray-100 rounded grow shrink basis-0 border-none px-4 py-3 focus:outline-none ring-0 focus:ring-0 caret-gray-500"
													required
												/>
											</div>

											<div className="grid grid-cols-1 gap-2">
												<label
													className="text-gray-900 text-sm font-medium"
													htmlFor="lastName">
													Last Name<span className="text-red-600">*</span>
												</label>
												<input
													type="text"
													name={`lastName${index}`}
													value={passengerDetails[index].lastName || ""}
													onChange={(e) =>
														handleChange(index, "lastName", e.target.value)
													}
													placeholder="Enter last name"
													className="bg-gray-100 rounded grow shrink basis-0 border-none px-4 py-3 focus:outline-none ring-0 focus:ring-0 caret-gray-500"
													required
												/>
											</div>
										</div>

										<div className="grid grid-cols-1 col-span-2 gap-5">
											{/* <div className="grid grid-cols-1 gap-2">
												<label
													className="text-gray-900 text-sm font-medium"
													htmlFor="gender">
													Gender<span className="text-red-600">*</span>
												</label>
												<select
													name={`gender${index}`}
													value={passengerDetails[index].gender || ""}
													onChange={(e) =>
														handleChange(index, "gender", e.target.value)
													}
													className="bg-gray-100 rounded grow shrink basis-0 border-none px-4 py-3 focus:outline-none ring-0 focus:ring-0 caret-gray-500"
													required>
													<option value="" disabled>
														Choose Gender
													</option>
													{genderOptions.map((option, index) => (
														<option key={index} value={option}>
															{option}
														</option>
													))}
												</select>
											</div> */}

											<div className=" relative">
												<label
													className="text-gray-900 text-sm font-medium"
													htmlFor="phoneNumber">
													Phone Number<span className="text-red-600">*</span>
												</label>
												<div>
													<input
														type="tel"
														name={`phoneNumber${index}`}
														value={passengerDetails[index].phoneNumber || ""}
														onChange={(e) =>
															handleChange(index, "phoneNumber", e.target.value)
														}
														placeholder="Enter phone number"
														className="bg-gray-100 w-full rounded grow shrink basis-0 border-none px-4 py-3 focus:outline-none ring-0 focus:ring-0 caret-gray-500"
														maxLength={11}
														pattern="[0-9]*"
														required
													/>
													{passengerDetails[index].phoneNumber.length > 0 &&
														passengerDetails[index].phoneNumber.length !== 11 && (
															<p className="text-xs text-red-500 absolute -bottom-5">
																Must be 11 digits,{" "}
																{11 - passengerDetails[index].phoneNumber.length}{" "}
																digit left.
															</p>
														)}
												</div>

											</div>

											<div className=" relative">
												<label
													className="text-gray-900 text-sm font-medium"
													htmlFor="phoneNumber">
													Phone Number<span className="text-red-600">*</span>
												</label>
												<div>
													<input
														type="tel"
														name={`phoneNumber${index}`}
														value={passengerDetails[index].phoneNumber || ""}
														onChange={(e) =>
															handleChange(index, "phoneNumber", e.target.value)
														}
														placeholder="Enter phone number"
														className="bg-gray-100 w-full rounded grow shrink basis-0 border-none px-4 py-3 focus:outline-none ring-0 focus:ring-0 caret-gray-500"
														maxLength={11}
														pattern="[0-9]*"
														required
													/>
													{passengerDetails[index].phoneNumber.length > 0 &&
														passengerDetails[index].phoneNumber.length !== 11 && (
															<p className="text-xs text-red-500 absolute -bottom-5">
																Must be 11 digits,{" "}
																{11 - passengerDetails[index].phoneNumber.length}{" "}
																digit left.
															</p>
														)}
												</div>

											</div>
										</div>

										<div className="grid grid-cols-1 gap-2">
											<label
												className="text-gray-900 text-sm font-medium"
												htmlFor="emailAddress">
												Email Address {"(Optional)"}
											</label>
											<input
												type="email"
												name={`emailAddress${index}`}
												value={passengerDetails[index].emailAddress || ""}
												onChange={(e) =>
													handleChange(index, "emailAddress", e.target.value)
												}
												placeholder="Enter email address"
												className="bg-gray-100 rounded grow shrink basis-0 border-none px-4 py-3 focus:outline-none ring-0 focus:ring-0 caret-gray-500"
												required
											/>
										</div>

										<p className="text-gray-400 text-xs font-medium uppercase">
											NEXT OF KIN DETAILS
										</p>

										<div className="grid grid-cols-2 gap-5">
											<div className="grid grid-cols-1 gap-2">
												<label
													className="text-gray-900 text-sm font-medium"
													htmlFor="nokFirstName">
													First Name<span className="text-red-600">*</span>
												</label>
												<input
													type="text"
													name={`nokFirstName${index}`}
													value={passengerDetails[index].nokFirstName || ""}
													onChange={(e) =>
														handleChange(index, "nokFirstName", e.target.value)
													}
													placeholder="Enter first name"
													className="bg-gray-100 rounded grow shrink basis-0 border-none px-4 py-3 focus:outline-none ring-0 focus:ring-0 caret-gray-500"
													required
												/>
											</div>

											<div className="grid grid-cols-1 gap-2">
												<label
													className="text-gray-900 text-sm font-medium"
													htmlFor="nokLastName">
													Last Name<span className="text-red-600">*</span>
												</label>
												<input
													type="text"
													name={`nokLastName${index}`}
													value={passengerDetails[index].nokLastName || ""}
													onChange={(e) =>
														handleChange(index, "nokLastName", e.target.value)
													}
													placeholder="Enter last name"
													className="bg-gray-100 rounded grow shrink basis-0 border-none px-4 py-3 focus:outline-none ring-0 focus:ring-0 caret-gray-500"
													required
												/>
											</div>
										</div>

										<div className="flex flex-col-reverse lg:grid lg:grid-cols-2 gap-5">
											<div className="grid grid-cols-1 col-span-2 gap-2 relative">
												<label
													className="text-gray-900 text-sm font-medium"
													htmlFor="phoneNumber">
													Phone Number<span className="text-red-600">*</span>
												</label>
												<input
													type="text"
													name={`nokPhoneNumber${index}`}
													value={passengerDetails[index].nokPhoneNumber || ""}
													onChange={(e) =>
														handleChange(
															index,
															"nokPhoneNumber",
															e.target.value
														)
													}
													placeholder="Enter phone number"
													className="bg-gray-100 rounded grow shrink basis-0 border-none px-4 py-3 focus:outline-none ring-0 focus:ring-0 caret-gray-500"
													maxLength={11}
													pattern="[0-9]*"
													required
												/>
												{passengerDetails[index].nokPhoneNumber.length > 0 &&
													passengerDetails[index].nokPhoneNumber.length !==
													11 && (
														<p className="text-xs text-red-500 absolute -bottom-5">
															Must be 11 digits,{" "}
															{11 -
																passengerDetails[index].nokPhoneNumber
																	.length}{" "}
															digit left.
														</p>
													)}
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>

				<div className="fixed w-full lg:max-w-lg bottom-0 lg:bottom-12 ">
					<div className="border-t shadow bg-white py-5 px-4 lg:rounded-bl-3xl lg:rounded-br-3xl">
						<div className="flex items-center justify-between gap-10">
							<div className="w-[80%] flex flex-col ">
								<div className="flex items-center gap-2">
									<p className="text-gray-500 text-xs font-normal line-through">
										{data.ride_meta.currency.symbol}
										<CurrencyFormat
											amount={data.ride_meta.ride_destination.amount}
										/>
									</p>

									{/* Discount percentage  */}
									{/* <div className="px-1 bg-green-100 rounded text-xs text-emerald-900">
										<span>-10%</span>
									</div> */}
								</div>
								<p className="text-slate-950 text-lg font-semibold">
									{data.ride_meta.currency.symbol}
									<CurrencyFormat
										amount={
											data.ride_meta.ride_destination.amount_with_discount
										}
									/>
								</p>
							</div>
							{bookingTicket ? (
								<button
									type="button"
									className="w-full max-w-xs text-white text-base font-medium rounded-lg px-6 py-3.5 flex items-center justify-center"
									style={{ backgroundColor: dynamicColors.primary }}>
									<LoadingSpinner />
								</button>
							) : (
								<CustomButton
									onClick={() => { }}
									type="submit"
									text="Continue"
									small={true}
									disabled={
										!passengerDetails[0].firstName ||
										!passengerDetails[0].lastName ||
										!passengerDetails[0].phoneNumber ||
										// !passengerDetails[0].gender ||
										// !passengerDetails[0].emailAddress ||
										!passengerDetails[0].nokFirstName ||
										!passengerDetails[0].nokLastName ||
										!passengerDetails[0].nokPhoneNumber
									}
								/>
							)}
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default PassengerDetails;
