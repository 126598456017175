import React from "react";

interface TimeConversionProps {
	timeString: string;
}

const TimeConversion: React.FC<TimeConversionProps> = ({ timeString }) => {
	const convert24HourTo12Hour = (time: string): string => {
		const [hours, minutes] = time.split(":").map(Number);

		if (isNaN(hours) || isNaN(minutes)) {
			return "Invalid Time";
		}

		const period = hours >= 12 ? "pm" : "am";
		const twelveHourFormat = hours % 12 || 12;

		return `${twelveHourFormat}:${String(minutes).padStart(2, "0")}${period}`;
	};

	const formattedTime = convert24HourTo12Hour(timeString);

	return <span>{formattedTime}</span>;
};

export default TimeConversion;
