import React from "react";
import { Link } from "react-router-dom";
import { HiHome } from "react-icons/hi";
import T40Container from "../../layout/Container";

interface Breadcrumb {
	label: string;
	url: string;
}

interface BreadcrumbsProps {
	breadcrumbs: Breadcrumb[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
	return (
		<div className="py-3 border-t border-b border-gray-200 bg-white">
			<T40Container>
				<nav className="flex items-center text-gray-500 text-sm">
					{breadcrumbs.map((breadcrumb, index) => (
						<React.Fragment key={breadcrumb.url}>
							{index > 0 ? (
								<svg
									className="flex-shrink-0 w-4 h-4 mx-1 text-gray-400"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor">
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M9 5l7 7-7 7"
									/>
								</svg>
							) : (
								<HiHome className="mr-1" />
							)}
							{index === breadcrumbs.length - 1 ? (
								<span className="font-medium text-slate-950">
									{breadcrumb.label}
								</span>
							) : (
								<Link
									to={breadcrumb.url}
									className="hover:text-gray-700 hover:underline">
									{breadcrumb.label}
								</Link>
							)}
						</React.Fragment>
					))}
				</nav>
			</T40Container>
		</div>
	);
};

export default Breadcrumbs;
