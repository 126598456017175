import T40Container from '../../layout/Container'
import { Headings } from '../../utils/constants/Headings'
import makePayment from '../../assets/make-payment.png';
import busTicket from '../../assets/bus-ticket.png';
import searchLocation from '../../assets/search-location.png';
import searchForRides from '../../assets/search-for-rides.png';
import { brandName } from '../../api/ApiConfig';
import { useNavigate } from 'react-router-dom';


type HowtoBuyTicketsProps = {
    id: number,
    title: string,
    details: string,
    image: string,
    alt: string,
    idColor: string
}

const HowtoBuyTickets = () => {

    const navigate = useNavigate();

    const getBusTickets: HowtoBuyTicketsProps[] = [
        {
            id: 1,
            title: "Search for rides",
            details: `To purchase your bus tickets on ${brandName}, 
            start by searching for rides either by location 
            or destination.`,
            image: searchForRides,
            alt: "woman with her hat looking at her phone",
            idColor: "text-orange-500 bg-orange-50"
        },
        {
            id: 2,
            title: "Select location",
            details: `Select your location (e.g Lagos, Abuja), 
            destination (Aba), add departure time, and if you 
            would be returning by road, specify your return date.`,
            image: searchLocation,
            alt: "direction location map",
            idColor: "bg-indigo-50 text-indigo-600"
        },
        {
            id: 3,
            title: "Make payment",
            details: `Input passenger information including your 
            name, mobile number, and email address, then proceed to 
            make payment.`,
            image: makePayment,
            alt: "making payment",
            idColor: "bg-yellow-100 text-yellow-400"
        },
        {
            id: 4,
            title: "Get your bus ticket instantly",
            details: `After payment, your bus ticket will be 
            emailed or sent via SMS. Have it printed or show 
            the SMS to our Bus Operator when boarding.`,
            image: busTicket,
            alt: "get your bus ticket",
            idColor: "bg-indigo-50 text-blue-900"
        }
    ]

    return (
        <section className='py-16 bg-blue-50'>
            <T40Container>
                <Headings
                    level="h2"
                    title={<span>How to buy your <br /> bus tickets with us</span>}
                    className="leading-[26px] md:leading-[46.8px] text-t40Black text-2xl lg:text-4xl font-semibold lg:font-bold text-left"
                />
                <p className='text-[#4b5563] mt-3 md:mt-0 md:text-base text-xs text-left'>Get started in 4 simple steps</p>

                <div className='lg:grid grid-cols-4 space-y-6 lg:space-y-0 gap-x-8 mt-8'>
                    {
                        getBusTickets?.map((items: HowtoBuyTicketsProps) => (
                            <div className='h-full text-center lg:text-left flex flex-col justify-between' key={items.id}>
                                <div className=''>
                                    <span className={`lg:w-fit text-center lg:text-left font-bold text-2xl p-[9px] rounded-[100px] ${items.idColor}`}>
                                        {items.id}
                                    </span>

                                    <h3 className='pt-4 text-t40Black font-semibold text-xl'>
                                        {items.title}
                                    </h3>

                                    <p className='text-gray-500 leading-[24px]'>
                                        {items.details}
                                    </p>
                                </div>

                                <div className='lg:pb-0 pb-4 lg:border-none border-b border-b-[#102751] pt-6 flex justify-center items-center '>
                                    <img src={items.image} alt={items.alt} />
                                </div>
                            </div>
                        ))
                    }
                </div>

                <div className='mt-10 flex justify-center items-center'>
                    <button onClick={() => navigate("/select-station")} className='transition-all ease-linear hover:bg-opacity-80 cursor-pointer rounded-lg font-medium py-[10px] px-[20px] bg-blue-900 text-white'>
                        Get started
                    </button>
                </div>
            </T40Container>
        </section>
    )
}

export default HowtoBuyTickets