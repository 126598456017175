import { ReactNode } from 'react';

type InputProps = {
    type: string,
    disabled?: boolean,
    className?: string,    
    label?: string,
    name: string,
    value?: string,    
    placeholder: string,
    onChange?: (e: any) => any,
}

const ReviewInput = ({
    type,
    className,    
    label,
    name,
    value,
    placeholder,
    onChange,
    disabled,
    ...props
}: InputProps) => {
    return (
        <div className='w-full'>
            <label
                htmlFor={name}
                className='block text-sm font-medium leading-6 text-gray-900'
            >
                {label}
            </label>

            <div className="relative mt-2 border-0 border-transparent ">                
                <input
                    type={type}
                    className={`block w-full py-3 px-4 border-0 rounded-lg text-gray-500 bg-[#F9FAFB] ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 placeholder:font-['Inter'] focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6 outline-none caret-gray-300 ${className}`}
                    name={name}
                    value={value}  
                    onChange={onChange}                  
                    placeholder={placeholder}
                    disabled={disabled}
                    required
                    {...props}
                />
            </div>
        </div>
    )
}

export default ReviewInput