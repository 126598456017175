import { ArrowLeft2, ArrowRight2 } from 'iconsax-react';
import ReactPaginate from 'react-paginate';

interface PaginationProps {
    onChange : (e : any) => any,
    page? : number,
    numOfPages : number
}
export default function Pagination({
    onChange,
    page,
    numOfPages
} : PaginationProps) {

    return <ReactPaginate
        breakLabel="..."
        nextLabel={
            <h1 className='flex text-sm items-center border rounded-md p-1 ml-2 cursor-pointer'>Next <ArrowRight2 variant='Bold' color="#233D4D" size={17}/></h1>
        }
        onPageChange={onChange}
        pageRangeDisplayed={1}
        pageCount={numOfPages}
        previousLabel={
            <h1 className='flex text-sm items-center border rounded-md p-1 mr-2 cursor-pointer'><ArrowLeft2 variant='Bold' color='#233D4D' size={17}/> Prev </h1>
        }
        activeClassName='bg-orange-100 rounded-sm'
        pageClassName='px-2'
        breakClassName='px-2'
        className='flex items-center'
        forcePage={page}
        // renderOnZeroPageCount={null}
    />
}