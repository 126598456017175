import { useState, useEffect } from 'react'
import T40Container from "../../layout/Container";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import AvailableLocation from "../locations/AvailableLocation";
import locationsData from '../locations/locations.json';
import { useCallback, useRef } from "react";
import { Headings } from "../../utils/constants/Headings";
import { FaArrowRightLong, FaArrowLeftLong, FaArrowRight, FaArrowLeft } from "react-icons/fa6";
import axios from 'axios';
import baseURL, { brandID } from '../../api/ApiConfig';

const Branches = () => {

	const [getLocations, setGetLocations] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(false)
	const swiperRef: any = useRef(null);

	const slidePrev = useCallback(() => {
		if (!swiperRef.current) return;
		swiperRef.current.swiper.slidePrev();
	}, []);

	const slideNext = useCallback(() => {
		if (!swiperRef.current) return;
		swiperRef.current.swiper.slideNext();
	}, []);

	const getLocation = async () => {
		try {
			setLoading(true);
			await axios.get(`${baseURL}/v1/intercity/generals/locations?company_id=${brandID}`)
			// await axios.get(`${baseURL}/v1/intercity/generals/locations`)
				.then((res: any) => {
					setGetLocations(res.data.data);
				})
		} catch (error: any) {

		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		getLocation();
	}, [])

	return (
		<div>
			<T40Container>
				<div className="space-y-10 lg:space-y-16">
					<div className="flex flex-col lg:flex-row lg:justify-between">
						<div className="md:text-left text-center ">
							<Headings
								level="h2"
								title={<span>Our branches are available <br /> for you to explore</span>}
								className="md:block hidden leading-[46.8px] text-blue-900 text-2xl lg:text-5xl font-semibold lg:font-bold"
							/>

							<Headings
								level="h2"
								title={<span> Available locations </span>}
								className="md:hidden block leading-[46.8px] text-blue-900 text-2xl lg:text-5xl font-semibold lg:font-bold"
							/>

							<p className="text-gray-600 pt-3 md:pt-6">
								We look forward to serving you at all our
								individual bus terminals
							</p>
						</div>

						<div className="hidden lg:flex lg:flex-row gap-8 items-center">
							<button onClick={slidePrev} className="p-[22px] rounded-full bg-[#F4F5F5] text-gray-800">
								<FaArrowLeft className="text-[#1F2A37]" size={20} />
							</button>

							<button onClick={slideNext} className="p-[22px] bg-white rounded-full border-2 border-[#050d1b] ">
								<FaArrowRight className="text-[#1F2A37]" size={20} />
							</button>
						</div>

					</div>


					<div className='mt-20 '>
						<Swiper
							ref={swiperRef}
							loop={true}
							grabCursor={true}
							autoplay={{
								delay: 4000,
								disableOnInteraction: false,
							}}
							modules={[Navigation, Autoplay]}
							slidesPerView={1}
							breakpoints={{
								500: {
									slidesPerView: 1,
									spaceBetween: 10,
								},
								640: {
									slidesPerView: 1,
									spaceBetween: 10,
								},
								768: {
									slidesPerView: 2.2,
									spaceBetween: 10,
								},
								1024: {
									slidesPerView: 3.2,
									spaceBetween: 10,
								},
							}}
						>
							{
									loading ? (
										<p>Loading . . .</p>
									) : (
										getLocations.length > 0 ? (
											getLocations.map((location: any) => (
												<SwiperSlide key={location.id}>
													<AvailableLocation {...location} />
												</SwiperSlide>												
											))
										) : (
											<p>No available location</p>
										)
									)
								}							
						</Swiper>

						<div className="md:hidden pt-6 flex items-center justify-center">
							<div className="flex items-center space-x-[15px]">
								<button onClick={slidePrev} className="p-[10px] rounded-full bg-[#F4F5F5] text-gray-800">
									<FaArrowLeftLong className="text-[#1F2A37]" size={10} />
								</button>

								<button onClick={slideNext} className="p-[10px] bg-white rounded-full border-2 border-[#050d1b] ">
									<FaArrowRightLong className="text-[#1F2A37]" size={10} />
								</button>
							</div>
						</div>
					</div>
				</div>
			</T40Container>
		</div>
	);
};

export default Branches;
