import React, { ReactNode } from "react";

interface ContainerProps {
  children: ReactNode;
  className?: string;
}

const T40Container: React.FC<ContainerProps> = ({ children, className }) => {
  return (
    <div className={`mx-auto px-4 lg:px-10 w-full max-w-screen-xl ${className}`}>
      {children}
    </div>
  );
};

export default T40Container;
