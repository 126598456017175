import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useBookingContext } from "../../../context/BookingContext";
import Breadcrumbs from "../../../utils/constants/Breadcrumbs";
import { getDynamicColors } from "../../../utils/dynamic/colorUtils";
import {
	CopyIcon,
	DownloadIcon,
	SuccessIcon,
	SuccessIconTwo,
} from "../../../assets/ExportSvg";
import CurrencyFormat from "../../../utils/constants/CurrencyFormat";
import { MdAccessTimeFilled } from "react-icons/md";
import TimeConversion from "../../../utils/constants/TimeConversion";

import { Margin, usePDF } from "react-to-pdf";
import { toast } from "react-toastify";

const breadcrumbs = [
	{ label: "Home", url: "/" },
	{ label: "Select Station", url: "/select-station" },
];

interface IBookingReceiptProps {}

const BookingReceipt: React.FC<IBookingReceiptProps> = () => {
	const storedData = sessionStorage.getItem("paymentStatus");
	const { bookingData } = useBookingContext();
	const navigate = useNavigate();
	const dynamicColors = getDynamicColors();

	useEffect(() => {
		const redirectToHome = () => {
			navigate("/");
		};

		if (!storedData || storedData !== "successful" || bookingData === null) {
			redirectToHome();
		}

		return () => {};
	}, [storedData, bookingData, navigate]);

	console.log(bookingData);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { toPDF, targetRef } = usePDF({
		filename: "Booking Ticket Receipt.pdf",
		page: { margin: Margin.LARGE, orientation: "portrait", format: "A4" },
	});

	const firstBookingDetails = bookingData?.data.booking_details[0];

	const formattedDate = firstBookingDetails
		? new Date(firstBookingDetails.created_at).toLocaleDateString("en-GB", {
				day: "2-digit",
				month: "short",
				year: "numeric",
		  })
		: "N/A"; // Provide a default value or handle the case when data is not available.

	const formattedTime = firstBookingDetails
		? new Date(firstBookingDetails.created_at).toLocaleTimeString("en-GB", {
				hour: "2-digit",
				minute: "2-digit",
		  })
		: "N/A"; // Provide a default value or handle the case when data is not available.

	const formattedDateTime = `${formattedDate} ${formattedTime}`;

	const handleCopy = async (copy: any) => {
		const textarea = document.createElement("textarea");
		textarea.value = `ID-${copy}`;
		document.body.appendChild(textarea);

		textarea.select();
		document.execCommand("copy");
		document.body.removeChild(textarea);

		toast.success("Booking code copied");
		// try {
		// 	// Request clipboard write permission within a user-initiated event
		// 	await navigator.clipboard.writeText(copy);
		// 	toast.success("Booking code copied");
		// } catch (error) {
		// 	console.error("Error copying to clipboard:", error);
		// 	toast.error("Booking code can't copy");
		// }
	};
	return (
		<div>
			<Breadcrumbs breadcrumbs={breadcrumbs} />
			<div className="bg-[#F6F6F6] min-h-[50vh] py-10">
				<div className="w-full">
					<div
						ref={targetRef}
						id="booking-receipt"
						className="bg-white w-full max-w-lg mx-auto lg:rounded-lg">
						{/* content  */}
						<div className="px-4 py-6 flex items-center justify-between border-b">
							<div className="space-y-2">
								<h3 className="font-bold">Booking Details</h3>
								{/* <div
									onClick={() => {}}
									className="cursor-pointer flex items-center gap-2">
									<p className="text-sm text-gray-500 ">
										ID-{firstBookingDetails?.booking_code}
									</p>

									<CopyIcon />
								</div> */}

								<div className="flex items-center justify-center gap-2 py-1 bg-green-100 w-[110px] rounded">
									<SuccessIcon />
									<span className="text-sm text-green-800">Successful</span>
								</div>
							</div>
							<button
								id="remove"
								onClick={() => toPDF()}
								style={{ backgroundColor: dynamicColors.primary }}
								className="text-white text-base font-medium rounded-lg px-6 py-3.5 flex items-center gap-2">
								<DownloadIcon /> Download
							</button>
						</div>
						{bookingData?.data.booking_details.map((data, index) => {
							return (
								<div key={index}>
									{/* <div className="px-4 py-6 flex items-center justify-between border-b">
										<div className="space-y-2">
											<h3 className="font-bold">Booking Details</h3>
											<div
												onClick={() => {}}
												className="cursor-pointer flex items-center gap-2">
												<p className="text-sm text-gray-500 ">
													ID-{data.booking_code}
												</p>

												<CopyIcon />
											</div>

											<div className="flex items-center justify-center gap-2 py-1 bg-green-100 w-[110px] rounded">
												<SuccessIcon />
												<span className="text-sm text-green-800">
													Successful
												</span>
											</div>
										</div>
										<button
											id="remove"
											onClick={() => toPDF()}
											style={{ backgroundColor: dynamicColors.primary }}
											className="text-white text-base font-medium rounded-lg px-6 py-3.5 flex items-center gap-2">
											<DownloadIcon /> Download
										</button>
									</div> */}

									{/* <div className="px-4 py-6 flex items-center justify-between border-b">
										<p>Booking Code:</p>

										<div
											onClick={async () => handleCopy(data.booking_code)}
											className="cursor-pointer flex items-center gap-2">
											<p className="text-sm">ID-{data.booking_code}</p>

											<CopyIcon />
										</div>
									</div> */}
								</div>
							);
						})}

						{/* Travel Details  */}
						<div className="px-4 py-6 space-y-4 border-b">
							<div className="flex items-center justify-between">
								<p>Departure City:</p>
								<p className="capitalize">
									{
										bookingData?.data.departure_details.ride_meta
											.departure_location.name
									}
								</p>
							</div>
							<div className="flex items-center justify-between">
								<p>Departure Address:</p>
								<p className="capitalize">
									{
										bookingData?.data.departure_details.ride_meta
											.departure_location.address
									}
								</p>
							</div>
							<div className="flex items-center justify-between">
								<p>Destination City:</p>
								<p className="capitalize">
									{bookingData?.data.destination_details.location.name}
								</p>
							</div>

							<div className="flex items-center justify-between">
								<p>Departure Time:</p>
								<p className="capitalize">
									<TimeConversion
										timeString={
											bookingData?.data.departure_details.ride_meta
												.departure_time || ""
										}
									/>
								</p>
							</div>
							{/* <div className="flex items-center justify-between">
								<p>Est. Arrival Time</p>
								<p className="capitalize">
									<TimeConversion
										timeString={
											bookingData?.data.destination_details.arrival_time || ""
										}
									/>
								</p>
							</div> */}
							{/* <div className="flex items-center justify-between">
								<p>Seat Number:</p>

								<div className="flex gap-1 flex-wrap">
									{bookingData?.data.booking_details.map((seat, index) => {
										return (
											<p className="capitalize text-orange-400" key={index}>
												{seat.passenger_details.seat.seat_no}
												{index === Array.length - 1 && (
													<span className="capitalize text-orange-400">,</span>
												)}
											</p>
										);
									})}
								</div>
							</div> */}
						</div>

						{/* Travel Details  */}

						{/* Passenger Details  */}
						{bookingData?.data.booking_details.map((data, index) => {
							return (
								<div key={index}>
									<div className="px-4 py-6 space-y-4 border-b">
										<div className="pt-4">
											<p className="text-sm text-gray-500 ">
												PASSENGER{" "}
												{bookingData?.data.booking_details.length > 1
													? `${index + 1} `
													: ""}{" "}
												DETAILS
											</p>
										</div>
										<div className="flex items-center justify-between">
											<p>First Name:</p>
											<p className="capitalize">
												{data.passenger_details.first_name}
											</p>
										</div>
										<div className="flex items-center justify-between">
											<p>Last Name:</p>
											<p className="capitalize">
												{data.passenger_details.last_name}
											</p>
										</div>
										<div className="flex items-center justify-between">
											<p>Gender:</p>
											<p className="capitalize">
												{data.passenger_details.gender}
											</p>
										</div>
										<div className="flex items-center justify-between">
											<p>Phone Number:</p>
											<p>{data.passenger_details.phone_number}</p>
										</div>
										<div className="flex items-center justify-between">
											<p>Email Address:</p>
											<p>{data.passenger_details.email}</p>
										</div>
										<div className="flex items-center justify-between">
											<p>Seat Number:</p>
											<p className="text-orange-400">
												{data.passenger_details.seat.seat_no}
											</p>
										</div>

										<div className="flex items-center justify-between">
											<p>Booking Code:</p>
											<div
												onClick={async () => handleCopy(data.booking_code)}
												className="cursor-pointer flex items-center gap-2">
												<p className="text-sm">ID-{data.booking_code}</p>

												<CopyIcon />
											</div>
										</div>

										<div className="pt-4">
											<p className="text-sm text-gray-500 ">
												NEXT OF KIN DETAILS
											</p>
										</div>
										<div className="flex items-center justify-between">
											<p>First Name:</p>
											<p className="capitalize">
												{data.passenger_details.next_of_kin_first_name}
											</p>
										</div>
										<div className="flex items-center justify-between">
											<p>Last Name:</p>
											<p className="capitalize">
												{data.passenger_details.next_of_kin_last_name}
											</p>
										</div>
										<div className="flex items-center justify-between">
											<p>Phone Number:</p>
											<p>{data.passenger_details.next_of_kin_phone_number}</p>
										</div>
									</div>
								</div>
							);
						})}
						<>
							<div className="px-4 py-6 space-y-4 border-b">
								<div className="flex items-center justify-between">
									<p className="font-semibold">Amount:</p>
									<p className="capitalize font-semibold">
										₦
										<CurrencyFormat
											amount={
												bookingData?.data.booking_details[0]
													.booking_payment_details.paid_amount || 0
											}
										/>
									</p>
								</div>
							</div>
							<div className="px-4 py-6 space-y-4 border-b">
								<div className="flex items-center justify-between">
									<p>Status:</p>
									<p className="capitalize text-green-500 flex items-center gap-2">
										<SuccessIconTwo /> Successful
									</p>
								</div>
							</div>
							<div className="px-4 py-6 space-y-4 border-b">
								<div className="flex items-center justify-between">
									<p>Created On:</p>
									<p className="capitalize flex items-center gap-2">
										<MdAccessTimeFilled className="text-gray-500" />
										{formattedDateTime}
									</p>
								</div>
							</div>
						</>

						<div className="py-5 w-full flex items-center justify-center">
							<button
								type="button"
								className="dont-capture text-white text-base font-medium rounded-lg px-20 py-3.5 flex items-center gap-2"
								onClick={() => navigate("/")}
								style={{ backgroundColor: dynamicColors.primary }}>
								Go Home
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BookingReceipt;
