import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default function Layout({ children }: { children: React.ReactNode }) {
    return <>
        <Navbar  />
        <main>
            {children}
        </main>
        <Footer />
    </>
}