import React from 'react';
import Loading from 'react-loading';

interface InputProps {
    type?: string,
    value?: string,
    onChange?: (e: any) => any,
    className?: string,
    placeholder?: string,
    name?: string,
    error?: Array<string>
    disabled?: boolean,
    boxShadow?: string,
    id?: string,
    haveBorder?: boolean,
    haveSmallerPadding?: boolean,
    onBtnClick?: Function | null,
    isLoading?: boolean,
    haveWhiteBackground? : boolean
}

export default function Input({
    type = 'text',
    value,
    onChange,
    className,
    placeholder,
    name,
    error,
    disabled = false,
    boxShadow,
    id,
    haveBorder = false,
    haveSmallerPadding = false,
    onBtnClick = null,
    isLoading=false,
    haveWhiteBackground=false
}: InputProps) {
    const style = {
        boxShadow: boxShadow || "0px 0px 56px rgba(29, 51, 64, 0.06)",
        borderRadius: "5px",
    }

    const [inputType] = React.useState<string>(type);

    return (
        <div className={className} id={id}>
            <div style={style} className={`flex overflow-hidden ${error?.join("") ? "bg-orange-100 border border-orange-400" : haveBorder ? "border border-blue-200" : ""} ${haveWhiteBackground && "bg-white"}`}>
                <input disabled={disabled} placeholder={placeholder} name={name} value={value} type={inputType} className={`${type === 'password' ? 'w-[92%]' : onBtnClick ? "w-[70%]" : 'w-full'} ${haveSmallerPadding ? "py-3 px-4" : "py-4 px-2"} 
                        text-md placeholder:text-md focus:outline-none bg-transparent disabled:opacity-70 disabled:cursor-not-allowed`}
                    onChange={onChange}
                    onBlur={
                        () => onChange && onChange({
                            target : {
                                value : value?.trim(),
                                name
                            }
                        })
                    }
                     />
                {
                    onBtnClick && <div className='w-[30%] p-2'>
                        <button disabled={isLoading} className='disabled:opacity-70 primary-butt w-full h-full flex justify-center items-center' onClick={onBtnClick as React.MouseEventHandler}>
                            {isLoading ?  <Loading type='spin' height="24px" width="24px" /> : 'Apply'}
                        </button>
                    </div>
                }
            </div>
            {
                error && error.map(
                    (value, ind) =>
                        <h1 className='text-orange-400 text-[12px]' key={"error" + ind}>{value}</h1>
                )
            }
        </div>
    )
}