import * as React from "react";
import { getDynamicColors } from "../../../utils/dynamic/colorUtils";

interface IStepperProps {
	step: number;
	setStepper: React.Dispatch<React.SetStateAction<number>>;
}

const Stepper: React.FunctionComponent<IStepperProps> = (props) => {
	const { step } = props;
	const dynamicColors = getDynamicColors();

	// const handleStepper = (step: number) => {
	// 	setStepper(step);
	// };
	return (
		<>
			<ol className="flex items-center w-full">
				<li className="flex w-full items-center text-white after:content-[''] after:w-full after:h-1 after:border-b after:border-1 after:inline-block">
					<span
						// onClick={() => handleStepper(1)}
						className="flex items-center justify-center w-7 h-7 bg-gray-100 rounded-full lg:h-8 lg:w-8 shrink-0"
						style={{
							background: step === 1 ? dynamicColors.primary : "#9CA3AF",
						}}>
						1
					</span>
				</li>
				<li className="flex w-full items-center text-white after:content-[''] after:w-full after:h-1 after:border-b after:border-1 after:inline-block">
					<span
						// onClick={() => handleStepper(2)}
						className="flex items-center justify-center w-7 h-7 bg-gray-100 rounded-full lg:h-8 lg:w-8 shrink-0"
						style={{
							background: step === 2 ? dynamicColors.primary : "#9CA3AF",
						}}>
						2
					</span>
				</li>
				<li className="flex w-full items-center text-white after:content-[''] after:w-full after:h-1 after:inline-block">
					<span
						// onClick={() => handleStepper(3)}
						className="flex items-center justify-center w-7 h-7 bg-gray-100 rounded-full lg:h-8 lg:w-8 shrink-0"
						style={{
							background: step === 3 ? dynamicColors.primary : "#9CA3AF",
						}}>
						3
					</span>
				</li>
			</ol>
		</>
	);
};

export default Stepper;
