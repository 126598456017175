import * as React from "react";
import { getDynamicColors } from "../dynamic/colorUtils";

interface ICustomButtonProps {
	text: string;
	type: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
	onClick: () => void;
	small: boolean;
	disabled?: boolean;
}

const CustomButton: React.FunctionComponent<ICustomButtonProps> = (props) => {
	const { text, type, onClick, small, disabled } = props;
	const dynamicColors = getDynamicColors();
	return (
		<button
			onClick={onClick}
			type={type}
			// style={{ backgroundColor: dynamicColors.primary }}
			className={`w-full ${
				small ? "max-w-sm" : ""
			} ${disabled? "bg-gray-700": "bg-[#1A56DB]"}
			text-white  transition-all ease-linear hover:bg-opacity-85 cursor-pointer text-base font-medium rounded-lg px-6 py-3.5`}
		>
			{text}
		</button>
	);
};

export default CustomButton;
