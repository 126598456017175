import React from 'react';
import service1 from '../../assets/other-services1.png';
import service2 from '../../assets/other-services2.png';
import { MdOutlineArrowRightAlt } from "react-icons/md";
import { brandName } from '../../api/ApiConfig';

type ServicesTypes = {
    id: number,
    title: string,
    details: string,
    action: string,
    image: any | HTMLImageElement
}

const CustomServices = () => {

    const services: ServicesTypes[] = [
        {
            id: 1,
            title: "Charter a ride",
            details: `Whether it's for a special event, corporate travel, 
            or group outings, chartering a vehicle with ${brandName} 
            is simple and convenient. Enjoy personalized transportation 
            solutions tailored to your needs.`,
            action: "Hire a car",
            image: service1
        },
        {
            id: 2,
            title: "Send a parcel",
            details: `Need to send a parcel? ${brandName} has you 
            covered. Our parcel delivery service ensures your 
            package reaches its destination safely and on time, 
            giving you peace of mind every step of the way.`,
            action: "Send Parcel",
            image: service2
        }
    ]
    return (
        <section className='mt-4 mb-[93px]'>
            <div className='flex items-center justify-center text-center'>
                <div>
                    <h2 className='text-indigo-700 text-[28px] font-bold'>Travel with {brandName}</h2>
                    <p className='md:w-[661px] mt-[17px] leading-[24px] text-gray-500 text-base font-medium'>
                        Travel with {brandName} today! Check our
                        available routes and buy tickets for your
                        intercity travels now.
                    </p>
                </div>
            </div>

            <div className="mt-12">
                <h2 className='text-t40Black text-center lg:text-center text-xl lg:text-[28px] font-bold leading-[42px]'>
                    Other services we offer
                </h2>
                
                <p className='text-center lg:text-center text-gray-500 text-base lg:text-sm font-medium leading-[24px] mt-3'>
                    {brandName} offers variety of logistics and
                    transportation services
                </p>
            </div>

            <div className='mt-8 md:flex items-center md:gap-12 space-y-4 md:space-y-0'>
                {
                    services.map((service: ServicesTypes) => (
                        <div key={service.id}>
                            <img className="lg:rounded-none rounded-t-xl" src={service.image} alt={service.title} />
                            <div className='lg:text-left text-center rounded-b-xl flex justify-between flex-col px-[39px] pb-4 pt-4 lg:pl-12 lg:pr-0 lg:pb-[23px] lg:pt-1' style={{ background: "linear-gradient(180deg, #2458B7 70%, #102751 100%)" }}>
                                <div>
                                    <h3 className='text-[28px] font-bold text-white'>
                                        {service.title}
                                    </h3>

                                    <p className='w-full lg:w-[408px] mt-3 mb-6 text-white font-medium leading-[24px] '>
                                        {service.details}
                                    </p>
                                </div>

                                <div className="flex lg:justify-left justify-center items-center">
                                    <button className='text-xs flex space-x-2 items-center w-fit rounded-lg font-medium group cursor-pointer hover:bg-opacity-90 bg-white px-3 py-2 text-t40Black'>
                                        <span>{service.action}</span>
                                        <MdOutlineArrowRightAlt />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </section >
    )
}

export default CustomServices