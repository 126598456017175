import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface DateSelectProps {
    className?: string,
    onChange?: (e: Date | null) => any,
    placeholderText?: string,
    selected?: Date | null,
    minDate?: Date,
    style?: React.CSSProperties,
    disbaled?: boolean,
    nullValuePlaceHolder?: string
}
export default function DateSelect(
    {
        className = "",
        onChange,
        placeholderText,
        selected,
        minDate,
        style,
        disbaled = false,
        nullValuePlaceHolder = ""
    }: DateSelectProps
) {
    // eslint-disable-next-line react/display-name
    const CustomInput = React.forwardRef(({ value, onClick, onChange }: any, ref: any) => {
        return <div>
            <div className={` ${(value && placeholderText) ? "max-h-[100px]" : "max-h-0"} transition-all overflow-hidden pb-1`}>
                <label>{placeholderText}*</label>
            </div>
            <div onClick={onClick}
                className={"flex item-center items-center border border-transparent  " + className + (disbaled ? " opacity-80" : ' ')} style={style}  >
                {/* <CalendarAdd variant='Bold' color="#627496" size="20" className='mr-2 -ml-1'/> */}
                <input
                    className='disabled:cursor-not-allowed focus:outline-none text-sm w-full placeholder:text-blue-11 text-blue-11 bg-transparent'
                    value={value ? value : nullValuePlaceHolder}
                    onChange={onChange}
                    placeholder={placeholderText}
                    ref={ref}
                    onKeyDown={e => e.preventDefault()}
                // disabled={disbaled} 
                />
            </div>
        </div>
    })
    return <div>

        <DatePicker
            wrapperClassName='w-full'
            onChange={(e) => onChange && onChange(e)}
            customInput={<CustomInput />}
            selected={selected}
            minDate={minDate}
        // disabled={disbaled}
        />
    </div>
}